var contextIcmBaseGeneric = pwNamespace('PEAKWORK.FORM.GENERIC');
contextIcmBaseGeneric.buildGenericForm = function($el, defaultOptions) {

    var $idInput = $el.find('input.js-form-generic-id-input')
        ,$outputContainer = $el.find('.js-form-generic-output-container')
        ,ajaxRoute = $idInput.data('formAjaxRoute');

    $idInput.change(function() {
        var params = $idInput.data('query-params-generic') ? $idInput.data('query-params-generic') : {};
        if ('undefined' === typeof params.id) {
            params.id = $idInput.val();
        }

        $.ajax({
            url: ajaxRoute
            ,data: params
            ,success: function(data) {
                $outputContainer.html(data);
            }
        });
    });
};

$(function() {
    var forms = $('form.js-form-generic')
        ,formsLength = forms.length
        ,i;

    for (i = 0; i < formsLength; i++) {
        PEAKWORK.FORM.GENERIC.buildGenericForm($(forms[i]), {});
    }
});