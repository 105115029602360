var contextIcmGenericPermissions = pwNamespace('PEAKWORK.ICM.GENERIC');

contextIcmGenericPermissions.Permissions = function (config) {
    config = config || {};

    this.config = $.extend(true, {
        viewBtn: '.js-generic-permissions-view'
        ,addBtn: '.js-generic-permissions-add'
        ,deleteBtn: '.js-generic-permissions-delete'
        ,editSelect: '.js-generic-permissions-edit'
        ,getRouteId: 'peakwork_icm_generic_permissions_get'
        ,addRouteId: 'peakwork_icm_generic_permissions_add'
        ,editRouteId: 'peakwork_icm_generic_permissions_edit'
        ,deleteRouteId: 'peakwork_icm_generic_permissions_delete'
        ,translationDomain: 'peakworkicmgeneric'
        ,instanceId: null
    }, config);

    this.init();
};

contextIcmGenericPermissions.Permissions.prototype.init = function() {
    var me = this;

    $(document).on('click', me.config.viewBtn, function(event) {
        me.displayPermissionsView($(this), event);
    });

    $(document).on('click', me.config.addBtn, function(event) {
        me.addPermission($(this), event);
    });

    $(document).on('click', me.config.deleteBtn, function(event) {
        me.deletePermissionConfirm($(this), event);
    });

    $(document).on('change', me.config.editSelect,function(event) {
        me.editPermission($(this), event);
    });
};

contextIcmGenericPermissions.Permissions.prototype.setInstanceId = function(element) {
    var me = this;

    if (element) {
        me.config.instanceId = element.data('instanceid');
    }
};

contextIcmGenericPermissions.Permissions.prototype.displayPermissionsView = function(element, event) {
    var me = this;

    me.setInstanceId(element);

    me.dialog = new BootstrapDialog({
        title: '<i class="fa fa-unlock-alt"></i> ' + Translator.trans('permissions.title', {}, me.config.translationDomain),
        message: PeakworkBackendHelper.getInstance().createLoader(),
        size: BootstrapDialog.SIZE_WIDE,
        nl2br: false,
        headerCloseBtn: true,
        onshown: function (dialog) {
            $.ajax({
                url: Routing.generate(me.config.getRouteId, {
                    resource: me.config.resource
                }),
                data: {
                    id: me.config.instanceId
                },
                method: 'GET',
                dataType: 'html',
                success: function(response) {
                    dialog.setMessage(response);
                    me.reinitializeAutocomplete();
                },
                error: function(response) {
                    dialog.close();
                    swal({
                        title: Translator.trans('permissions.error.title', {}, me.config.translationDomain)
                        ,text: Translator.trans(response.responseText, {}, me.config.translationDomain)
                        ,html: true
                        ,type: 'error'
                        ,showConfirmButton: true
                        ,confirmButtonText: Translator.trans('permissions.error.confirm', {}, me.config.translationDomain)
                    });
                }
            });
        },
        buttons: [{
            label: '<span class="fa fa-times"></span> ' + Translator.trans('permissions.close', {}, me.config.translationDomain),
            cssClass: 'btn-default',
            action: function(dialog) {
                dialog.close();
            }
        }]
    }).open();
};

contextIcmGenericPermissions.Permissions.prototype.updatePermissionView = function (me) {
    $.ajax({
        url: Routing.generate(me.config.getRouteId, {
            resource: me.config.resource
        }),
        data: {
            id: me.config.instanceId
        },
        method: 'GET',
        dataType: 'html',
        success: function(response) {
            me.dialog.setMessage(response);
            me.reinitializeAutocomplete();
        }
    });
};

contextIcmGenericPermissions.Permissions.prototype.addPermission = function(element, event) {
    var me = this,
        inputs = element.closest('tr').find('input.js-generic-permissions-input, select.js-generic-permissions-input'),
        data = $.extend(
            {
                id: me.config.instanceId
            },
            me.serializeInputArray(inputs)
        );

    $.ajax({
        url: Routing.generate(me.config.addRouteId, {
            resource: me.config.resource
        }),
        data: data,
        method: 'POST',
        dataType: 'json',
        beforeSend: function() {
            me.dialog.setMessage(PeakworkBackendHelper.getInstance().createLoader());
        },
        success: function(response) {
            if (response.Success) {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.add.success', {}, me.config.translationDomain), 'success');
                me.updatePermissionView(me);
            } else {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.add.error', {}, me.config.translationDomain), 'error');
                me.dialog.close();
            }
        }
    });
};

contextIcmGenericPermissions.Permissions.prototype.editPermission = function(element, event) {
    var me = this;
        inputs = element.closest('tr').find('input.js-generic-permissions-input, select.js-generic-permissions-input'),
        data = $.extend(
            {
                id: me.config.instanceId
            },
            me.serializeInputArray(inputs)
        );

    $.ajax({
        url: Routing.generate(me.config.editRouteId, {
            resource: me.config.resource
        }),
        data: data,
        method: 'POST',
        dataType: 'json',
        success: function(response) {
            if (response.Success) {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.edit.success', {}, me.config.translationDomain), 'success');
            } else {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.edit.error', {}, me.config.translationDomain), 'error');
                me.dialog.close();
            }
        }
    });
};

/**
 * Will request the user if he/she really wants to delete the entity.
 * @param event
 */
contextIcmGenericPermissions.Permissions.prototype.deletePermissionConfirm = function(element, event) {
    var me = this;

    swal({
        title: Translator.trans('permissions.delete.confirm.title', {}, me.config.translationDomain)
        ,text: Translator.trans('permissions.delete.confirm.text', {}, me.config.translationDomain)
        ,html: true
        ,type: 'warning'
        ,showCancelButton: true
        ,showConfirmButton: true
        ,closeOnCancel: true
        ,confirmButtonText: Translator.trans('permissions.delete.confirm.confirmbtn', {}, me.config.translationDomain)
        ,cancelButtonText: Translator.trans('permissions.delete.confirm.cancelbtn', {}, me.config.translationDomain)
    },function(isConfirm) {
        if (isConfirm) {
            me.deletePermission(element, event);
        }
    });

};

contextIcmGenericPermissions.Permissions.prototype.deletePermission = function(element, event) {
    var me = this;
        inputs = element.closest('tr').find('input.js-generic-permissions-input, select.js-generic-permissions-input'),
        data = $.extend(
            {
                id: me.config.instanceId
            },
            me.serializeInputArray(inputs)
        );

    $.ajax({
        url: Routing.generate(me.config.deleteRouteId, {
            resource: me.config.resource
        }),
        data: data,
        method: 'POST',
        dataType: 'json',
        beforeSend: function() {
            me.dialog.setMessage(PeakworkBackendHelper.getInstance().createLoader());
        },
        success: function(response) {
            if (response.Success) {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.delete.success', {}, me.config.translationDomain), 'success');
                me.updatePermissionView(me);
            } else {
                contextIcmGenericPermissions.Notification.notify(Translator.trans('permissions.delete.error', {}, me.config.translationDomain), 'error');
                me.dialog.close();
            }
        }
    });
};

contextIcmGenericPermissions.Permissions.prototype.serializeInputArray = function (inputArray) {
    var data = {}
        ,inputArrayLength = inputArray.length
        ,i
        ,$input;

    for (i = 0; i < inputArrayLength; i++) {
        $input = $(inputArray[i]);
        data[$input.attr('name')] = $input.val();
    }

    return data;
};

contextIcmGenericPermissions.Permissions.prototype.reinitializeAutocomplete = function() {
    var autoCompletes = $('.js-form-autocomplete')
        ,autoCompletesLength = autoCompletes.length
        ,i
        ,$element
        ,isTemplate;

    for (i = 0; i < autoCompletesLength; i++) {
        $element = $(autoCompletes[i]);
        isTemplate = $element.closest('.js-collection-template').length > 0;

        if (false === isTemplate) {
            PEAKWORK.FORM.AUTOCOMPLETE.init($element);
        }
    }
};