var contextIcmWorkflowUserGroupCreatePolicy = pwNamespace('PEAKWORK.ICM.WORKFLOWUSERGROUP.CREATEPOLICY');
contextIcmWorkflowUserGroupCreatePolicy.translationDomain = 'peakworkicmworkflowusergroup';

contextIcmWorkflowUserGroupCreatePolicy.loadForm = function($formContainer) {
    $.ajax({
        url: $formContainer.data('route')
        ,dataType: 'json'
        ,beforeSend: function() {
            $formContainer.html(PeakworkBackendHelper.getInstance().createLoader());
        }
        ,success: function(response) {
            $formContainer.html(response.content);
            contextIcmWorkflowUserGroupCreatePolicy.initFormEvents($formContainer);
        }
    });
};

contextIcmWorkflowUserGroupCreatePolicy.submitForm = function($formContainer, $table) {
    var $form = $formContainer.find('form');

    $.ajax({
        url: $form.attr('action')
        ,data: $form.serialize()
        ,dataType: 'json'
        ,method: 'POST'
        ,beforeSend: function() {
            $formContainer.html(PeakworkBackendHelper.getInstance().createLoader());
        }
        ,success: function(response) {
            if (response.success === true) {
                contextIcmWorkflowUserGroupCreatePolicy.loadForm($formContainer);
                $table.DataTable().draw();
            } else {
                $formContainer.html(response.content);
                contextIcmWorkflowUserGroupCreatePolicy.initFormEvents($formContainer);
            }
        }
    });
};

contextIcmWorkflowUserGroupCreatePolicy.initFormEvents = function($formContainer) {
    var autoCompletetFields = $formContainer.find('input.js-form-autocomplete')
        ,autoCompleteFieldsLength = autoCompletetFields.length
        ,i
        ,$form = $formContainer.find('form');

    for (i = 0; i < autoCompleteFieldsLength; i++) {
        PEAKWORK.FORM.AUTOCOMPLETE.init($(autoCompletetFields[i]));
    }

    PEAKWORK.FORM.GENERIC.buildGenericForm($($form), {});
};

contextIcmWorkflowUserGroupCreatePolicy.deleteRule = function($link, $table) {
    var route = $link.attr('href')
        ,rulename = $link.data('rulename');

    swal({
        title: Translator.trans('workflow.deleterule.confirmtitle', {}, contextIcmWorkflowUserGroupCreatePolicy.translationDomain)
        ,text: Translator.trans('workflow.deleterule.confirmtext', {
            'rulename': rulename
        }, contextIcmWorkflowUserGroupCreatePolicy.translationDomain)
        ,html: true
        ,type: 'warning'
        ,showCancelButton: true
        ,showConfirmButton: true
        ,closeOnCancel: true
        ,closeOnConfirm: false
        ,confirmButtonText: Translator.trans('workflow.deleterule.confirmbtn', {}, contextIcmWorkflowUserGroupCreatePolicy.translationDomain)
        ,cancelButtonText: Translator.trans('workflow.deleterule.cancelbtn', {}, contextIcmWorkflowUserGroupCreatePolicy.translationDomain)
    },function(isConfirm) {
        if (isConfirm) {
            $.ajax({
                url: route
                ,dataType: 'json'
                ,method: 'POST'
                ,beforeSend: function() {
                    swal({
                        title: Translator.trans('workflow.deleterule.actiontitle', {}, contextIcmWorkflowUserGroup.translationDomain)
                        ,text: PeakworkBackendHelper.getInstance().createLoader()
                        ,html: true
                        ,showConfirmButton: false
                    });
                }
                ,success: function(response) {
                    swal.close();
                    if (response.success) {
                        $table.DataTable().draw();
                    } else {
                        contextIcmWorkflowUserGroupCreatePolicy.notify(response.msg, 'error');
                    }
                }
            });
        }
    });
};

contextIcmWorkflowUserGroupCreatePolicy.notify = function(message, type) {
    toastr.options = {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': false,
        'positionClass': 'toast-top-right',
        'preventDuplicates': false,
        'onclick': null,
        'showDuration': '300',
        'hideDuration': '1000',
        'timeOut': '5000',
        'extendedTimeOut': '1000',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut'
    };

    switch (type) {
        case 'error':
            toastr.error(message);
            break;
        case 'success':
            toastr.success(message);
            break;
        case 'warning':
            toastr.warning(message);
            break;
        default:
            toastr.info(message);
    }
};


$(function() {
    /* table rendering */
    var $table = $('#peakworkIcmWorkflowCreatePolicyRulesTable');
    if ($table.length === 1) {
        /* initialize the table */
        new PEAKWORK.ICM.BASE.List({
            tableDomId: '#peakworkIcmWorkflowCreatePolicyRulesTable'
            ,tableId: 'peakworkIcmWorkflowCreatePolicyRulesTable'
            ,dataTableIdentifier: 'peakworkIcmWorkflowCreatePolicyRulesTable'
            ,hasActivate: false
            ,hasDeactivate: false
            ,hasDelete: false
            ,addBtn: 'div.addBtn'
            ,translationDomain: contextIcmWorkflowUserGroupCreatePolicy.translationDomain
            ,autoload: true
            ,saveFlashSettings: false
            ,hasActiveToggle: false
            ,paging: false
            ,lengthMenu: [-1]
            ,info: false
        });
        $.fn.dataTableExt.sErrMode = 'mute';


        $table.on('click', '.js-peakworkICMWorflowUserGroupDeleteRule', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroupCreatePolicy.deleteRule($(this), $table);
        });
    }

    var $formContainer = $('#peakworkIcmWorkflowCreatePolicyFormContainer');
    if ($formContainer.length === 1) {
        contextIcmWorkflowUserGroupCreatePolicy.loadForm($formContainer);

        $formContainer.on('click', '.js-peakworkDetailFormAbort', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroupCreatePolicy.loadForm($formContainer);
        });

        $formContainer.on('click', '.js-peakworkDetailFormSave', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroupCreatePolicy.submitForm($formContainer, $table);
        });
    }
});