var contextIcmWorkflowUserGroup = pwNamespace('PEAKWORK.ICM.WORKFLOWUSERGROUP');
contextIcmWorkflowUserGroup.translationDomain = 'peakworkicmworkflowusergroup';

contextIcmWorkflowUserGroup.checkSession = function() {
    $.ajax({
        url: Routing.generate('peakwork_icm_workflowusergroup_usergroup_create_checksession')
        ,dataType: 'json'
        ,success: function(response) {
            if (response.hasWorkflow) {
                swal({
                    title: Translator.trans('workflow.sessiontitle', {}, contextIcmWorkflowUserGroup.translationDomain)
                    ,text: Translator.trans('workflow.sessiontext', {
                        'usergroup': response.usergroup
                    }, contextIcmWorkflowUserGroup.translationDomain)
                    ,html: true
                    ,type: 'warning'
                    ,showCancelButton: true
                    ,showConfirmButton: true
                    ,closeOnCancel: true
                    ,closeOnConfirm: false
                    ,confirmButtonText: Translator.trans('workflow.confirmbtn', {}, contextIcmWorkflowUserGroup.translationDomain)
                    ,cancelButtonText: Translator.trans('workflow.cancelbtn', {}, contextIcmWorkflowUserGroup.translationDomain)
                },function(isConfirm) {
                    if (isConfirm) {
                        window.location = response.nextStep;
                    }
                });
            }
        }
    });
};
contextIcmWorkflowUserGroup.loadUserForm = function($formContainer) {
    $.ajax({
        url: $formContainer.data('route')
        ,dataType: 'json'
        ,beforeSend: function() {
            $formContainer.html(PeakworkBackendHelper.getInstance().createLoader());
        }
        ,success: function(response) {
            contextIcmWorkflowUserGroup.renderUserForm($formContainer, response.content);
        }
    });
};

contextIcmWorkflowUserGroup.submitUserForm = function($formContainer, $table) {
    var $form = $formContainer.find('form');

    $.ajax({
        url: $form.attr('action')
        ,data: $form.serialize()
        ,dataType: 'json'
        ,method: 'POST'
        ,beforeSend: function() {
            $formContainer.html(PeakworkBackendHelper.getInstance().createLoader());
        }
        ,success: function(response) {
            if (response.success === true) {
                contextIcmWorkflowUserGroup.loadUserForm($formContainer);
                $table.DataTable().draw();
            } else {
                contextIcmWorkflowUserGroup.renderUserForm($formContainer, response.content);
            }
        }
    });
};

contextIcmWorkflowUserGroup.renderUserForm = function($formContainer, formContent) {
    $formContainer.html(formContent);
    var $username = $formContainer.find('#workflow_user_group_user_username')
        ,fields = contextIcmWorkflowUserGroup.getFormFields($formContainer.find('form'))
        ,usernewNewVal = fields['usernamenew'].val();

    new PEAKWORK.ICM.BASE.AutoCompleteCreate($username, {
        initAllowCreateSelectingEvent: false
        ,initSelection: function(id, callback) {
            if (id !== 'registernew') {
                callback(this.getRegisterNewValue(id));
            }
        }
    });

    $username.on('select2-selecting', function(event) {
        var $form = $formContainer.find('form')
            ,fields = contextIcmWorkflowUserGroup.getFormFields($form);
        if ('registernew' === event.val) {
            contextIcmWorkflowUserGroup.resetForm(fields);
            contextIcmWorkflowUserGroup.enableFormFields(fields);
            fields['usernamenew'].val(event.object.data.name);
            contextIcmWorkflowUserGroup.toggleSaveBtns(fields, true);
            return true;
        }

        if ('' === event.val) {
            contextIcmWorkflowUserGroup.resetForm(fields);
            contextIcmWorkflowUserGroup.enableFormFields(fields);
            contextIcmWorkflowUserGroup.toggleSaveBtns(fields, true);
        } else {
            contextIcmWorkflowUserGroup.loadUser(fields, event.object);
            contextIcmWorkflowUserGroup.disableFormFields(fields);
            contextIcmWorkflowUserGroup.toggleSaveBtns(fields, false);
        }
    });

    if (usernewNewVal !== '') {
        $username.select2('val', usernewNewVal);
    }
};

contextIcmWorkflowUserGroup.toggleSaveBtns = function(fields, isCreate) {
    if (isCreate) {
        fields['btnAdd'].addClass('hidden');
        fields['btnCreateAndAdd'].removeClass('hidden');
    } else {
        fields['btnCreateAndAdd'].addClass('hidden');
        fields['btnAdd'].removeClass('hidden');
    }
};

contextIcmWorkflowUserGroup.getFormFields = function($form) {
    return {
        'usernamenew': $form.find('#workflow_user_group_user_usernamenew')
        ,'name': $form.find('#workflow_user_group_user_name')
        ,'email': $form.find('#workflow_user_group_user_email')
        ,'language': $form.find('#workflow_user_group_user_language')
        ,'accountType': $form.find('#workflow_user_group_user_accountType')
        ,'active': $form.find('#workflow_user_group_user_active')
        ,'isNew': $form.find('#workflow_user_group_user_new')
        ,'btnCreateAndAdd': $form.find('#workflow_user_group_user_submit')
        ,'btnAdd': $form.find('#workflow_user_group_user_submit_add')
    }
};

contextIcmWorkflowUserGroup.resetForm = function(fields) {
    contextIcmWorkflowUserGroup.setUserData(fields, {
        name: ''
        ,email: ''
        ,language: 'en'
        ,accountType: 'PERSON'
        ,active: false
        ,isNew: true
    });
};

contextIcmWorkflowUserGroup.enableFormFields = function(fields) {
    contextIcmWorkflowUserGroup.toggleDisabled(fields, false);
};

contextIcmWorkflowUserGroup.disableFormFields = function(fields) {
    contextIcmWorkflowUserGroup.toggleDisabled(fields, true);
};

contextIcmWorkflowUserGroup.toggleDisabled = function(fields, disabled) {
    fields['name'].prop('disabled', disabled);
    fields['email'].prop('disabled', disabled);
    fields['language'].prop('disabled', disabled);
    fields['accountType'].prop('disabled', disabled);
    fields['usernamenew'].prop('disabled', disabled);
};

contextIcmWorkflowUserGroup.loadUser = function(fields, selected) {
    $.ajax({
        url: Routing.generate('peakwork_icm_workflowusergroup_usergroup_userdetail', {
            username: selected.id
        })
        ,dataType: 'json'
        ,method: 'POST'
        ,success: function(response) {
            if (response.success === false) {
                contextIcmWorkflowUserGroup.notify(response.msg, 'error');
            }

            contextIcmWorkflowUserGroup.setUserData(fields, response.data);
        }
    });
};

contextIcmWorkflowUserGroup.setUserData = function(fields, data) {
    fields['usernamenew'].val('');
    fields['name'].val(data.name);
    fields['email'].val(data.email);
    fields['language'].val(data.language);
    fields['accountType'].val(data.accountType);
    fields['active'].prop('checked', data.active);
    fields['isNew'].prop('checked', data.isNew);
};

contextIcmWorkflowUserGroup.inviteUser = function($inviteLink, $table) {
    if ($inviteLink.hasClass('disabled')) {
        return true;
    }

    $.ajax({
        url: $inviteLink.attr('href')
        ,dataType: 'json'
        ,method: 'POST'
        ,beforeSend: function() {
            $inviteLink.addClass('disabled');
        }
        ,success: function(response) {
            contextIcmWorkflowUserGroup.notify(response.msg, response.success ? 'success' : 'error');
            $table.DataTable().draw();
            $inviteLink.removeClass('disabled');
        }
    });
};

contextIcmWorkflowUserGroup.removeUserFromUserGroup = function($deleteLink, $table) {
    var dialog = BootstrapDialog.show({
        title: Translator.trans('removeuserfromusergroup.title', {}, contextIcmWorkflowUserGroup.translationDomain),
        message: PeakworkBackendHelper.getInstance().createLoader(),
        nl2br: false,
        size: BootstrapDialog.SIZE_WIDE,
        onshown: function(dialog) {
            $.ajax({
                url: $deleteLink.attr('href'),
                method: 'GET',
                dataType: 'json',
                success: function(response) {
                    dialog.setMessage(response.content);

                    if (response.success) {
                        dialog.indexedButtons['btn-ok'].removeClass('hidden');
                    }
                }
            });
        },
        buttons: [{
            label: '<span class="fa fa-ban"></span> ' + Translator.trans('removeuserfromusergroup.abort', {}, contextIcmWorkflowUserGroup.translationDomain),
            cssClass: 'btn-default',
            action: function(dialog) {
                dialog.close();
            }
        }, {
            id: 'btn-ok',
            label: '<span class="fa fa-check"></span> ' + Translator.trans('removeuserfromusergroup.confirm', {}, contextIcmWorkflowUserGroup.translationDomain),
            cssClass: 'btn-danger hidden',
            action: function(dialog) {
                dialog.indexedButtons['btn-ok'].disable();
                contextIcmWorkflowUserGroup.doRemoveUserFromUserGroup(dialog, $table);
            }
        }]
    });
};

contextIcmWorkflowUserGroup.doRemoveUserFromUserGroup = function(dialog, $table) {
    var $form = dialog.$modalBody.find('form');

    $.ajax({
        url: $form.attr('action'),
        data: $form.serialize(),
        method: 'POST',
        dataType: 'json',
        success: function(response) {
            contextIcmWorkflowUserGroup.notify(response.content, response.success ? 'success' : 'error');
            $table.DataTable().draw();
            dialog.close();
        }
    });
};


contextIcmWorkflowUserGroup.abortCreateWorkflow = function() {
    swal({
        title: Translator.trans('abortworkflow.confirmtitle', {}, contextIcmWorkflowUserGroup.translationDomain)
        ,text: Translator.trans('abortworkflow.confirmtext', {}, contextIcmWorkflowUserGroup.translationDomain)
        ,html: true
        ,type: 'warning'
        ,showCancelButton: true
        ,showConfirmButton: true
        ,closeOnCancel: true
        ,closeOnConfirm: false
        ,confirmButtonText: Translator.trans('abortworkflow.confirmbtn', {}, contextIcmWorkflowUserGroup.translationDomain)
        ,cancelButtonText: Translator.trans('abortworkflow.cancelbtn', {}, contextIcmWorkflowUserGroup.translationDomain)
    },function(isConfirm) {
        if (isConfirm) {
            $.ajax({
                url: Routing.generate('peakwork_icm_workflowusergroup_usergroup_create_abort')
                ,dataType: 'json'
                ,beforeSend: function() {
                    swal({
                        title: Translator.trans('abortworkflow.aborttitle', {}, contextIcmWorkflowUserGroup.translationDomain)
                        ,text: PeakworkBackendHelper.getInstance().createLoader()
                        ,html: true
                        ,showConfirmButton: false
                    });
                }
                ,success: function(response) {
                    window.location = response.redirectUri;
                }
            });
        }
    });
};

contextIcmWorkflowUserGroup.notify = function(message, type) {
    toastr.options = {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': false,
        'positionClass': 'toast-top-right',
        'preventDuplicates': false,
        'onclick': null,
        'showDuration': '300',
        'hideDuration': '1000',
        'timeOut': '5000',
        'extendedTimeOut': '1000',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut'
    };

    switch (type) {
        case 'error':
            toastr.error(message);
            break;
        case 'success':
            toastr.success(message);
            break;
        case 'warning':
            toastr.warning(message);
            break;
        default:
            toastr.info(message);
    }
};

$(function() {
    /* table rendering */
    var $table = $('#peakworkIcmWorkflowUsergroupUsersTable')
        ,$filter = $('#peakworkIcmWorkflowUsergroupUsersTableFilter')
        ,tempSearchVal = ''
        ,searchReloadTabledebounced = $.debounce(250, function () {
            $table.DataTable().draw();
        });

    if ($table.length === 1) {
        /* initialize the table */
        new PEAKWORK.ICM.BASE.List({
            tableDomId: '#peakworkIcmWorkflowUsergroupUsersTable'
            ,tableId: 'peakworkIcmWorkflowUsergroupUsersTable'
            ,dataTableIdentifier: 'peakworkIcmWorkflowUsergroupUsersTable'
            ,hasActivate: false
            ,hasDeactivate: false
            ,hasDelete: false
            ,addBtn: 'div.addBtn'
            ,translationDomain: 'peakworkicmworkflowusergroup'
            ,autoload: true
            ,saveFlashSettings: false
            ,hasActiveToggle: false
            ,filterDomId: '#peakworkIcmWorkflowUsergroupUsersTableFilter'
        });
        $.fn.dataTableExt.sErrMode = 'mute';

        $table.on('click', '.js-peakworkICMUserInviteBtn', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.inviteUser($(this), $table);
        });

        $table.find('input.js-form-tablechoice-search-input').on('focus', function () {
            tempSearchVal = $(this).val();
        }).on('keyup change', function () {
            var $input = $(this)
                ,inputValue = $input.val();

            if (tempSearchVal !== inputValue) {
                $filter.find('input[name="' + $input.data('field') + '"]').val(inputValue);
                searchReloadTabledebounced();
                tempSearchVal = inputValue;
            }
        });

        $table.on('click', '.js-peakworkICMUserRemoveFromUserGroupBtn', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.removeUserFromUserGroup($(this), $table);
        });
    }

    var $formContainer = $('#peakworkIcmWorkflowUsergroupUsersFormContainer');
    if ($formContainer.length === 1) {
        contextIcmWorkflowUserGroup.loadUserForm($formContainer);

        $formContainer.on('click', '.js-peakworkDetailFormAbort', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.loadUserForm($formContainer);
        });

        $formContainer.on('click', '.js-peakworkDetailFormSave', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.submitUserForm($formContainer, $table);
        });
    }

    var $createForm = $('#peakworkIcmWorkflowCreateUserGroupForm,#peakworkIcmWorkflowCreateRoleForm');
    if ($createForm.length === 1) {
        $createForm.on('click', '.js-peakworkDetailFormAbort', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.abortCreateWorkflow();
        });
    }

    var $ruleAbort = $('.js-peakworIcmWorkflowUserGroupAbort');
    if ($ruleAbort.length === 1) {
        $ruleAbort.on('click', function(e) {
            e.preventDefault();
            contextIcmWorkflowUserGroup.abortCreateWorkflow();
        });
    }

    var $workflowStepContainer = $('.js-peakworkICMWorkflowUserGroupCreateStep');
    if ($workflowStepContainer.length === 1) {
        contextIcmWorkflowUserGroup.checkSession();
    }
});