$(function() {
    var $usergroupSelectionForm = $('#peakworkIcmWorkflowUsergroupSelectionForm')
        ,$usergroupSelectionField = $usergroupSelectionForm.find('.js-peakworkIcmWorkflowUserGroupSelection');

    /* link to user page for selected group */
    $usergroupSelectionField.on('change', function (e) {
        var url = Routing.generate('peakwork_icm_workflowusergroup_usergroup_index', {
            id: e.val
        });

        swal({
            title: Translator.trans('loaduserpage', {}, 'peakworkicmworkflowusergroup')
            ,text: PeakworkBackendHelper.getInstance().createLoader()
            ,html: true
            ,showConfirmButton: false
        });

        window.location = url;
    });
});