(function($, window, document, undefined) {
    'use strict';

    var pluginName = 'pwTreeCollection';

    // Create the plugin constructor
    function PwTreeCollectionPlugin ( element, options ) {
        this.element = element;
        this._name = pluginName;
        this._defaults = $.fn[pluginName].defaults;
        this.options = $.extend( {}, this._defaults, options );
        this._numElements = 0;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(PwTreeCollectionPlugin.prototype, {

        // Initialization logic
        init: function () {
            var obj;
            this.buildCache();
            this.getNewItemText();
            this.getListItemText();
            this.getMinMax();
            this.getSortable();
            this.getPrototypeName();
            this.getItems();
            this.setCustomEvents();
            this.countItems();
            this.bindEvents();
            this.updateClasses();
            this.initSortable();
            this.updateObjects();
            obj = this.resolveObject();
            if (typeof this._fireOnInit === 'string' && $.isFunction(obj[this._fireOnInit])) {
                 obj[this._fireOnInit](this);
            }

            if (this._min !== false) {
                while (this._numElements < this._min) {
                    this.addItem(this);
                }
            }

            this.selectFirstListItem();
        },

        // Cache DOM nodes for performance
        buildCache: function () {
            this.$element = $(this.element);
            this.$listElement = this.$element.find('.' + this.options.listClass);
            this.$content = $(this.element).children('.' + this.options.contentClass);
            this.listItemTemplate = this.$element.find('.' + this.options.listItemTemplateClass).html();
            this.$element.find('.' + this.options.listItemTemplateClass).remove();
            this.template = this.$element.find('.' + this.options.templateClass).html();
            this.$element.find('.' + this.options.templateClass).remove();
        },

        setCustomEvents: function() {
            this._namespace = this.$element.data('namespace') || false;
            this._fireOnAdd = this.$element.data('fireonadd') || false;
            this._fireOnRemove = this.$element.data('fireonremove') || false;
            this._fireOnInit = this.$element.data('fireoninit') || false;
            this._useObject = this.$element.data('useobject') || false;
        },

        // Remove plugin instance completely
        destroy: function() {
            this.unbindEvents();
            this.$element.removeData();
        },

        // Bind events that trigger methods
        bindEvents: function() {
            var plugin = this;
            plugin.$element.on('click.' + plugin._name, '.' + plugin.options.listItemClass, function(event) {
                event.preventDefault();
                plugin.selectListItem.call(plugin, this);
            });

            plugin.$element.on('click.' + plugin._name, '.' + this.options.removeClass, function (event) {
                event.preventDefault();
                if ($(this).closest('.' + plugin.options.collectionClass).hasClass(plugin.options.collectionTreeClass)) {
                    plugin.removeItem.call(plugin,this);
                }
            });

            plugin.$element.on('click.' + plugin._name, '.' + this.options.addClass, function (event) {
                event.preventDefault();
                if ($(this).closest('.' + plugin.options.collectionClass).hasClass(plugin.options.collectionTreeClass)) {
                    plugin.addItem.call(plugin, this);
                }
            });

            plugin.$element.on('change.' + plugin._name, '.' + this.options.listItemUpdateClass, function (event) {
                plugin.updateListItemText.call(plugin, this);
            });

        },

        // Unbind events that trigger methods
        unbindEvents: function() {
            /*
             Unbind all events in our plugin's namespace that are attached
             to "this.$element".
             */
            this.$element.off('.' + this._name);
        },

        getNewItemText: function() {
            this._newItemText = this.$element.data('newitemtext') || '';
        }

        ,getListItemText: function() {
            this._listItemText = this.$element.data('listitemtext') || '';
            this._listItemTextFields = this.$element.data('listitemtextfields') || {};
        }

        ,initSortable: function() {
            if (false === this._sortable) {
                return;
            }

            var plugin = this
                ,$parent = (this.$listElement.parent());

            $parent.nestable({
                maxDepth: 1
                ,listNodeName: 'ul'
                ,listClass: 'tree ' + plugin.options.listClass
                ,itemClass: plugin.options.listItemClass
                ,rootClass: 'panel-body'
                ,handleClass: plugin.options.listItemDragClass
            });

            $parent.on('change', function() {
                plugin.handleSortChange();
            });
        }

        ,handleSortChange: function() {
            if (false === this._sortable) {
                return;
            }

            var $element = $(this.$listElement.parent())
                ,list = $element.nestable('serialize')
                ,i = 0
                ,length = list.length
                ,$posField;

            for (i; i < length; i++) {
                if ('undefined' === typeof list[i].target) {
                    continue;
                }

                $posField = $(list[i].target).find('.' + this._sortablePositionFieldClass);
                $posField.val(i);
            }
        }

        ,getMinMax: function() {
            this._min = this.$element.data('min') || false;
            this._max = this.$element.data('max') || false;
        },

        getPrototypeName: function() {
            this._prototypeName = this.$element.data('prototypename') || '__name__';
            this._prototypeRegExp = new RegExp(this._prototypeName, 'g');
        },

        countItems: function() {
            this._numElements = $(this.element).find('.' + this.options.itemClass).length;
        },

        getSortable: function() {
            this._sortable = this.$element.data('sortable');
            this._sortablePositionFieldClass = this.$element.data('sortablepositionfieldclass');
        }

        ,getItems: function() {
            this.$items = $(this.element).find('.' + this.options.itemClass);
        },

        resolveObject: function () {
            var obj = (typeof this._namespace === 'string') ? pwNamespace(this._namespace) : $.fn;
            if (typeof this._useObject === 'string' && typeof obj[this._useObject] !== 'undefined') {
                obj = obj[this._useObject];
            }
            return obj;
        },

        selectListItem: function(el) {
            var plugin = this
                ,$element = $(el)
                ,target = $($element.data('target'))
                ,items
                ,itemsLength
                ,i
                ,$item
                ,$content;

            $element.addClass('is-active');
            $('.' + plugin.options.listItemClass).not(el).removeClass('is-active');

            target.removeClass('hidden');
            items = plugin.$content.find('.' + plugin.options.itemClass).not(target);
            itemsLength = items.length;

            for (i = 0; i < itemsLength; i++) {
                $item = $(items[i]);
                $content = $item.closest('.' + plugin.options.contentClass);
                if ($content[0] === plugin.$content[0]) {
                    $item.addClass('hidden');
                }
            }
        }

        ,removeItem: function(el) {
            var obj = this.resolveObject()
                ,$element = $(el)
                ,$listItem
                ,isActive;

            if (typeof this._fireOnRemove !== 'undefined' && $.isFunction( obj[ this._fireOnRemove ] )) {
                obj[ this._fireOnRemove ](this);
            }

            $listItem = $element.closest('.' + this.options.listItemClass);
            isActive = $listItem.hasClass('is-active');
            $($listItem.data('target')).remove();
            if (true === isActive) {
                this.selectFirstListItem();
            }

            $listItem.remove();
            this.updateClasses();
            this.handleSortChange();
        },

        addItem: function(el) {
            var obj = this.resolveObject()
                ,newElement
                ,dateFields
                ,dateFieldsLength
                ,i;

            if (this._max !== false && this._numElements > this._max) {
                return;
            }

            /* add list element */
            $(el).before(
                this.listItemTemplate
                    .replace(this._prototypeRegExp, this._numElements)
                    .replace(this._listItemText, this._newItemText)
            );

            newElement = $(this.template.replace(this._prototypeRegExp, this._numElements));
            this.$content.append(newElement);

            if (typeof this._fireOnAdd !== 'undefined' && $.isFunction( obj[ this._fireOnAdd ] )) {
                obj[ this._fireOnAdd ](this, newElement);
            }

            this._numElements++;
            this.updateClasses();
            this.handleSortChange();
            this.selectLastListItem();

            dateFields = this.$content.find('.js-form-dt-date, .js-form-dt-datetime, .js-form-dt-time');
            dateFieldsLength = dateFields.length;

            for (i = 0; i < dateFieldsLength; i++) {
                pwNamespace('PEAKWORK.FORM.DATE').buildFromElement($(dateFields[i]));
            }
        },

        updateListItemText: function(el) {
            var $element = $(el)
                ,$formItem = $element.closest('.' + this.options.itemClass)
                ,id = $formItem.attr('id')
                ,listTextFields = $formItem.find('.' + this.options.listItemUpdateClass)
                ,$listItem = this.$listElement.find('li[data-target="#' + id + '"]').find('.' + this.options.listItemTextClass)
                ,listText = this._listItemText
                ,i = 0
                ,length = listTextFields.length
                ,listTextField;

            for (i; i < length; i++) {
                listTextField = $(listTextFields[i]);
                listText = listText.replace('%' + listTextField.data('listtext') + '%', listTextField.val());
            }

            $listItem.html(listText);
        }

        ,updateClasses: function() {
            var plugin = this
                ,length = this._listItemTextFields.length
                ,formFields
                ,formFieldsLength
                ,iFormFields
                ,$element
                ,i
                ,name;


            if (this._numElements === this._max && this._max !== false) {
                this.$element.addClass('has-max');
            } else {
                this.$element.removeClass('has-max');
            }

            if (this._numElements === this._min && this._min !== false) {
                this.$element.addClass('has-min');
            } else {
                this.$element.removeClass('has-min');
            }

            if (this._numElements > 0) {
                this.$element.addClass('has-items');
            } else {
                this.$element.removeClass('has-items');
            }

            formFields = this.$content.find('input,textarea,select');
            formFieldsLength = formFields.length;


            for (iFormFields = 0; iFormFields < formFieldsLength; iFormFields++) {
                name = formFields[iFormFields].name;

                if ('undefined' === typeof name) {
                    return;
                }

                for (i = 0; i < length; i++) {
                    if (-1 !== name.indexOf(plugin._listItemTextFields[i])) {
                        $element = $(formFields[iFormFields]);
                        $element.addClass(plugin.options.listItemUpdateClass);
                        $element.data('listtext', plugin._listItemTextFields[i]);
                        break;
                    }
                }
            }
        },

        /**
         * Sets all fields of a list item to readonly, if it is defined.
         */
        updateObjects: function() {
            var plugin = this
                ,listItems = this.$listElement.find('.' + plugin.options.listItemClass)
                ,listItemsLength = listItems.length
                ,i
                ,$item;

            for (i = 0; i < listItemsLength; i++) {
                $item = $(listItems[i]);
                if ($item.hasClass(plugin.options.listItemReadonlyClass)) {
                    plugin.setFieldsToReadonly($item);
                }
            }
        }

        ,setFieldsToReadonly: function($item) {
            var fields = $($item.data('target')).find('input,textarea,select')
                ,fieldsLength = fields.length
                ,i
                ,$field
                ,$tmpItem;

            for (i = 0; i < fieldsLength; i++) {
                $field = $(fields[i]);
                if ($field.is('select')) {
                    $tmpItem = $('<input name="' + $field.attr('name') + '" value="' + $field.val() + '" class="hidden" readonly>');
                    $field.after($tmpItem);
                    $field.prop('disabled', true);
                } else if ($field.is('input') && 'checkbox' === $field.attr('type').toLowerCase()) {
                    $tmpItem = $field.clone();
                    $tmpItem.addClass('hidden');
                    $field.after($tmpItem);
                    $field.prop('disabled', true);
                    $field.closest('.checkbox').addClass('disabled');
                } else {
                    $field.prop('readonly', true);
                }
            }
        }

        ,selectFirstListItem: function() {
            this.$listElement.find('.' + this.options.listItemClass).first().trigger('click');
        }

        ,selectLastListItem: function() {
            this.$listElement.find('.' + this.options.listItemClass).last().trigger('click');
        }

        ,callback: function() {
            // Cache onComplete option
            var onComplete = this.options.onComplete;

            if (typeof onComplete === 'function') {
                /*
                 Use the "call" method so that inside of the onComplete
                 callback function the "this" keyword refers to the
                 specific DOM node that called the plugin.

                 More: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
                 */
                onComplete.call(this.element);
            }
        }

    });

    /*
     Create a lightweight plugin wrapper around the "Plugin" constructor,
     preventing against multiple instantiations.

     More: http://learn.jquery.com/plugins/basic-plugin-creation/
     */
    $.fn[ pluginName ] = function( options ) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' +
                        pluginName, new PwTreeCollectionPlugin(this, options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            var returns;

            this.each(function () {
                var instance = $.data(this, 'plugin_' + pluginName);

                if (instance instanceof PwTreeCollectionPlugin && typeof instance[options] === 'function') {
                    returns = instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
                }

                if (options === 'destroy') {
                    $.data(this, 'plugin_' + pluginName, null);
                }
            });

            return returns !== undefined ? returns : this;
        }
    };

    $.fn[pluginName].defaults = {
        collectionClass: 'js-collection'
        ,collectionTreeClass: 'js-collection-tree'
        ,listClass: 'js-collection-list'
        ,listItemUpdateClass: 'js-collection-listitem-update'
        ,listItemReadonlyClass: 'js-collection-listitem-noteditable'
        ,listItemDragClass: 'js-collection-listitem-drag'
        ,listItemClass: 'js-collection-listitem'
        ,listItemTextClass: 'js-collection-listitem-text'
        ,listItemTemplateClass: 'js-collection-listitem-template'
        ,templateClass: 'js-collection-template'
        ,contentClass: 'js-collection-content'
        ,itemClass: 'js-collection-item'
        ,addClass: 'js-collection-add'
        ,removeClass: 'js-collection-remove'
        ,onComplete: null
    };

})( jQuery, window, document );

$(function() {
    var treeItems = $('.js-collection-tree')
        ,treeItemsLength = treeItems.length
        ,i
        ,$element;

    for (i = 0; i < treeItemsLength; i++) {
        $element = $(treeItems[i]);
        /* enable plugin only for collections which are not part of a hidden template */
        if (0 === $element.closest('.js-collection-template').length) {
            $element.pwTreeCollection();
        }
    }
});
