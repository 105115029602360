$(function() {
    var $fieldDefinition = $('.js-peakworkIcmResourceTypeFieldDefinition')
        ,context = pwNamespace('PEAKWORK.ICM.RESTCLIENTBUNDLE');


    if ($fieldDefinition.length > 0) {
        /* add change event to type field to enable/disable resourcetype selectbox and aliases */
        $fieldDefinition.on('change', '.js-peakworkIcmResourceTypeFieldDefinitionType', function() {
            var $select = $(this)
                ,$collectionItem = $select.closest('.js-collection-item')
                ,$resourceType = $collectionItem.find('.js-peakworkIcmResourceTypeFieldDefinitionResourceType')
                ,$aliases = $collectionItem.find('.js-peakworkIcmResourceTypeFieldDefinitionAliases')
                ,$aliasesAddBtn = $aliases.find('.js-form-collection-add');

            if ('Reference' === $select.val()) {
                $resourceType.select2('enable', true);
                $aliasesAddBtn.prop('disabled', false);
            } else {
                $resourceType.select2('enable', false);
                $resourceType.select2('val', '');

                $aliases.find('.js-form-collection-delete').each(function(index, delItem) {
                    $(delItem).trigger('click');
                });

                $aliasesAddBtn.prop('disabled', true);
            }
        });

        /* disable resource type and and aliases if type is not "Reference" */
        context.disableResourceTypeAndAliases = function(collectionItem) {
            var $resourceType = collectionItem.find('.js-peakworkIcmResourceTypeFieldDefinitionResourceType')
                ,$aliases = collectionItem.find('.js-peakworkIcmResourceTypeFieldDefinitionAliases')
                ,$aliasesAddBtn = $aliases.find('.js-form-collection-add')
                ,$select = collectionItem.find('.js-peakworkIcmResourceTypeFieldDefinitionType');

            if ('readonly' !== $select.attr('readonly') && 'Reference' !== $select.val()) {
                $resourceType.select2('enable', false);
                $aliasesAddBtn.prop('disabled', true);
            }
        };

        /* enable select2 for new added field and disable resource type and and aliases */
        context.executeAfterMyElementWasAdded = function(plugin, element) {
            PEAKWORK.FORM.AUTOCOMPLETE.init(element.find('.js-peakworkIcmResourceTypeFieldDefinitionResourceType'));
            PEAKWORK.ICM.RESTCLIENTBUNDLE.disableResourceTypeAndAliases(element);
        };

        /* disable resource type and and aliases on load if type is not "Reference" */
        $fieldDefinition.find('.js-peakworkIcmResourceTypeFieldDefinitionType').each(function(index, element) {
            PEAKWORK.ICM.RESTCLIENTBUNDLE.disableResourceTypeAndAliases($(this).closest('.js-collection-item'));
        });
    }
});