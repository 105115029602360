var contextIcmBaseForm = pwNamespace('PEAKWORK.ICM.BASE');
contextIcmBaseForm.FormsWithValidators = {};
contextIcmBaseForm.FormValidator = function (form, config) {
    config = config || {};
    var me = this;

    this.$form = $(form);
    this.invalidFieldGroupDefinitions = {};
    this.validator = null;
    this.config = $.extend(true, {
        ignore: ''
        ,focusInvalid: false
        ,ignoreTitle: true
        ,onclick: function(element) {
            var validator = this
                ,valid = false
                ,wasValid = false;

            if (element.name in validator.submitted) {
                wasValid = !(element.name in validator.invalid) || false === validator.invalid[element.name];
                valid = validator.element(element);
                me.checkElement(element, validator, valid, wasValid);
            } else if (element.parentNode.name in validator.submitted) {
                wasValid = !(element.name in validator.invalid) || false === validator.invalid[element.name];
                valid = validator.element(element.parentNode);
                me.checkElement(element, validator, valid, wasValid);
            }
        }
        ,onfocusout: function(element) {
            var validator = this
                ,valid = false
                ,wasValid = false
                ,$element = $(element);

            /**
             * get input element for select2 field
             */
            if ($element.hasClass('select2-offscreen')) {
                element = $element.parent().next()[0];
            }

            if (!validator.checkable(element) && (element.name in validator.submitted || !validator.optional(element))) {
                wasValid = !(element.name in validator.invalid) || false === validator.invalid[element.name];
                valid = validator.element(element);
                me.checkElement(element, validator, valid, wasValid);
            }
        }
        ,onkeyup: false
        ,invalidHandler: function(event, validator) {
            me.invalidHandler(event, validator);
        }
        ,highlight: function (element, errorClass, validClass) {
            me.highlight(element, errorClass, validClass, this);
        }
        ,unhighlight: function (element, errorClass, validClass) {
            me.unhighlight(element, errorClass, validClass, this);
        }
        ,errorPlacement: function (error, element) {
            var $element = $(element)
                ,checkContainer;

            if ($element.hasClass('js-fileupload-file') || $element.hasClass('js-fileupload-input')) {
                error.insertAfter($element.closest('.input-group'));
            } else if ($element.hasClass('js-peakworkIcmGenericTypeFieldDefinition-errorcounter')) {
                error.insertAfter($element.closest('.form-group').prev('label'));
            } else if ($element.hasClass('js-form-tablechoice-select')) {
                $element.closest('.form-group').append(error);
            } else {
                if ($element.is('input[type=checkbox]') || $element.is('input[type=radio]')) {
                    checkContainer = $element.closest('.form-group').children('div');
                    element = checkContainer.length !== 0 ? checkContainer : element;
                }

                error.insertAfter(element);
            }
        }
    }, config);

    this.init();
};

contextIcmBaseForm.FormValidator.prototype.init = function() {
    var me = this
        ,numberFields = me.$form.find('input[data-type=number]')
        ,numberFieldsLength = numberFields.length
        ,i
        ,rules = {};

    for (i = 0; i < numberFieldsLength; i++) {
        rules[numberFields[i].name] = {
            number: true
        };
    }

    me.initFieldDefinition(rules);

    this.validator = this.$form.validate($.extend(true, this.config, {
        rules: rules
    }));

    me.$form.on('click', '.js-form-tablechoice-statebtn', function() {
        var $btn = $(this)
            ,$element = $btn.closest('.js-form-tablechoice').children('.js-form-tablechoice-select')
            ,valid = false
            ,wasValid = false;

        if ($element[0].name in me.validator.submitted) {
            wasValid = !($element[0].name in me.validator.invalid);
            valid = me.validator.element($element[0]);
            me.checkElement($element[0], me.validator, valid, wasValid);
        }
    });
};

contextIcmBaseForm.FormValidator.prototype.initFieldDefinition = function(rules) {
    var me = this
        ,fieldDefinitions = me.$form.find('.js-peakworkIcmGenericTypeFieldDefinition')
        ,fieldDefinitionsLength = fieldDefinitions.length
        ,i
        ,$fieldDefinition
        ,$counterField
        ,counterFieldName
        ,value;

    for (i = 0; i < fieldDefinitionsLength; i++) {
        $fieldDefinition = $(fieldDefinitions[i]);
        if (true !== $fieldDefinition.data('required')) {
            continue;
        }

        counterFieldName = $fieldDefinition.attr('id') + '_counter';
        value = $fieldDefinition.find('.js-collection-listitem').length;
        $counterField = $('<input type="hidden" value="' + (1 === value ? '' : (value - 1)) + '" name="' +
            counterFieldName + '" class="js-peakworkIcmGenericTypeFieldDefinition-errorcounter">');
        $counterField.appendTo($fieldDefinition.closest('.form-group'));
        rules[counterFieldName] = {
            required: true
        };

        me.initFieldDefinitionEvents($fieldDefinition, $counterField);
    }
};

contextIcmBaseForm.FormValidator.prototype.initFieldDefinitionEvents = function($fieldDefinition, $counterField) {
    var me = this;

    $fieldDefinition.find('.js-collection-add').on('click', function(event) {
        var value = parseInt($counterField.val(), 10);
        $counterField.val(isNaN(value) ? 1 : (value + 1));
    });

    $fieldDefinition.on('click', '.js-collection-remove', function(event) {
        var value = parseInt($counterField.val() - 1, 10);
        $counterField.val(0 === value ? '' : value);

        if (0 === value && $counterField[0].name in me.validator.submitted) {
            me.validator.form();
        }
    });
};

contextIcmBaseForm.FormValidator.prototype.highlight = function(element, errorClass, validClass, validator) {
    var $element = $(element);
    if ($element.hasClass('js-form-autocomplete') || $element.hasClass('js-has-select2')) {
        $('#s2id_' + $element.attr('id') + ' .select2-choice').addClass('has-error');
    } else {
        $element.addClass(errorClass);
    }
};

contextIcmBaseForm.FormValidator.prototype.unhighlight = function(element, errorClass, validClass, validator) {
    var $element = $(element);
    if ($element.hasClass('js-form-autocomplete') || $element.hasClass('js-has-select2')) {
        $('#s2id_' + $element.attr('id') + ' .select2-choice').removeClass('has-error');
    } else {
        $element.removeClass(errorClass);
    }
};

contextIcmBaseForm.FormValidator.prototype.invalidHandler = function(event, validator) {
    var me = this
        ,invalidElements = validator.invalidElements()
        ,invalidElementsCount = invalidElements.length
        ,i = 0
        ,$invalidElement
        ,$fieldDefinition;

    me.resetInvalidFieldDefinitions();

    for (i; i < invalidElementsCount; i++) {
        $invalidElement = $(invalidElements[i]);
        if (false === $invalidElement.data('isfielddefinitionfield')) {
            continue;
        }

        $fieldDefinition = $invalidElement.closest('.js-peakworkIcmGenericTypeFieldDefinition');
        if (0 === $fieldDefinition.length) {
            $invalidElement.data('isfielddefinitionfield', false);
            continue;
        }

        me.markFieldDefinitionAsInvalid(
            me.getFieldDefintionListItem($invalidElement, $fieldDefinition)
        );
    }
};

contextIcmBaseForm.FormValidator.prototype.resetInvalidFieldDefinitions = function() {
    var me = this
        ,key;

    for (key in me.invalidFieldGroupDefinitions) {
        me.markFieldDefinitionAsValid(me.invalidFieldGroupDefinitions[key], true);
    }

    me.invalidFieldGroupDefinitions = {};
};

contextIcmBaseForm.FormValidator.prototype.markFieldDefinitionAsInvalid = function($listItem) {
    var errorCount = $listItem.data('errorcount') || 0;

    $listItem.data('errorcount', errorCount + 1);
    if (0 === $listItem.length || $listItem.hasClass('has-error')
    ) {
        return;
    }

    $listItem.addClass('has-error');
    $listItem.append('<span class="fa fa-exclamation-triangle text-danger"></span>');
};

contextIcmBaseForm.FormValidator.prototype.markFieldDefinitionAsValid = function($listItem, ignoreError) {
    var errorCount = $listItem.data('errorcount') || 0;

    if (true !== ignoreError && 0 !== errorCount - 1) {
        $listItem.data('errorcount', errorCount - 1);
        return;
    }

    $listItem.removeClass('has-error');
    $listItem.find('span.fa-exclamation-triangle').remove();
    $listItem.data('errorcount', 0);
};

contextIcmBaseForm.FormValidator.prototype.getFieldDefintionListItem = function($item, $fieldDefinition) {
    var me = this
        ,target;

    if ('undefined' !== typeof $item.data('listtarget') &&
        'undefined' !== typeof me.invalidFieldGroupDefinitions[$item.data('listtarget')]
    ) {
        return me.invalidFieldGroupDefinitions[$item.data('listtarget')];
    }

    $fieldDefinitionItem = $item.closest('.js-collection-item[id]');
    target = $fieldDefinitionItem.attr('id');
    $item.data('listtarget', target);
    $fieldDefinitionListItem = $fieldDefinition.find('li[data-target=#' + target + ']');
    me.invalidFieldGroupDefinitions[target] = $fieldDefinitionListItem;
    return $fieldDefinitionListItem;
};

contextIcmBaseForm.FormValidator.prototype.checkElement = function(element, validator, valid, wasValid) {
    var me = this
        ,$element = $(element)
        ,target = $element.data('listtarget');

    if (valid === wasValid ||
        false === $element.data('isfielddefinitionfield') ||
        'undefined' === typeof me.invalidFieldGroupDefinitions[target]
    ) {
        return;
    }

    if (true === valid) {
        me.markFieldDefinitionAsValid(me.invalidFieldGroupDefinitions[target]);
        return;
    }

    me.markFieldDefinitionAsInvalid(me.invalidFieldGroupDefinitions[target]);
};

$(function() {
    /**
     * Pattern validator
     */
    $.validator.addMethod('pattern', function(value, element, params) {
        var $element = $(element)
            ,pattern = new RegExp('^' + $element.attr('pattern') + '$');

        return pattern.test(value);
    }, $.validator.format(Translator.trans('form.validate.pattern', {}, 'peakworkicmbase')));

    $(document).on('submit', 'form.js-savemask', function(event) {
        swal({
            title: Translator.trans('form.saving', {}, 'peakworkicmbase')
            ,text: PeakworkBackendHelper.getInstance().createLoader()
            ,html: true
            ,showConfirmButton: false
        });
    });

    var forms = $('form.js-savemask')
        ,formsLength = forms.length
        ,i;

    for (i = 0; i < formsLength; i++) {
        PEAKWORK.ICM.BASE.FormsWithValidators[forms[i].id] = new PEAKWORK.ICM.BASE.FormValidator(forms[i], {});
    }
});