(function(){
    var contextToolkit = pwNamespace('PEAKWORK.BACKEND');

    /**
     * Injects input field on given "triggerContent" identified by "triggerSelector"
     * table needs class 'paginationInputInjection' for addon to be triggered and
     * a pagingType which renders a wildcard (triggerContent)
     * • numbers
     * • simple_numbers
     * • full_numbers
     * • first_last_numbers
     *
     *  v1.0
     * #tested with current chrome, firefox & edge with one and two tables
     *
     * todo: dynamic and/or external styles and triggerclasses (?)
     *
     * @type {{ACTIVATORSELECTOR, getInstance}}
     */
    contextToolkit.PagingInputInjection = (function() {
        var instance = null
        ,initInstance =  function() {
            // private
            var me = {

                conf: {
                    debug: false
                    ,debugColor1: '#FFF'
                    ,debugBg1: '#a74980'
                    ,debugColor2: '#000'
                    ,triggerIdPartial: '_ellipsis'
                    ,triggerSelector: '.paginate_button.disabled'
                    ,paginationClassSelector: '.dataTables_paginate'
                    ,tables: $(PEAKWORK.BACKEND.PagingInputInjection.ACTIVATORSELECTOR)
                    ,inputClass: 'addon-pagination-input-inject'
                    ,inputStyles: 'float: left; padding: 6px 12px; border: 1px solid #ddd; margin-left: -1px; width: 100px;'
                    ,triggerStyles: 'cursor: pointer;'
                    ,styleSheetInjection: ''
                    ,fetchIdRetries: 10
                    ,fetchIdIntervall: 500
                }

                

                /**
                 * init
                 */
                ,init: function() {
                    me.log('init');
                    me.overrideBehaviour();
                }

                /**
                 * sets listener on trigger and binds events to respective handlers
                 */
                ,overrideBehaviour: function() {
                    me.log('overrideBehaviour');

                    var tablePaginationSelector = ''
                        ,id = '';

                    me.conf.tables.each(function(index, item){
                        id = $(item).attr('id');
                        tablePaginationSelector = '#'+id+'_paginate';
                        me.conf.styleSheetInjection += ', #'+id+'_ellipsis a';
                        $('body').on('click', tablePaginationSelector+' '+me.conf.triggerSelector, me.onInputClassClickHandler);
                    });
                    me.conf.styleSheetInjection = me.conf.styleSheetInjection.substr(2);
                    me.conf.styleSheetInjection += '{'+me.conf.triggerStyles+'}';
                    $('head').append($('<style/>').text(me.conf.styleSheetInjection));
                }

                /**
                 * creates input field and directly binds eventHandler accordingly
                 *
                 * @returns {jQuery|HTMLElement}
                 */
                ,createInput: function(clickedElement) {
                    var tableSelector = '#' + clickedElement.parent().parent().attr('id').split('_paginate')[0]
                        ,table = $(tableSelector)
                        ,info = table.DataTable().page.info()
                        ,maxValue = info.pages
                        ,styles = me.conf.inputStyles
                        ,input = $('<input/>', { 'data-length': info.length, 'data-tableselector': tableSelector, type: 'number', min: 1, max: maxValue, style: styles, class: me.conf.inputClass, name: 'paginationInputInjectJumpNum', placeholder: '1 - '+maxValue });

                    input.on('keypress', me.onInputFieldKeyPressHandler);
                    input.on('focusout', me.onInputFieldFocusoutHandler);
                    return input;
                }

                /**
                 * trigger Click handler
                 * hides a-tag and retrieves and positions input field next to it
                 * (seemingly same position)
                 */
                ,onInputClassClickHandler: function() {
                    me.log('onInputClassClickHandler');
                    if(-1 === $(this).attr('id').indexOf(me.conf.triggerIdPartial)) {
                        return;
                    }
                    if (0 === $('.'+me.conf.inputClass).length) {
                        var input = me.createInput($(this));
                        $(this).append(input);
                        setTimeout(function(){ input.focus(); },0); // workaround for firefox because by now firefox sucks big time!
                    }
                    $(this).find('a').hide();
                }

                /**
                 * key press handler for the input field
                 * catches return (keycode 13) and triggers pagejump and table redraw
                 *
                 * @param event
                 * @returns {boolean}
                 */
                ,onInputFieldKeyPressHandler: function(event) {
                    if (13 == event.keyCode) {
                        var target = Number($(this).val())
                            ,max = Number($(this).attr('max'))
                            ,table = $($(this).data('tableselector'));
                        if(target > max) {
                            target = max;
                        }
                        if(target <= 0) {
                            target = 1;
                        }
                        me.log('redraw page('+(target-1)+')');
                        table.DataTable().page(target-1).draw(false);
                        return false;
                    }
                }

                /**
                 * input fields focus lost handler
                 * removes input field and shows the originally used a-tag
                 *
                 * @param event
                 */
                ,onInputFieldFocusoutHandler: function(event) {
                    $(this).parent().find('a').show();
                    $(this).remove();
                }

                /**
                 * wrapper for string console out
                 * (shorter to type and colored ...)
                 *
                 * @param message
                 */
                ,log: function(message) {
                    if (!me.conf.debug) {
                       return;
                    }
                    console.log(
                        '%c PagingInputInjection %c - '+message,
                        'background-color: '+me.conf.debugBg1 +'; color: '+ me.conf.debugColor1 +';' ,
                        'color: '+me.conf.debugColor2 +';'
                    );
                }
            }
            // public
            ,baseObj = {};
            // init
            me.init();

            return baseObj;
        };

        return {
            ACTIVATORSELECTOR: '.js-paginationInputInjection'
            ,getInstance: function() {
                if (null === instance) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
})();

$(function(){
    var paginationInputCandidates = $(PEAKWORK.BACKEND.PagingInputInjection.ACTIVATORSELECTOR)
        ,paginationInputCandidatesCount = 0;
    function triggerPaginationInputInjection() {
        var pagingInjection = PEAKWORK.BACKEND.PagingInputInjection.getInstance();
    }
    if (0 < paginationInputCandidates.length) {
        paginationInputCandidates.each(function(index, item){
            $(item).on('draw.dt', function() {
                paginationInputCandidatesCount++;
                if (paginationInputCandidatesCount === paginationInputCandidates.length) {
                    window.setTimeout(triggerPaginationInputInjection, 250);
                }
            });
        });
    }
});