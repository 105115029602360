;(function ( $, window, document, undefined ) {
    "use strict";

    var pluginName = 'pwCollection';

    // Create the plugin constructor
    function PwCollectionPlugin ( element, options ) {
        this.element = element;
        this._name = pluginName;
        this._defaults = $.fn[pluginName].defaults;
        this.options = $.extend( {}, this._defaults, options );
        this._numElements = 0;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(PwCollectionPlugin.prototype, {

        // Initialization logic
        init: function () {
            var obj, $addBtn;
            this.buildCache();
            this.getMinMax();
            this.getPrototypeName();
            this.getItems();
            this.setCustomEvents();
            this.countItems();
            this.updateClasses();
            this.bindEvents();
            obj = this.resolveObject();
            if (typeof this._fireOnInit === 'string' && $.isFunction(obj[this._fireOnInit])) {
                obj[this._fireOnInit](this);
            }
            if (this._min !== false) {
                $addBtn = this.$element.find('.' + this.options.addClass);
                while (this._numElements < this._min) {
                    this.addItem($addBtn);
                }
            }
        },

        setCustomEvents: function() {
            this._namespace = this.$element.data('namespace') || false;
            this._fireOnAdd = this.$element.data('fireonadd') || false;
            this._fireOnRemove = this.$element.data('fireonremove') || false;
            this._fireOnInit = this.$element.data('fireoninit') || false;
            this._useObject = this.$element.data('useobject') || false;
        },

        // Remove plugin instance completely
        destroy: function() {
            this.unbindEvents();
            this.$element.removeData();
        },

        // Cache DOM nodes for performance
        buildCache: function () {
            this.$element = $(this.element);
            this.getContent();
            this.$template = this.$element.find('.'+ this.options.templateClass).first().html();
            this.$element.find('.'+ this.options.templateClass).first().remove();
        },

        /**
         * Checks if the parent of the element is the correct fielddefinition.
         * @param $element
         * @returns {boolean}
         */
        checkParent: function($element) {
            var $item = $element.closest('.' + this.options.collectionClass);
            return $item[0] == $(this.element)[0];
        },

        // Bind events that trigger methods
        bindEvents: function() {
            var plugin = this;
            plugin.$element.on('click.' + plugin._name, '.'+ this.options.removeClass, function (event) {
                event.preventDefault();
                if (plugin.checkParent($(this))) {
                    plugin.removeItem.call(plugin,this); // plugin -> this; this -> eventHandler
                }
            });

            plugin.$element.on('click.' + plugin._name, '.'+ this.options.addClass, function (event) {
                event.preventDefault();
                if (plugin.checkParent($(this))) {
                    plugin.addItem.call(plugin, this);
                }
            });

            plugin.$element.on('click.' + plugin._name, '.js-closebox-panel-collection', function (event) {
                event.preventDefault();
                if (plugin.checkParent($(this))) {
                    plugin.removeItem.call(plugin,this); // plugin -> this; this -> eventHandler
                }
            });

            plugin.$element.on('click.' + plugin._name, '.js-showhide-panel-collection', function (event) {
                event.preventDefault();

                if (plugin.checkParent($(this))) {
                    var hpanel = $(this).closest('div.hpanel');
                    var icon = $(this).find('i:first');
                    var body = hpanel.find('div.panel-body');
                    var footer = hpanel.find('div.panel-footer');
                    body.slideToggle(300);
                    footer.slideToggle(200);

                    // Toggle icon from up to down
                    icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                    hpanel.toggleClass('').toggleClass('panel-collapse');
                    setTimeout(function () {
                        hpanel.resize();
                        hpanel.find('[id^=map-]').resize();
                    }, 50);
                }
            });
        },

        // Unbind events that trigger methods
        unbindEvents: function() {
            /*
                Unbind all events in our plugin's namespace that are attached
                to "this.$element".
            */
            this.$element.off('.'+this._name);
        },

        getMinMax: function() {
            this._min = this.$element.data('min') || false;
            this._max = this.$element.data('max') || false;
        },

        getPrototypeName: function() {
            this._prototypeName = this.$element.data('prototypename') || '__name__';
            this._prototypeRegExp = new RegExp(this._prototypeName, 'g');
        },

        countItems: function() {
            this._numElements = $(this.element).find('.'+ this.options.itemClass).length;
        },

        getContent: function() {
            this.$content = $(this.element).find('.'+ this.options.contentClass);
        },

        getItems: function() {
            this.$items = $(this.element).find('.'+ this.options.itemClass);
        },

        resolveObject: function () {
            var obj = (typeof this._namespace === 'string') ? pwNamespace(this._namespace) : $.fn;
            if (typeof this._useObject === 'string' && typeof obj[this._useObject] !== 'undefined') {
                obj = obj[this._useObject];
            }
            return obj;
        },

        removeItem: function(el) {
            var obj = this.resolveObject();

            if (this._min !== false && this._numElements <= this._min) {
                return;
            }

            if (typeof this._fireOnRemove !== 'undefined' && $.isFunction( obj[ this._fireOnRemove ] )) {
                obj[ this._fireOnRemove ](this);
            }
            $(el).closest('.'+ this.options.itemClass).remove();
            this._numElements--;
            this.updateClasses();
            this.updateTitleCount();
        },

        addItem: function(el) {
            var obj = this.resolveObject()
                ,newElement;

            if (this._max !== false && this._numElements >= this._max) {
                return;
            }

            newElement = $(this.$template.replace(this._prototypeRegExp, this._numElements));
            if ($(el).closest('.' + this.options.collectionClass).hasClass(this.options.collectionPanelClass)) {
                $(el).before(newElement); // add button inside content
            } else {
                this.$content.append(newElement); // add button outside content
            }

            if (typeof this._fireOnAdd !== 'undefined' && $.isFunction( obj[ this._fireOnAdd ] )) {
                obj[ this._fireOnAdd ](this, newElement);
            }

            this._numElements++;
            this.updateClasses();
            this.updateTitleCount();
            $.each(
                this.$content.find('.js-form-dt-date, .js-form-dt-datetime, .js-form-dt-time')
                ,function (idx, el) {
                    pwNamespace('PEAKWORK.FORM.DATE').buildFromElement($(el));
                }
            );
        },

        updateClasses: function() {
            if (this._numElements == this._max && this._max !== false) {
                this.$element.addClass('has-max');
            } else {
                this.$element.removeClass('has-max');
            }
            if (this._numElements == this._min && this._min !== false) {
                this.$element.addClass('has-min');
            } else {
                this.$element.removeClass('has-min');
            }

            if (this._numElements > 0) {
                this.$element.addClass('has-items');
            } else {
                this.$element.removeClass('has-items');
            }
        },

        updateTitleCount: function() {
            this.$element.find('.' + this.options.itemTitleCountClass).each(function(index, item) {
                $(item).html(index+1);
            });
        },

        callback: function() {
            // Cache onComplete option
            var onComplete = this.options.onComplete;

            if ( typeof onComplete === 'function' ) {
                /*
                    Use the "call" method so that inside of the onComplete
                    callback function the "this" keyword refers to the
                    specific DOM node that called the plugin.

                    More: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
                */
                onComplete.call(this.element);
            }
        }

    });

    /*
        Create a lightweight plugin wrapper around the "Plugin" constructor,
        preventing against multiple instantiations.

        More: http://learn.jquery.com/plugins/basic-plugin-creation/
    */
    $.fn[ pluginName ] = function( options ) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            return this.each(function () {
                if (!$.data(this, "plugin_" + pluginName)) {
                    $.data(this, "plugin_" +
                        pluginName, new PwCollectionPlugin(this, options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            var returns;

            this.each(function () {
                var instance = $.data(this, 'plugin_' + pluginName);

                if (instance instanceof PwCollectionPlugin && typeof instance[options] === 'function') {
                    returns = instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
                }

                if (options === 'destroy') {
                  $.data(this, 'plugin_' + pluginName, null);
                }
            });

            return returns !== undefined ? returns : this;
        }
    };

    $.fn[pluginName].defaults = {
        collectionClass: 'js-collection',
        collectionPanelClass: 'js-collection-panel',
        templateClass: 'js-collection-template',
        contentClass: 'js-collection-content',
        itemClass: 'js-collection-item',
        addClass: 'js-collection-add',
        removeClass: 'js-collection-remove',
        itemTitleCountClass: 'js-collection-item-titlecount',
        onComplete: null
    };

})( jQuery, window, document );

$(function() {
    $('.js-collection-panel, .js-collection-base, .js-collection-table').each(function(index, item){
        var $element = $(item);
        /* enable plugin only for collections which are not part of a hidden template */
        if (0 === $element.closest('.js-collection-template').length) {
            $element.pwCollection();
        }
    });
});
