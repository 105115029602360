var contextPeakworkFormFile = pwNamespace('PEAKWORK.FORM.FILE');

contextPeakworkFormFile.initFileField = function($element) {
    var $container = $element.closest('.input-group')
        ,$inputElement = $container.find('.js-fileupload-input');

    if ($element.prop('required')) {
        $element.prop('required', false);
        $element.removeAttr('required');
        $inputElement.prop('required', true);
        $inputElement.attr('required', true);
    }

    $element.trigger('initFileField');
};

contextPeakworkFormFile.fileUploaded = function($element) {
    var fileName = $element.val().replace(/\\/g, '/').replace(/.*\//, '')
        ,$container = $element.closest('.input-group')
        ,$inputElement = $container.find('.js-fileupload-input')
        ,$removeElement = $container.find('.js-fileupload-remove');

    $inputElement.val(fileName);
    $removeElement.removeClass('disabled');

    $element.trigger('fileUploaded', [{
        'fileName': fileName
    }]);
};

contextPeakworkFormFile.clickFileUpload = function($element) {
    var $container = $element.closest('.input-group')
        ,$inputElement = $container.find('.js-fileupload-file');

    $inputElement.trigger('click');
};

contextPeakworkFormFile.removeFile = function($element) {
    var $container = $element.closest('.input-group')
        ,$fileElement = $container.find('.js-fileupload-file')
        ,$inputElement = $container.find('.js-fileupload-input');

    $fileElement.replaceWith($($fileElement).val('').clone(true));
    $inputElement.val('');
    $element.addClass('disabled');
    $element.trigger('fileRemoved', [$fileElement]);
};

$(function() {
    var fileUploadFields = $('.js-fileupload-file')
        ,fileUploadFieldsLength = fileUploadFields.length
        ,i;

    for (i = 0; i < fileUploadFieldsLength; i++) {
        contextPeakworkFormFile.initFileField($(fileUploadFields[i]));
    }

    $(document).on('change', '.js-fileupload-file:file', function() {
        contextPeakworkFormFile.fileUploaded($(this));
    });

    $(document).on('click', '.js-fileupload-input', function() {
        contextPeakworkFormFile.clickFileUpload($(this));
    });

    $(document).on('click', '.js-fileupload-remove', function(event) {
        var $element = $(this);
        if (!$element.hasClass('disabled')) {
            contextPeakworkFormFile.removeFile($element);
        }
    });
});