$(function() {
    /* change event to office  field to load table */
    var translationDomain = 'peakworkicmworkflowoffice'
        ,$officeSearchElement = $('.js-peakworkIcmWorkflowOfficePwOffice ')
        ,$customerSearchElement = $('.js-peakworkIcmWorkflowOfficePwCustomer')
        ,$table = $('#peakworkIcmWorkflowOfficeListTable')
        ,$tableContainer = $table.closest('.hpanel')
        ,$filter = $('#peakworkIcmWorkflowOfficeSearchFilter')
        ,$contentContainer = $('.js-form-detail-output-container')
        ,searchReloadTabledebounced = $.debounce(250, function () {
            $table.DataTable().ajax.reload();
        })
        ,tempSearchVal = '';

    if (1 === $table.length) {
        /* initialize the table */
        new PEAKWORK.ICM.BASE.List({
            tableDomId: '#peakworkIcmWorkflowOfficeListTable'
            ,tableId: 'peakworkIcmWorkflowOfficeListTable'
            ,dataTableIdentifier: 'peakworkIcmWorkflowOfficeListTable'
            ,filterDomId: '#peakworkIcmWorkflowOfficeSearchFilter'
            ,filterSubmittedEvent: 'peakworkIcmWorkflowOfficeListFilterSubmitted'
            ,hasActivate: false
            ,hasDeactivate: false
            ,hasDelete: false
            ,addBtn: 'div.addBtn'
            ,addDetailRoute: 'peakwork_icm_workflowoffice_crsrouting_register'
            ,addBtnInitEvent: 'peakworkIcmWorkflowOfficeListAddBtnInit'
            ,translationDomain: 'peakworkicmworkflowoffice'
            ,autoload: false
            ,saveFlashSettings: false
            ,hasActiveToggle: true
            ,activeToggleMethod: 'POST'
            ,activeToggle: '.js-peakworkIcmGenericToggleActive'
            ,resource: 'crsrouting'
        });
        $.fn.dataTableExt.sErrMode = 'mute';

        /* click event on register to load crsrouting */
        $table.on('peakworkIcmWorkflowOfficeListAddBtnInit', function(event, $addBtn) {
            if (!$addBtn.hasClass('js-customer-click-is-init')) {
                $addBtn.addClass('js-customer-click-is-init');
                $addBtn.removeClass('js-details-view');
                $addBtn.on('click', function(e) {
                    e.preventDefault();
                    new PEAKWORK.ICM.WORKFLOW.CrsRouting({
                        'element': $(this)
                        ,'contentContainer': $contentContainer
                        ,'filter': $filter
                        ,'pwcustomerField': $customerSearchElement
                        ,'pwofficeField': $officeSearchElement
                    });
                });
            }
        });

        /* remove office and hide table */
        $customerSearchElement.on('change', function (e) {
            $officeSearchElement.select2('val', '');
            $tableContainer.addClass('hidden');
            $contentContainer.html('');
        });

        /* load and show table, remvoe content html */
        $officeSearchElement.on('change', function(e) {
            $contentContainer.html('');
            if ('' !== e.val) {
                $tableContainer.removeClass('hidden');
                $filter.trigger('peakworkIcmWorkflowOfficeListFilterSubmitted');
            } else {
                $tableContainer.addClass('hidden');
            }
        });

        /* hide table */
        $customerSearchElement.on('select2-removed', function (e) {
            $tableContainer.addClass('hidden');
        });

        /* CRS Routing */
        $table.on('click', '.js-peakworkICMWorkflowCrsInstanceEdit', function(e) {
            e.preventDefault();
            new PEAKWORK.ICM.WORKFLOW.CrsRouting({
                'element': $(this)
                ,'contentContainer': $contentContainer
                ,'filter': $filter
                ,'pwcustomerField': $customerSearchElement
                ,'pwofficeField': $officeSearchElement
            });
        });

        /* PaymentInstance */
        $table.on('click', '.js-peakworkICMWorkflowPaymentEdit', function(e) {
            e.preventDefault();
            new PEAKWORK.ICM.WORKFLOW.Payment({
                'element': $(this)
                ,'contentContainer': $contentContainer
                ,'filter': $filter
                ,'pwcustomerField': $customerSearchElement
                ,'pwofficeField': $officeSearchElement
                ,'table': $table
            });
        });

        /* Delete row */
        $table.on('click', '.js-delete-row', function(e) {
            e.preventDefault();
            var $button = $(this);

            swal({
                title: Translator.trans('deleteall.confirm.title', {}, translationDomain)
                ,text: Translator.trans('deleteall.confirm.text', {}, translationDomain)
                ,type: 'warning'
                ,showCancelButton: true
                ,confirmButtonColor: '#DD6B55'
                ,confirmButtonText: Translator.trans('deleteall.confirm.confirmbtn', {}, translationDomain)
                ,closeOnConfirm: true
                ,closeOnCancel: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $.ajax({
                        url: $button.attr('href')
                        ,method: 'POST'
                        ,data: {
                            'id': $button.data('id')
                            ,'pwoffice': $button.data('pwoffice')
                            ,'pwcustomer': $button.data('pwcustomer')
                            ,'touroperator': $button.data('touroperator')
                            ,'traveltype': $button.data('traveltype')
                        }
                        ,beforeSend: function() {
                            swal({
                                title: Translator.trans('deleteall.waiting.title', {}, translationDomain)
                                ,text: PeakworkBackendHelper.getInstance().createLoader()
                                ,html: true
                                ,type: 'info'
                                ,showConfirmButton: false
                            });
                        }
                        ,success: function(response) {
                            $table.DataTable().draw();
                            $contentContainer.html('');

                        }
                    });
                }
            });
        });

        $table.find('input.js-form-tablechoice-search-input').on('focus', function () {
            tempSearchVal = $(this).val();

        }).on('keyup change', function () {
            if (tempSearchVal !== $(this).val()) {
                $filter.find('input.js-peakworkIcmWorkflowOfficeTouroperator').attr('value', $(this).val());
                searchReloadTabledebounced();
                tempSearchVal = $(this).val();
            }
        });
    }
});