//noinspection Eslint
var contextIcmGenericHistory = pwNamespace('PEAKWORK.ICM.GENERIC');
contextIcmGenericHistory.History = function (config, $container) {
    config = config || {};

    this.$container = $container || $(document);
    this.config = $.extend(true, {
        viewBtn: '.js-generic-history-view'
        ,compareContentBox: 'icmPeakworkGenericHistoryComparison'
        ,compareCheckbox: '.js-generic-history-compare'
        ,getRouteId: 'peakwork_icm_generic_list_history_index'
        ,translationDomain: 'peakworkicmgeneric'
        ,instanceId: null
        ,compareRouteId: 'peakwork_icm_generic_list_history_compare'
        ,filterSubmitEvent: 'icmPeakworkGenericHistorySearchFilterFilterSubmitted'
    }, config);

    this.compareItems = [];
    this.$compareLink = null;
    this.$comparisonContainer = null;
    this.$comparisonContainerContent = null;

    this.init();
};

contextIcmGenericHistory.History.prototype.init = function() {
    var me = this;

    me.$container.on('click', me.config.viewBtn, function(event) {
        me.displayHistoryView($(this), event);
    });
};

contextIcmGenericHistory.History.prototype.getInstanceId = function(element) {
    var me = this;

    if (me.config.instanceId === null) {
        return element.data('instanceid');
    }

    return me.config.instanceId;
};

contextIcmGenericHistory.History.prototype.displayHistoryView = function(element, event) {
    var me = this;

    me.dialog = BootstrapDialog.show({
        title: '<i class="fa fa-history"></i> ' + Translator.trans('history.title', {}, me.config.translationDomain),
        message: PeakworkBackendHelper.getInstance().createLoader(),
        nl2br: false,
        size: BootstrapDialog.SIZE_WIDE,
        onshown: function(dialog) {
            $.ajax({
                url: Routing.generate(me.config.getRouteId, {
                    resource: me.config.resource
                }),
                data: {
                    id: me.getInstanceId(element)
                },
                method: 'GET',
                dataType: 'html',
                success: function(response) {
                    dialog.setMessage(response);
                    me.initList();
                    me.initCompareContainer();
                    me.initDateTimeFilter();
                    me.initEvents();
                }
            });
        },
        buttons: [{
            label: '<span class="fa fa-times"></span> ' + Translator.trans('history.close', {}, me.config.translationDomain),
            cssClass: 'btn-default',
            action: function(dialog) {
                dialog.close();
            }
        }]
    });
};

contextIcmGenericHistory.History.prototype.initList = function() {
    var me = this;

    $('#icmPeakworkGenericHistoryTable').on('draw.dt', function(e, settings) {
        me.initDataTableAddOns();
        me.checkCheckedCompareBox($(e.target));
        me.emptyCompareContainer();
    });

    $('#icmPeakworkGenericHistorySearchFilter').on(me.config.filterSubmitEvent, function() {
        me.compareItems = [];
    });

    new PEAKWORK.ICM.GENERIC.List({
        resource: me.config.resource
        ,tableDomId: '#icmPeakworkGenericHistoryTable'
        ,tableId: 'icmPeakworkGenericHistoryTable'
        ,dataTableIdentifier: 'icmPeakworkGenericHistoryTable'
        ,filterDomId: '#icmPeakworkGenericHistorySearchFilter'
        ,filterSubmittedEvent: me.config.filterSubmitEvent
        ,domString: '<"clear">lfrt<"clear"><"compareBtn"><"clear"><"clear">ip'
        ,legacy: true === me.config.legacy
    });
};

contextIcmGenericHistory.History.prototype.initCompareContainer = function() {
    var me = this;

    me.$comparisonContainer = $('#' + me.config.compareContentBox);
    me.$comparisonContainerContent = me.$comparisonContainer.find('div');
    me.$comparisonContainer.on('click', 'button', function(event) {
        event.preventDefault();
        me.emptyCompareContainer();
    });
};

contextIcmGenericHistory.History.prototype.emptyCompareContainer = function() {
    var me = this;
    if (null !== me.$comparisonContainer) {
        me.$comparisonContainerContent.html('');
        me.$comparisonContainer.addClass('hidden');
    }
};

contextIcmGenericHistory.History.prototype.initDateTimeFilter = function() {
    var me = this
        ,dateFields = me.dialog.$modalBody.find('.js-form-dt-date')
        ,dateFieldsLength = dateFields.length
        ,i;

    for (i = 0; i < dateFieldsLength; i++) {
        PEAKWORK.FORM.DATE.buildFromElement($(dateFields[i]));
    }
};

contextIcmGenericHistory.History.prototype.initEvents = function() {
    var me = this;

    me.dialog.$modalBody.on('click', me.config.compareCheckbox, function(event) {
        me.checkCompareBox($(this));
    });
};

contextIcmGenericHistory.History.prototype.checkCompareBox = function($checkbox) {
    var me = this
        ,isChecked = 'undefined' !== typeof $checkbox.attr('checked');

    if (isChecked) {
        me.removeCompare($checkbox);
        me.toggleCompareBtn();
        return;
    }

    if (2 === me.compareItems.length) {
        me.reduceCheckedCompares($checkbox);
    }

    me.compareItems[me.compareItems.length] = $checkbox;
    $checkbox.attr('checked', 'checked');
    $checkbox.prop('checked', true);

    me.toggleCompareBtn();
};

contextIcmGenericHistory.History.prototype.removeCompare = function($checkbox) {
    var me = this
        ,hasItem0 = 'undefined' !== typeof me.compareItems[0]
        ,hasItem1 = 'undefined' !== typeof me.compareItems[1];

    if (hasItem0 && me.compareItems[0].data('id') === $checkbox.data('id')) {
        me.removeCompareCheckedAttribute(me.compareItems[0]);
        if (hasItem1) {
            me.compareItems[0] = me.compareItems[1];
            me.compareItems.splice(1,1);
        } else {
            me.compareItems.splice(0,1);
        }
        return 0;
    }

    if (hasItem1 && me.compareItems[1].data('id') === $checkbox.data('id')) {
        me.removeCompareCheckedAttribute(me.compareItems[1]);
        me.compareItems.splice(1,1);

        return 1;
    }

    return -1;
};

contextIcmGenericHistory.History.prototype.reduceCheckedCompares = function($checkbox) {
    var me = this;

    me.removeCompareCheckedAttribute(me.compareItems[0]);
    me.compareItems[0] = me.compareItems[1];
    me.compareItems.splice(1,1);
};

contextIcmGenericHistory.History.prototype.removeCompareCheckedAttribute = function($checkbox) {
    $checkbox.removeAttr('checked');
    $checkbox.prop('checked', false);
};

contextIcmGenericHistory.History.prototype.toggleCompareBtn = function() {
    var me = this;

    if (me.compareItems.length !== 2) {
        me.$compareLink.prop('disabled', true);
        me.$compareLink.attr('disabled', 'disabled');
        return;
    }

    me.$compareLink.prop('disabled', false);
    me.$compareLink.removeAttr('disabled');
};

contextIcmGenericHistory.History.prototype.initDataTableAddOns = function($checkbox) {
    var me = this
        ,compareContainer = me.dialog.$modalBody.find('.compareBtn')
        ,disabledString = 2 !== me.compareItems.length ? 'disabled' : '';

    me.$compareLink = $('<button class="btn btn-default js-history-compare" href="" ' + disabledString + '>' +
        '<span><i class="fa fa-exchange"> ' +
            Translator.trans('history.compare.button', {}, me.config.translationDomain) +
        '</i></span>' +
        '</button>');
    compareContainer.html(me.$compareLink);

    me.$compareLink.on('click', function(event) {
        event.preventDefault();
        me.compare();
    });
};

contextIcmGenericHistory.History.prototype.compare = function() {
    var me = this;
    
    $.ajax({
        url: Routing.generate(me.config.compareRouteId, {
            resource: me.config.resource
            ,id1: me.compareItems[0].data('id')
            ,id2: me.compareItems[1].data('id')
        }),
        data: {
            legacy: this.config.legacy || false
        }
        ,success: function(response) {
            me.$comparisonContainerContent.html(response);
            me.$comparisonContainer.removeClass('hidden');
        }
    });
};

contextIcmGenericHistory.History.prototype.checkCheckedCompareBox = function($table) {
    if (0 === this.compareItems.length) {
        return;
    }

    var me = this
        ,i = 0
        ,ids = []
        ,checkboxes = $table.find(me.config.compareCheckbox)
        ,$checkbox;


    for (i = 0; i < me.compareItems.length; i++) {
        ids.push(me.compareItems[i].data('id'));
    }

    for (i = 0; i < checkboxes.length; i++) {
        $checkbox = $(checkboxes[i]);
        if (-1 !== $.inArray($checkbox.data('id'), ids)) {
            $checkbox.attr('checked', 'checked');
            $checkbox.prop('checked', true);
        }
    }
};
