var contextIcmBaseAutocomplete = pwNamespace('PEAKWORK.ICM.BASE');

contextIcmBaseAutocomplete.AutoCompleteCreate = function ($element, config) {
    config = config || {};

    this.config = $.extend(true, {
        translationDomain: 'peakworkicmbase'
        ,editBtn: 'js-form-autocomplete-generic-edit'
        ,viewBtn: 'js-form-autocomplete-generic-view'
        ,initClass: 'js-form-autocomplete-init'
        ,attention: true
        ,attentionElementClass: 'attention-element'
        ,attentionBackgroundClass: 'attention-background'
        ,initAllowCreateSelectingEvent: true
        ,initSelection: true
    }, config);

    this.$element = $element;
    this.formValidator = null;
    this.$attentionBackground = null;

    this.init();
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.init = function() {
    var me = this;
    me.initCache();
    me.initSelect2();
    me.initEditDetail();
    me.initViewDetail();
    me.initSelectEvent();
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initCache = function() {
    this.$form = this.$element.closest('form');
    this.isDisabled = this.$element.prop('disabled');
    this.$elementContainer = this.$element.closest('.row');
    this.$contentContainer = this.$element.next('.js-form-autocomplete-createcontent');
    this.$editBtn = this.$elementContainer.find('.' + this.config.editBtn);
    this.editRoute = this.$editBtn.data('route');
    this.editRouteParams = this.$editBtn.data('routeparams');
    this.$viewBtn = this.$elementContainer.find('.' + this.config.viewBtn);
    this.getRoute = this.$viewBtn.data('route');
    this.getRouteParams = this.$viewBtn.data('routeparams');
    this.checkCreatePermission();
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.checkCreatePermission = function() {
    var me = this;
    me.allowCreate = me.$element.data('allowcreate');

    if (false === me.isDisabled && 'undefined' !== typeof me.$element.data('checkcreateroute')) {
        me.allowCreate = false;
        $.ajax({
            url: Routing.generate(me.$element.data('checkcreateroute'), me.$element.data('checkcreaterouteparams'))
            ,dataType: 'json'
            ,success: function(response) {
                me.allowCreate = response.allowed;
            }
        });
    }
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.getAttentionBackground = function() {
    var me = this;

    if (null === me.$attentionBackground) {
        me.$attentionBackground = me.$form.find('.' + me.config.attentionBackgroundClass);
        if (0 === me.$attentionBackground.length) {
            me.$attentionBackground = $('<div class="' + me.config.attentionBackgroundClass + ' hidden"></div>');
            me.$form.after(me.$attentionBackground);
        }
    }

    return me.$attentionBackground;
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.enableAttention = function() {
    var me = this
        ,$attentionBackground;

    if (false === me.config.attention) {
        return true;
    }

    $attentionBackground = me.getAttentionBackground();
    $attentionBackground.removeClass('hidden');
    me.$elementContainer.addClass(me.config.attentionElementClass);
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.disableAttention = function() {
    var me = this
        ,$attentionBackground;

    if (false === me.config.attention) {
        return true;
    }

    $attentionBackground = me.getAttentionBackground();
    me.$elementContainer.removeClass(me.config.attentionElementClass);
    $attentionBackground.addClass('hidden');
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.enableBtns = function() {
    this.$viewBtn.removeClass('disabled');
    this.$editBtn.removeClass('disabled');
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.disableBtns = function() {
    this.$viewBtn.addClass('disabled');
    this.$editBtn.addClass('disabled');
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.hideBtns = function() {
    this.disableBtns();
    this.$viewBtn.addClass('hidden');
    this.$editBtn.addClass('hidden');
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.showBtns = function() {
    this.enableBtns();
    this.$viewBtn.removeClass('hidden');
    this.$editBtn.removeClass('hidden');
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initSelect2 = function() {
    var me = this;
    if (me.$element.hasClass(me.config.initClass)) {
        return;
    }

    me.$element.addClass(me.config.initClass);
    me.$element.select2({
        minimumInputLength: parseInt(me.$element.data('minimuminputlength'), 10)
        ,allowClear: me.$element.data('allowclear')
        ,formatNoMatches: me.$element.data('formatnomatches')
        ,formatSearching: me.$element.data('formatsearching')
        ,formatInputTooShort: me.$element.data('formatinputtooshort')
        ,ajax: {
            url: me.$element.data('searchroute')
            ,dataType: 'json'
            ,quietMillis: 250
            ,data: function (term, page) {
                var params = me.$element.data('query-params-autocomplete') ? me.$element.data('query-params-autocomplete') : {};
                if (me.$element.data('query-params-autocomplete-search')) {
                    $.extend(params, me.$element.data('query-params-autocomplete-search'));
                }
                params.q = term;
                return params;
            }
            ,results: function (data, page) {
                return {
                    results: data
                };
            }
            ,cache: true
        }
        ,initSelection: function(element, callback) {
            me.initSelection($(element), callback);
        }
        ,createSearchChoice: function(term, data) {
            var result = false;
            if (false !== (result = me.createSearchChoice(term, data))) {
                return result;
            }
        }
        ,dropdownCssClass: 'bigdrop'
        ,escapeMarkup: function (m) {
            return m;
        }
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initEditDetail = function() {
    var me = this;

    me.$editBtn.on('click', function(event) {
        event.preventDefault();

        if ($(this).hasClass('disabled')) {
            return;
        }

        me.loadDetailForm(Routing.generate(me.editRoute, $.extend(true, me.editRouteParams, {
            'id': me.$element.select2('val')
        })));
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initViewDetail = function() {
    var me = this;

    me.$viewBtn.on('click', function(event) {
        event.preventDefault();

        if ($(this).hasClass('disabled')) {
            return;
        }

        me.loadDetailForm(Routing.generate(me.getRoute, $.extend(true, me.getRouteParams, {
            'id': me.$element.select2('val')
        })));
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initSelection = function($initElement, callback) {
    var me = this
        ,id = $initElement.val()
        ,params;


    if (id !== '' && me.config.initSelection !== false) {
        if (typeof me.config.initSelection === 'function') {
            me.config.initSelection.call(me, id, callback);
        } else {
            me.enableBtns();
            params = $initElement.data('query-params-autocomplete') ? $initElement.data('query-params-autocomplete') : {};
            if (me.$element.data('query-params-autocomplete-get')) {
                $.extend(params, me.$element.data('query-params-autocomplete-get'));
            }
            params.id = id;

            $.ajax({
                url: $initElement.data('getroute')
                ,dataType: 'json'
                ,data: params
            }).done(function(data) {
                callback(data);
                me.handleBtnsAfterSelect2Selection(data);
            });
        }
    }
};


contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.handleBtnsAfterSelect2Selection = function(data) {
    if ('undefined' === typeof data.data ||
        'undefined' === typeof data.data.permissions ||
        false === data.data.permissions.get
    ) {
        this.hideBtns();
        return true;
    }

    if (false === this.isDisabled && true === data.data.permissions.edit) {
        this.showBtns();
        return true;
    } else {
        this.$editBtn.addClass('disabled');
        this.$editBtn.addClass('hidden');
    }

    this.$viewBtn.removeClass('disabled');
    this.$viewBtn.removeClass('hidden');
    return true;
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.createSearchChoice = function(term, data) {
    var me = this;

    if (true !== me.allowCreate) {
        return false;
    }

    if ($(data).filter(function() { return this.text.localeCompare(term) === 0; }).length === 0) {
        return me.getRegisterNewValue(term);
    }

    return false;
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.getRegisterNewValue = function(term) {
    var me = this;
    return {
        id: 'registernew'
        ,text: term + ' (' + Translator.trans('form.autocomplete.createnew', {}, me.config.translationDomain) + ')'
        ,data: {
            name: term
        }
    };
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initSelectEvent = function() {
    var me = this;

    if (true !== me.$element.data('allowcreate') || false === me.config.initAllowCreateSelectingEvent) {
        return false;
    }

    me.$element.on('select2-selecting', function(event) {
        if ('registernew' === event.val) {
            me.hideBtns();
            me.loadDetailForm(me.$element.data('createroute'));
            return true;
        }

        if ('' === event.val) {
            me.disableBtns();
        } else {
            me.handleBtnsAfterSelect2Selection(event.object);
        }

        me.closeDetailForm();
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.loadDetailForm = function(url) {
    var me = this;

    $.ajax({
        url: url
        ,success: function(response) {
            me.renderDetailForm(response);
        }
        ,error: function(jqXHR) {
            PEAKWORK.ICM.GENERIC.Notification.notify(
                'undefined' !== typeof jqXHR.responseJSON && 'undefined' !== typeof jqXHR.responseJSON.message ?
                    jqXHR.responseJSON.message : Translator.trans('detail.notloaded', {}, me.config.translationDomain)
                ,'error'
            );
        }
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.renderDetailForm = function(response) {
    var me = this
        ,$detailForm = $(response)
        ,idFields = $detailForm.find('[id]')
        ,idFieldsLength = idFields.length
        ,forFields = $detailForm.find('[for]')
        ,forFieldsLength = forFields.length
        ,i
        ,$tmpElement
        ,random = 1 + Math.floor(Math.random() * 6);

    for (i = 0; i < forFieldsLength; i++) {
        $tmpElement = $(forFields[i]);
        $tmpElement.attr('for', $tmpElement.attr('for') + '_' + random);
    }

    for (i = 0; i < idFieldsLength; i++) {
        $tmpElement = $(idFields[i]);
        $tmpElement.attr('id', $tmpElement.attr('id') + '_' + random);
    }

    me.$contentContainer.html($detailForm);
    me.$contentContainer.removeClass('hidden');
    me.initDetailForm($detailForm);
    me.enableAttention();
};


contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.closeDetailForm = function() {
    var me = this;

    me.$contentContainer.html('');
    me.$contentContainer.addClass('hidden');
    me.disableAttention();
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.abortDetailForm = function() {
    var me = this;

    me.closeDetailForm();
    me.$element.select2('val', '');
    me.formValidator = null;
};


contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initDetailForm = function($detailFormContainer) {
    var me = this
        ,$saveBtn = $detailFormContainer.find('button.js-detailform-save')
        ,$abortBtn = $detailFormContainer.find('button.js-detailform-abort')
        ,$closeBtn = $detailFormContainer.find('button.js-detailform-close')
        ,$form = $detailFormContainer.find('form');

    $closeBtn.on('click', function(event) {
        event.preventDefault();
        me.closeDetailForm();
    });

    $abortBtn.on('click', function(event) {
        event.preventDefault();
        me.abortDetailForm();
    });

    $saveBtn.on('click', function(event) {
        event.preventDefault();
        me.submitDetailForm($detailFormContainer, $form);
    });

    PEAKWORK.ICM.GENERIC.FIELDDEFINITION.init($form.find('.js-peakworkIcmGenericTypeFieldDefinition'));
    me.initSimpleAutocompleteFields($form);
    contextIcmBaseAutocomplete.initFields($form.find('.js-form-autocomplete-generic'));
    PEAKWORK.ICM.RESTCLIENTBUNDLE.REFERENCE.initFields($form);

    $form.find('.js-collection-panel, .js-collection-base, .js-collection-table').each(function(index, item){
        var $element = $(item);
        /* enable plugin only for collections which are not part of a hidden template */
        if (0 === $element.closest('.js-collection-template').length) {
            $element.pwCollection();
        }
    });

    me.formValidator = new PEAKWORK.ICM.BASE.FormValidator($form[0], {});
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.initSimpleAutocompleteFields = function($form) {
    var fields = $form.find('.js-form-autocomplete')
        ,length = fields.length
        ,i
        ,$field;

    for (i = 0; i < length; i++) {
        $field = $(fields[i]);
        if (false === $field.closest('.js-collection-template').length > 0) {
            PEAKWORK.FORM.AUTOCOMPLETE.init($field);
        }
    }
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.submitDetailForm = function($detailFormContainer, $form) {
    var me = this;

    if (
        null === me.formValidator ||
        false === me.formValidator.validator.form()
    ) {
        return false;
    }

    $.ajax({
        url: $form.attr('action')
        ,method: $form.attr('method')
        ,data: $form.serialize()
        ,beforeSend: function() {
            me.$contentContainer.find('.panel-footer').remove();
            me.$contentContainer.find('.panel-body').html(PeakworkBackendHelper.getInstance().createLoader());
        }
        ,success: function(response) {
            try {
                var responseJson = $.parseJSON(response);
                if ('object' === typeof responseJson) {
                    return me.submitDetailFormSuccess(responseJson);
                }
            } catch (e) {}

            me.renderDetailForm(response);
        }
        ,error: function(jqXHR) {
            PEAKWORK.ICM.GENERIC.Notification.notify(
                'undefined' !== typeof jqXHR.responseJSON && 'undefined' !== typeof jqXHR.responseJSON.message ?
                    jqXHR.responseJSON.message : Translator.trans('detail.save.error', {}, me.config.translationDomain)
                ,'error'
            );

            me.abortDetailForm();
        }
    });
};

contextIcmBaseAutocomplete.AutoCompleteCreate.prototype.submitDetailFormSuccess = function(responseJson) {
    var me = this;
    me.$element.select2('val', responseJson.id, true);
    me.closeDetailForm();

    PEAKWORK.ICM.GENERIC.Notification.notify(
        Translator.trans('detail.save.success', {}, me.config.translationDomain)
        ,'success'
    );

    return true;
};

contextIcmBaseAutocomplete.initFields = function(autoCompleteFields) {
    var length = autoCompleteFields.length,
        i = 0
        ,$autoCompleteField;

    for (i = 0; i < length; i++) {
        $autoCompleteField = $(autoCompleteFields[i]);
        if (false === $autoCompleteField.closest('.js-collection-template').length > 0) {
            new contextIcmBaseAutocomplete.AutoCompleteCreate($autoCompleteField);
        }
    }
};

$(function() {
    /**
     * Initialize autocomplete for all fields which are not part of a collection template.
     */
    contextIcmBaseAutocomplete.initFields($('.js-form-autocomplete-generic'));

});
