$(function() {
    new PEAKWORK.ICM.BASE.List({
        tableDomId: '#peakworkIcmAccessPolicyListTable'
        ,tableId: 'peakworkIcmAccessPolicyListTable'
        ,dataTableIdentifier: 'peakworkIcmAccessPolicyListTable'
        ,filterSubmittedEvent: 'peakworkIcmAccessPolicyListFilterSubmitted'
        ,filterDomId: '#peakworkIcmAccessPolicySearchFilter'
        ,addBtn: 'div.addBtn'
        ,addDetailRoute: 'peakwork_icm_accesspolicy_add'
        ,activeBtn: '.js-peakworkIcmAccessPolicyActive'
        ,deactiveBtn: '.js-peakworkIcmAccessPolicyDeactive'
        ,historyBtn: '.js-peakworkIcmGenericHistory'
        ,translationDomain: 'peakworkicmaccesspolicy'
        ,hasActivate: true
        ,hasDeactivate: true
        ,hasDelete: false
        ,hasHistory: true
        ,isLegacy: true
    });
});