var context = pwNamespace('PEAKWORK.FORM.TABLECHOICE');

context.buildTableChoice = function(el, defaultOptions) {
    var activeHtml = '<div class="checkbox checkbox-toggle no-margins"><input type="checkbox" id="%ID%" class="js-form-tablechoice-statebtn" checked="checked"><label for="%ID%"></label></div>'
        ,deactiveHtml = '<div class="checkbox checkbox-toggle no-margins"><input type="checkbox" id="%ID%" class="js-form-tablechoice-statebtn"><label for="%ID%"></label></div>'
        ,$table = el.find('table.js-form-tablechoice-table')
        ,$select = el.find('select.js-form-tablechoice-select')
        ,isDisabled = $select.prop('disabled')
        ,isReadonly = typeof $select.attr('readonly') !== 'undefined'
        ,$tableHeader = $table.find('thead')
        ,columnNames = []
        ,identifierName = $table.data('identifier-name')
        ,stateColPosition = $table.data('state-col-position')
        ,identifierColIndex = 0
        ,hiddenColIndices = []
        ,tableId = 'tablechoice_' + (new Number(new Date()))
        ,$countsContainer = $table.closest('.js-form-tablechoice').find('.js-form-tablechoice-count')
        ,hasCountsContainer = 1 === $countsContainer.length
        ,tempSearchVal = '';

    $tableHeader.find('th').each(function(index, item) {
        item = $(item);
        columnNames.push({
            'data': item.data('field'),
            'orderable': item.data('sortable')
        });

        if (identifierName === item.data('field')) {
            identifierColIndex = index;
        }

        if (item.hasClass('hidden')) {
            hiddenColIndices.push(index);
        }
    });

    var selectedList = function() {
        return $select.find('option').map(function() {
            return this.value;
        }).get();
    };

    var setStateFilterSearch = function(checked) {
        if (checked) {
          /**
           * Set 0, so the backend controller can handle a "nothing selected" search. An empty array won't be sent.
           */
          var value = $select.val();
            return null === value ? 0 : value;
        }

        return false;
    };

    var generatrId = function() {
        var text = ''
            ,possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            ,i = 0;

        for (i; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    };

    $table.attr('id', tableId);
    PEAKWORK.BACKEND.Datatables.initDatatable(tableId, {
        ajax: {
            url: $table.data('url')
            ,type: 'POST'
            ,data: function(data) {
                data.search = {};
                el.find('input.js-form-tablechoice-search-input').each(function(index, item) {
                    var $item = $(this);
                    data.search[$item.data('field')] = $item.val();
                });

                el.find('input.js-form-tablechoice-search-state').each(function(index, item) {
                    var $item = $(this)
                        ,stateValue = setStateFilterSearch($item.prop('checked'));

                    if (false !== stateValue) {
                        data.search[$item.data('field')] = stateValue;
                    }
                });

                return data;
            }
        }
        ,rowCallback: function(row, data, index) {
            $(hiddenColIndices).each(function(index, item) {
                $($('td', row)[item]).addClass('hidden');
            });
            if (stateColPosition === 'first') {
                $stateTd = $('td', row).first();
            } else {
                $stateTd = $('td', row).last();
            }

            if (selectedList().indexOf(data[identifierName]) !== -1) {
                $stateTd.html(activeHtml.replace(/%ID%/g, generatrId())).addClass('text-center');
            } else {
                $stateTd.html(deactiveHtml.replace(/%ID%/g, generatrId())).addClass('text-center');
            }
        }
        ,columns: columnNames
        ,searching: false
        ,lengthChange: false
        ,processing: true
        ,serverSide: true
        ,autoWidth: false
        ,pageLength: 10
        ,saveSettings: false
        ,saveFlashSettings: false
        ,convertSortInformation: true
        ,removeColumnsInRequest: true
        ,scrollToTop: false
        ,order: [[$table.data('sorting-column'), $table.data('sorting-direction')]]
    });


    // a bit hacky: removes unwanted style from datatables bootstrap extension.
    // BootStrap => BS => BullShit
    el.find('.dataTables_wrapper').removeClass('form-inline');

    // search
    var searchReloadTabledebounced = $.debounce(250, function () {
            $table.DataTable().ajax.reload();
        }
    );

    el.find('input.js-form-tablechoice-search-input').on('focus', function () {
        tempSearchVal = $(this).val();
    }).on('keyup change', function () {
        if (tempSearchVal !== $(this).val()) {
            searchReloadTabledebounced();
            tempSearchVal = $(this).val();
        }
    });

    el.find('input.js-form-tablechoice-search-state').on('change', searchReloadTabledebounced);

    if (false === isDisabled && false === isReadonly) {
        $table.on('click', 'tbody tr .js-form-tablechoice-statebtn', function() {
            var $row = $(this).closest('tr')
                ,$search = $row.find('td').eq(identifierColIndex).text();

            if (selectedList().indexOf($search) !== -1) {
                // remove item
                $select.find('option').filter('[value="' + $search + '"]').remove();

                if (true === hasCountsContainer) {
                    $countsContainer.html(parseInt($countsContainer.html(), 10) - 1);
                }
            } else {
                // add item
                var $option = $('<option>').val($search).text($search).attr('selected', true);
                $select.append($option);

                if (true === hasCountsContainer) {
                    $countsContainer.html(parseInt($countsContainer.html(), 10) + 1);
                }
            }
        });
    }
};

$(function() {
    $.each($('.js-form-tablechoice'), function (idx, collection) {
        PEAKWORK.FORM.TABLECHOICE.buildTableChoice($(this), {});
    });
});