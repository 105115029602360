var contextIcmCrsRouting = pwNamespace('PEAKWORK.ICM.RESTCLIENT.CRSROUTING');

/**
 * When changing the pwcustomer we need to clear the pwoffice, make the input writable and
 * set the pwcustomer value to the pwoffice autoselect request.
 * @param Object $element
 */
contextIcmCrsRouting.pwcustomer = function($element) {
    var $pwCustomer = $element.find('input.js-peakworkIcmCrsRoutingPwCustomerNumber')
        ,$pwOffice = $element.find('input.js-peakworkIcmCrsRoutingPwOfficeNumber');

    $pwCustomer.on('select2-selecting', function(e) {
        $pwOffice.select2('val', '');
        $pwOffice.select2('readonly', false);
        $pwOffice.data('query-params-autocomplete', {
            'pwcustomer': e.val
        });
    });
};

/**
 * When changing the touroperator we need to clear the crs instance, make the input writable and
 * set the touroperator value to the crs instance autoselect request.
 * @param Object $element
 */
contextIcmCrsRouting.touroperator = function($element) {
    var $touroperator = $element.find('input.js-peakworkIcmCrsRoutingTouroperator')
        ,$crsInstance = $element.find('input.js-peakworkIcmCrsRoutingCrsInstanceId');

    $touroperator.on('select2-selecting', function(e) {
        $crsInstance.select2('val', '');
        $crsInstance.select2('readonly', false);
        $crsInstance.data('query-params-autocomplete', {
            'touroperator': e.val
        });
    });
};

/**
 * When changing the crs instance we need to clear the crs authentication, make the input writable and
 * set the crs instance value to the crs authentication autoselect request. We also need to set the crs template
 * id to the hidden field and adjust the generic request parameters.
 *
 * When clearing the crs instance we need to clear the crs authentication, make the input readonly and
 * remove the crs instance value from the crs authentication autoselect request. We also need to remove the crs
 * template id to the hidden field and clear the generic request parameters.
 *
 * @param Object $element
 */
contextIcmCrsRouting.crsinstance = function($element) {
    var $crsInstance = $element.find('input.js-peakworkIcmCrsRoutingCrsInstanceId')
        ,$crsTemplateId = $element.find('input.js-peakworkIcmCrsRoutingCrsTemplateId')
        ,$crsAuthentication = $element.find('input.js-peakworkIcmCrsRoutingCrsAuthenticationId')
        ,$crsAuthenticationName = $element.find('input.js-peakworkIcmCrsRoutingCrsAuthenticationName')
        ,$genericOutputContainer = $crsInstance.closest('form').find('.js-form-generic-output-container');

    $crsInstance.on('select2-selecting', function(e) {
        $crsInstance.data('query-params-generic', {
            'id': e.object.data.crstemplateid
        });

        $crsAuthentication.select2('val', '');
        $crsAuthenticationName.val('');

        $crsAuthentication.data('query-params-autocomplete', {
            'crstemplateid': e.object.data.crstemplateid
        });

        $crsTemplateId.val(e.object.data.crstemplateid);
        $crsAuthentication.select2('readonly', false);

        contextIcmCrsRouting.initializeCrsAuthenticationFields($crsInstance);

    });

    $crsInstance.on('select2-clearing', function(e) {
        $crsTemplateId.val('');
        $crsInstance.data('query-params-generic', {});
        $crsAuthentication.select2('val', '');
        $crsAuthenticationName.val('');
        $crsAuthentication.data('query-params-autocomplete', {});
        $crsAuthentication.select2('readonly', true);
        $genericOutputContainer.html('');
    });
};

/**
 * Enable select2 for the crs authentication field. We cannot use the common autoselect javascript because
 * the field has an execption: it must provide a functionality to create a new authentication when the input
 * does not exist.
 *
 * When changing the crs autehntication we need to set the name to the hidden input field as well as
 * requesting the server to get the generic fields (with values).
 *
 * @param Object $element
 * @param function callbackGenericLoaded
 */
contextIcmCrsRouting.crsauthentication = function($element, callbackGenericLoaded) {
    var $crsInstance = $element.find('input.js-peakworkIcmCrsRoutingCrsInstanceId')
        ,$crsAuthentication = $element.find('input.js-peakworkIcmCrsRoutingCrsAuthenticationId')
        ,$crsAuthenticationName = $element.find('input.js-peakworkIcmCrsRoutingCrsAuthenticationName')
        ,$genericOutputContainer = $crsInstance.closest('form').find('.js-form-generic-output-container');

    $crsAuthentication.select2({
        minimumInputLength: parseInt($crsAuthentication.data('minimuminputlength'), 10)
        ,allowClear: $crsAuthentication.data('allowclear')
        ,formatNoMatches: $crsAuthentication.data('formatnomatches')
        ,formatSearching: $crsAuthentication.data('formatsearching')
        ,formatInputTooShort: $crsAuthentication.data('formatinputtooshort')
        ,ajax: {
            url: $crsAuthentication.data('searchroute')
            ,dataType: 'json'
            ,quietMillis: 250
            ,data: function (term, page) {
                var params = $crsAuthentication.data('query-params-autocomplete') ? $crsAuthentication.data('query-params-autocomplete') : {};
                params.q = term;
                return params;
            }
            ,results: function (data, page) {
                return {
                    results: data
                };
            }
            ,cache: true
        }
        ,initSelection: function(element, callback) {
            var $element = $(element)
                ,id = $element.val()
                ,params;

            if (id !== '') {
                params = $element.data('query-params-autocomplete') ? $element.data('query-params-autocomplete') : {};
                params.id = id;
                params.name = $crsAuthenticationName.val();
                $.ajax({
                    url: $element.data('getroute')
                    ,dataType: 'json'
                    ,data: params
                }).done(function(data) {
                    callback(data);
                    if ('function' === typeof callbackGenericLoaded) {
                        callbackGenericLoaded.call(this, params);
                    }
                });
            }
        }
        ,createSearchChoice: function(term, data) {
            if ($(data).filter(function() { return this.text.localeCompare(term) === 0; }).length === 0) {
                return {
                    id: 'registernew'
                    ,text: term + ' (' + Translator.trans('form.crsauthentication.createnew', {}, 'peakworkicmcrsrouting') + ')'
                    ,data: {
                        name: term
                    }
                };
            }
        }
        ,dropdownCssClass: 'bigdrop'
        ,escapeMarkup: function (m) {
            return m;
        }
    });

    $crsAuthentication.on('select2-selecting', function(e) {
        /* set new name */
        $crsAuthenticationName.val(
            'undefined' !== typeof e.object.data && 'undefined' !== typeof e.object.data.name ?
                e.object.data.name : e.object.text
        );

        /* reload fields */
        var params = $crsInstance.data('query-params-generic') ? $crsInstance.data('query-params-generic') : {}
            ,ajaxRoute = $crsInstance.data('formAjaxRoute');

        params.crsauthenticationid = e.object.id;

        $.ajax({
            url: ajaxRoute
            ,data: params
            ,success: function(data) {
                $genericOutputContainer.html(data);
                if ('function' === typeof callbackGenericLoaded) {
                    callbackGenericLoaded.call(this, params);
                }
            }
            ,error: function() {
                $genericOutputContainer.html('');
                if ('function' === typeof callbackGenericLoaded) {
                    callbackGenericLoaded.call(this, params);
                }
            }
        });
    });
};

/**
 * supplier function to provide crs auth fields right after instance selection
 *
 */
contextIcmCrsRouting.initializeCrsAuthenticationFields = function($crsInstance) {
    var $genericOutputContainer = $crsInstance.closest('form').find('.js-form-generic-output-container')
        ,params = $crsInstance.data('query-params-generic') ? $crsInstance.data('query-params-generic') : {}
        ,ajaxRoute = $crsInstance.data('formAjaxRoute');
    $.ajax({
        url: ajaxRoute
        ,data: params
        ,success: function(data) {
            $genericOutputContainer.html(data);
        }
        ,error: function() {
            $genericOutputContainer.html('');
        }
    });
};


$(function() {
    var $crsRoutingForm = $('#peakworkIcmCrsRoutingForm');
    if ($crsRoutingForm.length > 0) {
        contextIcmCrsRouting.pwcustomer($crsRoutingForm);
        contextIcmCrsRouting.touroperator($crsRoutingForm);
        contextIcmCrsRouting.crsinstance($crsRoutingForm);
        contextIcmCrsRouting.crsauthentication($crsRoutingForm);
    }
});