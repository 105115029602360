var contextIcmGenericNavigation = pwNamespace('PEAKWORK.ICM.GENERIC');

contextIcmGenericNavigation.Navigation = function (config, $container) {
    config = config || {};

    this.$container = $container || $(document);
    this.config = $.extend(true, {
        viewBtn: '.js-generic-navigation-view'
        ,listClass: 'js-navigation-list'
        ,templateClass: 'js-navigation-template'
        ,handleClass: 'js-navigation-listitem-drag'
        ,getRouteId: 'peakwork_icm_generic_list_navigation_index'
        ,editRouteId: 'peakwork_icm_generic_list_navigation_edit'
        ,translationDomain: 'peakworkicmgeneric'
        ,instanceId: null
        ,folderClass: 'js-navigation-folder'
        ,folderNameClass: 'js-navigation-folder-name'
        ,folderRemoveClass: 'js-navigation-folder-remove'
        ,folderAddClass: 'js-navigation-addfolder'
    }, config);

    this.template = '';
    this.nestableObject;

    this.init();
};

contextIcmGenericNavigation.Navigation.prototype.init = function() {
    var me = this;

    me.$container.on('click', me.config.viewBtn, function(event) {
        me.displayNavigationView($(this), event);
    });
};

contextIcmGenericNavigation.Navigation.prototype.getInstanceId = function(element) {
    var me = this;
    return me.config.instanceId;
};

contextIcmGenericNavigation.Navigation.prototype.displayNavigationView = function(element, event) {
    var me = this;

    me.dialog = BootstrapDialog.show({
        title: '<i class="fa fa-list"></i> ' + Translator.trans('navigation.title', {}, me.config.translationDomain),
        message: PeakworkBackendHelper.getInstance().createLoader(),
        nl2br: false,
        headerCloseBtn: true,
        size: BootstrapDialog.SIZE_WIDE,
        onshown: function(dialog) {
            $.ajax({
                url: Routing.generate(me.config.getRouteId, {
                    resource: me.config.resource
                }),
                data: {
                    id: me.getInstanceId(element)
                },
                method: 'GET',
                dataType: 'html',
                success: function(response) {
                    dialog.setMessage(response);
                    me.initData();
                    me.initNestable();
                    me.initAddFolderButton();
                    me.initAllFolders();
                }
                ,error: function(jqXHR) {
                    PEAKWORK.ICM.GENERIC.Notification.notify(jqXHR.responseText, 'error');
                    me.dialog.close();

                }
            });
        },
        buttons: [{
            label: '<span class="fa fa-times"></span> ' + Translator.trans('navigation.close', {}, me.config.translationDomain),
            cssClass: 'btn-default',
            action: function(dialog) {
                dialog.close();
            }
        },{
            label: '<span class="fa fa-check"></span> ' + Translator.trans('navigation.save', {}, me.config.translationDomain),
            cssClass: 'btn-primary',
            action: function(dialog) {
                me.save(dialog);
            }
        }]
    });
};

contextIcmGenericNavigation.Navigation.prototype.initData = function() {
    var me = this;

    me.template = me.dialog.$modalBody.find('.' + me.config.templateClass).html();
    me.dialog.$modalBody.find('.' + me.config.templateClass).remove();
};

contextIcmGenericNavigation.Navigation.prototype.initNestable = function() {
    var me = this;

    me.nestableObject = me.dialog.$modalBody.find('.' + me.config.listClass);
    me.nestableObject.nestable({
        maxDepth: 3
        ,handleClass: me.config.handleClass
        ,childAllowedClass: me.config.folderClass
    });
};

contextIcmGenericNavigation.Navigation.prototype.initAddFolderButton = function() {
    var me = this;

    me.dialog.$modalBody.find('.' + me.config.folderAddClass).on('click', function(event) {
        event.preventDefault();
        var $button = $(this)
            ,$newElement = $(me.template);

        $($button.closest('li')).before($newElement);
        me.initFolder($($newElement), true);
        me.initNestable();
    });
};

contextIcmGenericNavigation.Navigation.prototype.initAllFolders = function() {
    var me = this
        ,folders = me.dialog.$modalBody.find('.' + me.config.folderClass)
        ,foldersLength = folders.length
        ,i;

    for (i = 0; i < foldersLength; i++) {
        me.initFolder($(folders[i]));
    }
};

contextIcmGenericNavigation.Navigation.prototype.initFolder = function($folderElement, isNew) {
    var me = this
        ,editableFields = $folderElement.find('.' + me.config.folderNameClass);
    isNew = isNew ? isNew : false;

    if (editableFields.length > 0) {
        // init the editable feature only for the first found element
        // since this is the one for the folder. All other found elements
        // belong to a sub folder and will be handled by themselves
        me.initFolderEditable($(editableFields[0]), isNew);
    }

    me.initFolderDelete($folderElement);
};

contextIcmGenericNavigation.Navigation.prototype.initFolderEditable = function($editableElement, isNew) {
    var me = this;

    if (true === isNew) {
        $editableElement.on('init', function(e, editable) {
            editable.show();
        });
    }

    $editableElement.editable({
        mode: 'inline'
        ,showbuttons: false
        ,onblur: 'submit'
        ,saveonchange: false
        ,send: 'never'
        ,validate: function(value) {
            if ('' === $.trim(value)) {
                return Translator.trans('navigation.foldernamerequired', {}, me.config.translationDomain);
            }

            return '';
        }
    });

    $editableElement.on('save', function(event, params) {
        event.preventDefault();
        me.saveFolderName($editableElement, params.newValue);
    });
};

contextIcmGenericNavigation.Navigation.prototype.saveFolderName = function($editableElement, newValue) {
    var me = this
        ,$listItem = $editableElement.closest('.' + me.config.folderClass)
        ,data = $.parseJSON($listItem.attr('data-data'));
    data.name = newValue;
    $listItem.attr('data-data', JSON.stringify(data));
};

contextIcmGenericNavigation.Navigation.prototype.initFolderDelete = function($folderElement) {
    var me = this;

    $folderElement.find('.' + me.config.folderRemoveClass).on('click', function (event) {
        event.preventDefault();
        swal({
            title: Translator.trans('navigation.delete.confirm.title', {}, me.config.translationDomain)
            ,text: Translator.trans('navigation.delete.confirm.text', {}, me.config.translationDomain)
            ,html: true
            ,type: 'warning'
            ,showCancelButton: true
            ,showConfirmButton: true
            ,closeOnCancel: true
            ,confirmButtonText: Translator.trans('navigation.delete.confirm.confirmbtn', {}, me.config.translationDomain)
            ,cancelButtonText: Translator.trans('navigation.delete.confirm.cancelbtn', {}, me.config.translationDomain)
        },function(isConfirm) {
            if (isConfirm) {
                me.deleteFolder($folderElement);
            }
        });
    });
};

contextIcmGenericNavigation.Navigation.prototype.deleteFolder = function($folderElement) {
    var me = this
        ,folderChildren = $folderElement.find('.dd-item')
        ,folderChildrenLength = folderChildren.length
        ,i = 0
        ,$buttonListElement = me.dialog.$modalBody.find('.' + me.config.folderAddClass).closest('li')
        ,$parentFolder = $folderElement.parent().closest('.' + me.config.folderClass).find('.dd-list')
        ,hasParentFolder = 1 < $parentFolder.length
        ,$child;

    if (folderChildrenLength > 0) {
        for (i; i < folderChildrenLength; i++) {
            $child = $(folderChildren[i]);
            if (true === hasParentFolder) {
                $child.appendTo($($parentFolder[0]));
                $parentFolder.append($child);
            } else {
                $child.insertBefore($buttonListElement);
            }
        }
    }

    $folderElement.remove();
};

contextIcmGenericNavigation.Navigation.prototype.save = function(dialog) {
    var me = this;

    $.ajax({
        url: Routing.generate(me.config.editRouteId, {
            resource: me.config.resource
        })
        ,data: {
            'data': JSON.stringify(me.serialize())
        }
        ,method: 'POST'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('navigation.stored.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function (response) {
            swal({
                title: Translator.trans('navigation.stored.success.title', {}, me.config.translationDomain)
                ,text: Translator.trans('navigation.stored.success.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'success'
                ,confirmButtonText: Translator.trans('navigation.stored.success.confirmbtn', {}, me.config.translationDomain)
                ,closeOnConfirm: true
            });
        }
        ,error: function (jqXHR) {
            if (403 === jqXHR.status) {
                PEAKWORK.ICM.GENERIC.Notification.notify(jqXHR.responseText, 'error');
                swal.close();
                me.dialog.close();
                return true;
            }

            swal({
                title: Translator.trans('navigation.stored.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('navigation.stored.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
                ,confirmButtonText: Translator.trans('navigation.stored.error.confirmbtn', {}, me.config.translationDomain)
                ,closeOnConfirm: true
            });
        }
    });
};

contextIcmGenericNavigation.Navigation.prototype.serialize = function() {
    var me = this
        ,data,
        depth = 0;

    step = function(level, depth) {
        var array = [ ]
            ,items = level.children('li.dd-item')
            ,itemsLength = items.length
            ,i
            ,$li
            ,$item
            ,$sub;

        for (i = 0; i < itemsLength; i++) {
            $li = $(items[i]);
            $item = $.extend({}, $.parseJSON($li.attr('data-data')));
            $sub = $li.children('ol');

            if ($sub.length) {
                $item.children = step($sub, depth + 1);
            }

            array.push($item);
        }

        return array;
    };

    data = step(me.nestableObject.find('ol').first(), depth);

    return data;
};