var contextToolkit = pwNamespace('PEAKWORK.BACKEND');
contextToolkit.Datatables = (function() {
    var me = {
        dataTableType : 'normal'
        ,config : {
            setOrderingRouteId : 'peakwork_common_datatables_setordering'
            ,setPageLengthRouteId : 'peakwork_common_datatables_setpagelength'
            ,setColimnsVisibilityRouteId : 'peakwork_common_datatables_setcolumnsvisibility'
            ,setPageRouteId : 'peakwork_common_datatables_setpage'
            ,getSavedSettingsRouteId: 'peakwork_common_datatables_getsettings'
            ,saveSettingsRouteId: 'peakwork_common_datatables_savesettings'
            ,translationDomain: 'peakworkcommondatatables'
            ,paginateButton: '.dataTables_paginate  .paginate_button'
            ,document: $(document)
        }
        //namespae used for the common functions
        ,common: {
            urlParam : 'showPreviousState=true'

            ,scrollToTopOnPageChange: function() {
                me.config.document.on('click', me.config.paginateButton, function() {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 250);
                });
            }

            /**
             * Helper function for getting the base URL (No query string params)
             * @returns {String}
             */
            ,getBaseUrl: function() {
                return window.location.href.split('?')[0];
            }

            /**
             * Adds the parameter to the url
             */
            ,addParamToUrl: function() {
                $(document).on('click', '.js-details-view', function() {
                    var baseUrl = me.common.getBaseUrl(),
                        queryString = me.common.sanitizeQueryString(me.common.urlParam),
                        delimiter = '?',
                        finalUrl;

                    if ( queryString !== '') {
                        delimiter = '&';
                    }

                    finalUrl = baseUrl + queryString + delimiter + me.common.urlParam;
                    history.pushState(null, '', finalUrl);
                });
            }

            /**
             * Sanitizes the query string and returns it as a string
             * @param {String} param
             * @returns {String}
             */
            ,sanitizeQueryString: function(param) {
                var str = window.location.search.replace('&' + param, '');
                str = str.replace('?' + param, '');
                if (str.charAt(0) === '&') {
                    str = str.replace('&', '?');
                }
                return str;
            }

            /**
             * Removes the param from the URL
             * @param {String} param
             */
            ,removeParamFromUrl: function(param) {
                var queryString = me.common.sanitizeQueryString(param),
                    finalUrl;

                finalUrl = me.common.getBaseUrl() + queryString;
                history.pushState(null, '', finalUrl);
            }

            /**
             * Check if the param is present in the URL
             * @param {String} param
             * @returns {boolean}
             */
            ,hasParam: function(param) {
                return window.location.search.indexOf(param) > -1;
            }

            /**
             * Merges the saved settings form the db
             * with the ones from init call (The place where you call PeakworkBackendDatatable.initDatatable)
             * @param {Object} savedSettings
             * @param {Object} options
             * @returns {Object}
             */
            ,mergeSavedSettings: function(options, savedSettings) {
                if (!$.isEmptyObject(savedSettings.settings)) {
                    $.extend(true, options, savedSettings.settings);
                }
                return options;
            }

            /**
             * Adds a sorting element to the request with the column name.
             * @param data
             * @returns {*}
             */
            ,modifySortInformation: function(data) {
                var sorting = []
                    ,orderLength = data['order'].length
                    ,i = 0
                    ,columns = data['columns'];

                for (i; i < orderLength; i++) {
                    sorting.push({
                        'column': columns[data['order'][i]['column']]['data']
                        ,'dir': data['order'][i]['dir']
                    });
                }

                data['sorting'] = sorting;

                return data;
            }

            /**
             * Removes the columns object fromt the data object.
             * @param data
             * @returns {*}
             */
            ,removeColumnsFromData: function(data) {
                if ('undefined' !== typeof data['columns']) {
                    delete data['columns'];
                }

                return data;
            }

            /**
             * Merges the saved settings form the db with the default ones
             * @param {Object} options
             * @returns {Object}
             */
            ,mergeWithDefaultSettings: function(options) {
                //merge the saved setting with the default ones
                options = $.extend(true, {
                    dom: 'l<"clear">rtip',
                    processing: true,
                    searching: false,
                    pageLength: 25,
                    convertSortInformation: false,
                    removeColumnsInRequest: false,
                    scrollToTop: true,
                    filterForm: null,
                    columnDefs: [{
                        targets: 'js-datatable-truefalseasicons'
                        ,render: function ( data, type, row ) {
                            if ('true' === data || true === data || 1 === data || '1' === data) {
                                return '<span class="text-success fa fa-check"></span>';
                            } else {
                                return '<span class="text-danger fa fa-times"></span>';
                            }
                        }
                    }],
                    language: {
                        emptyTable: Translator.trans('common.table.empty', {}, me.config.translationDomain),
                        info: Translator.trans('common.table.page_PAGE_of_PAGES_', {}, me.config.translationDomain),
                        infoEmpty: Translator.trans('common.table.empty', {}, me.config.translationDomain),
                        infoFiltered: Translator.trans('common.table.filterdfrom_MAX_', {}, me.config.translationDomain),
                        infoPostFix: '',
                        thousands: '',
                        lengthMenu: Translator.trans('common.table.recordsperpage_MENU_', {}, me.config.translationDomain),
                        loadingRecords: Translator.trans('common.table.loading', {}, me.config.translationDomain),
                        processing: '<img src="' + PeakworkBackend.bundlesDir +
                        'peakworkbackendbasis/images/loading-bars.svg" width="40" height="40" ' +
                        '/>&nbsp;&nbsp;' +
                        Translator.trans('common.table.processing', {}, me.config.translationDomain),
                        search: Translator.trans('common.table.search', {}, me.config.translationDomain) + ':',
                        zeroRecords: Translator.trans('common.table.empty', {}, me.config.translationDomain),
                        paginate: {
                            first: Translator.trans('common.table.paginate.first', {}, me.config.translationDomain),
                            last: Translator.trans('common.table.paginate.last', {}, me.config.translationDomain),
                            next: Translator.trans('common.table.paginate.next', {}, me.config.translationDomain),
                            previous: Translator.trans('common.table.paginate.previous', {}, me.config.translationDomain)
                        },
                        aria: {
                            sortAscending:
                            ':' + Translator.trans('common.table.sortAscending', {}, me.config.translationDomain),
                            sortDescending:
                            ':' + Translator.trans('common.table.sortDescending', {}, me.config.translationDomain)
                        }
                        ,select: {
                            cells: Translator.trans('common.table.select.cells', {}, me.config.translationDomain)
                            ,columns: Translator.trans('common.table.select.columns', {}, me.config.translationDomain)
                            ,rows: Translator.trans('common.table.select.rows', {}, me.config.translationDomain)
                        }
                    }
                }, options);

                return options;
            }
            /**
             * The main function for creating the dataTable
             * @param {String} tableId
             * @param {Object} options
             * @param {Object} savedSettings
             */
            ,createDataTable: function(tableId, options, savedSettings) {
                var table = $('#' + tableId), datatable;
                //merge the saved settings and the ones form the JS call
                options = me.common.mergeSavedSettings(options, savedSettings);
                //merge the saved settings with the default
                options = me.common.mergeWithDefaultSettings(options);
                //create the DataTable

                //modify the AJAX request and creates the datatable
                table.on('preXhr.dt', function (e, settings, data) {
                    if (true === options.convertSortInformation) {
                        data = me.common.modifySortInformation(data);
                    }

                    if (true === options.removeColumnsInRequest) {
                        data = me.common.removeColumnsFromData(data);
                    }
                });

                return table.DataTable(options);
            }
        }
        //namespace used for the Datatable that uses the DB storage
        ,dbStorage: {
            /**
             * Pops up a window with unxepected error message
             */
            showUnexpectedError: function() {
                swal({
                    type: 'error',
                    title: Translator.trans('common.unexpectederror.header', {}, me.config.translationDomain),
                    text: Translator.trans('common.unexpectederror.text', {}, me.config.translationDomain)
                });
            }

            /**
             * Sends a call for saving the settings in the DB
             * @param {String} routeId
             * @param {Object} data
             */
            ,saveSettings: function(routeId, data) {
                $.ajax({
                    url: Routing.generate(routeId),
                    data: data,
                    method: 'POST',
                    dataType: 'json',
                    success: function(response) {},
                    error: function() {
                        me.dbStorage.showUnexpectedError();
                    }
                });
            }

            /**
             * Creates object for saving in the db
             * @param {String} tableId
             * @param {Element} dataTable
             * @returns {{tableId: *, sorting: *, pageLength: *, columnsVisibility: (*|string|Chartist.Svg.Path)}}
             */
            ,parseSettingForSaving: function(tableId, dataTable) {
                var settings = {
                    tableId: tableId
                    ,sorting : dataTable.order()
                    ,pageLength: dataTable.page.len()
                    ,columnsVisibility : dataTable.columns().visible().join(',')
                };
                return settings;
            }

            /**
             * Initialize the events that can happen on the dataTable
             * @param {String} tableId
             * @param {Element} dataTable
             */
            ,initEvents: function(tableId, dataTable) {
                //Listen for the order event
                dataTable.on('order', function () {
                    var data;
                    data = {
                        tableId: tableId
                        ,sorting: dataTable.order()
                    };
                    me.dbStorage.saveSettings(me.config.setOrderingRouteId, data);
                } );
                //listen for the length event
                dataTable.on('length', function ( e, settings, len ) {
                    var data;
                    data = {
                        tableId: tableId
                        ,length : len
                    };
                    me.dbStorage.saveSettings(me.config.setPageLengthRouteId, data);
                } );
                //listen for the columns-visibility event
                dataTable.on('column-visibility', function () {
                    var data;
                    data = {
                        tableId: tableId
                        ,columnsVisibility: dataTable.columns().visible().join(',')
                    };
                    me.dbStorage.saveSettings(me.config.setColimnsVisibilityRouteId, data);
                } );

                //listen for the columns-visibility event
                dataTable.on('page', function () {
                    var data;
                    data = {
                        tableId: tableId
                        ,page: dataTable.page.info().page
                    };
                    me.dbStorage.saveSettings(me.config.setPageRouteId, data);
                } );
            }

            /**
             * Creates an array of hidden columns
             * @param {Object} savedSettings
             * @returns {Array}
             */
            ,getColumnsVisibility: function(savedSettings) {
                var hiddenColumns = [],
                    visibleColumns = [];

                if (!$.isEmptyObject(savedSettings.settings)) {
                    $.each( savedSettings.settings['visibleColumns'], function( column, visible ) {
                        if (visible === 'false') {
                            hiddenColumns.push(column);
                        } else {
                            visibleColumns.push(column);
                        }
                    });
                }
                return [visibleColumns, hiddenColumns];
            }

            /**
             * The main function for creating the dataTable
             * @param {String} tableId
             * @param {Object} options
             * @param {Object} savedSettings
             */
            ,createDataTable: function(tableId, options, savedSettings) {
                var dataTable
                    ,start = savedSettings.settings.pageNumber * savedSettings.settings.pageLength
                    ,hasParam = me.common.hasParam(me.common.urlParam)
                    ,columnsVisibility;

                //set the pagination
                if (hasParam) {
                    options.displayStart = start;
                    me.common.removeParamFromUrl(me.common.urlParam);
                }

                dataTable = me.common.createDataTable(tableId, options, savedSettings);

                //sets the visibility of the columns
                columnsVisibility = me.dbStorage.getColumnsVisibility(savedSettings);
                dataTable.columns(columnsVisibility[0]).visible(true, true);
                dataTable.columns(columnsVisibility[1]).visible(false, false);
                //save the settings in the DB
                me.dbStorage.saveSettings(
                    me.config.saveSettingsRouteId,
                    me.dbStorage.parseSettingForSaving(options.identifier, dataTable)
                );

                //init the change events
                me.dbStorage.initEvents(options.identifier, dataTable);
            }

            /**
             * Gets the saved settings and calls the createDaTaTable function
             * @param {String} tableId
             * @param {Object} options
             */
            ,initDataTable: function(tableId, options) {
                //add the ability to set hash on the URL
                me.common.addParamToUrl();

                //get the saved settings
                $.ajax({
                    url: Routing.generate(me.config.getSavedSettingsRouteId),
                    data: {
                        tableId: options.identifier
                    },
                    method: 'POST',
                    dataType: 'json',
                    success: function(response) {
                        //create the data table with the saving process
                        me.dbStorage.createDataTable(tableId, options, response);
                    },
                    error: function() {
                        me.dbStorage.showUnexpectedError();
                    }
                });
            }
        }
        //namespace used for the Datatable that uses the browser's browserStorage storage
        ,browserStorage : {
            /**
             * Setts the dataTable options based on the browserStorage settings
             * @param {Object} options
             * @param {String} lsTableIdSettings
             */
            mergeOptions: function(options, lsTableIdSettings) {
                var settings = JSON.parse(sessionStorage.getItem(lsTableIdSettings)),
                    tmpOrder = []
                    ,orderIndex;

                if (null !== settings) {
                    //set page length
                    options.pageLength = settings.length;
                    //set start
                    options.displayStart = settings.start;
                    //set order
                    for (orderIndex in settings.order) {
                        tmpOrder[orderIndex] = [
                            settings.order[orderIndex].column
                            ,settings.order[orderIndex].dir
                        ];
                    }
                    options.order = tmpOrder;
                }

                return options;
            }

            /**
             * Modifies the ajax request before sending
             * @param {Object} data
             * @param {String} lsTableIdSettings
             */
            ,modifyAjaxRequest: function(data, lsTableIdSettings) {
                var settings = JSON.parse(sessionStorage.getItem(lsTableIdSettings)),
                    settingItem;

                if (null !== settings) {
                    //delete settings that make problems. They are already set.
                    delete (settings.draw);
                    delete (settings.length);
                    delete (settings.order);
                    delete (settings.start);

                    //set the needed setting for the ajax request
                    for (settingItem in settings) {
                        data[settingItem] = settings[settingItem];
                    }
                }

                return data;
            }

            ,loadValuesIntoForm: function(options, lsTableIdSettings) {
                if (null === options.filterForm) {
                    return;
                }

                var settings = JSON.parse(sessionStorage.getItem(lsTableIdSettings))
                    ,settingItem;

                if (null !== settings) {
                    //delete settings that make problems. They are already set.
                    delete (settings.draw);
                    delete (settings.length);
                    delete (settings.order);
                    delete (settings.start);
                    delete (settings.sorting);
                    delete (settings.search);

                    for (settingItem in settings) {
                        options.filterForm.find('[name="' + settingItem + '"]').val(settings[settingItem]);
                    }
                }
            }

            /**
             * Init the events needed for updating the dataTable and saving the settings in the browserStorage
             * @param {String} tableId
             * @param {Object} options
             */
            ,initEvents: function(tableId, options) {
                var table = $('#' + tableId)
                    ,lsTableIdSettings = tableId + 'settings'
                    ,lsTableIdColumns = tableId + 'columns'
                    ,hasParam = me.common.hasParam(me.common.urlParam)
                    ,isFirst = true;


                if (hasParam) {
                    options = me.browserStorage.mergeOptions(options, lsTableIdSettings);
                    me.browserStorage.loadValuesIntoForm(options, lsTableIdSettings);
                }

                //modify the AJAX request and creates the datatable
                table.on('preXhr.dt', function (e, settings, data) {
                    if (true === options.convertSortInformation) {
                        data = me.common.modifySortInformation(data);
                    }

                    // modify the request only on page load and not every time
                    if (isFirst && hasParam) {
                        data = me.browserStorage.modifyAjaxRequest(data, lsTableIdSettings);
                        me.common.removeParamFromUrl(me.common.urlParam);
                        isFirst = false;
                    }

                    if (true === options.removeColumnsInRequest) {
                        data = me.common.removeColumnsFromData(data);
                    }

                    sessionStorage.setItem(lsTableIdSettings, JSON.stringify(data));
                })
                .dataTable(options);

                //listen for the columns visibility event
                table.on( 'column-visibility.dt', function (e, settings) {
                    var columns = settings.aoColumns
                        ,columnIndex
                        ,lsHiddenColumns = [];

                    for (columnIndex in columns) {
                        if (!columns[columnIndex].bVisible) {
                            lsHiddenColumns.push(columnIndex);
                        }
                    }
                    sessionStorage.setItem(lsTableIdColumns, JSON.stringify(lsHiddenColumns));
                });

                //listen for the init event and hide the columns
                table.on( 'init.dt', function () {
                    var hiddenColumns = JSON.parse(sessionStorage.getItem(lsTableIdColumns));
                    if (hasParam) {
                        table.fnSetColumnVis( hiddenColumns, false, false );
                        table.fnDraw(options);
                    }
                });
            }

            /**
             * Init the browserStorage data table
             * @param {String} tableId
             * @param {Object} options
             */
            ,initDataTable: function(tableId, options) {
                //add the ability to set hash on the URL
                me.common.addParamToUrl();

                //merge the default settings
                options = me.common.mergeWithDefaultSettings(options);

                //adds the events on the table
                me.browserStorage.initEvents(tableId, options);
            }
        }
    };

    return {
        /**
         * Initializes the dataTable
         * @param String tableId
         * @param Object options
         */
        initDatatable: function(tableId, options) {
            var hasIdentifier = options.identifier !== undefined && options.identifier !== '';

            //init the scroll even on page change
            if (true === options.scrollToTop) {
                me.common.scrollToTopOnPageChange();
            }

            //use the settings stored in the database
            if (options.saveSettings === true && hasIdentifier) {
                me.dataTableType = 'db';
                me.dbStorage.initDataTable(tableId, options);
                return;
            }

            //used the settings stored in the browserStorage
            if (options.saveFlashSettings === true && hasIdentifier) {
                me.dataTableType = 'normal';
                me.browserStorage.initDataTable(tableId, options);
                return;
            }

            //create the table without the saving process and without the browserStorage process
            me.common.createDataTable(tableId, options, {});
        }
        ,resetDataTable: function(tableId) {
            var response;

            if (me.dataTableType !== 'db') {
                return;
            }

            /* reset the pagination */
            response = me.dbStorage.saveSettings(
                me.config.setPageRouteId,
                {tableId: tableId, page: 0}
            );
            return response;
        }
    };
})();
