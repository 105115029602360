$(function() {
    var context = pwNamespace('PEAKWORK.FORM.DATE');

    context.buildPicker = function(el, defaultOptions) {
        var format = el.data('format') || defaultOptions.format
            ,minDate = 'undefined' !== typeof el.data('mindate') ? el.data('mindate') : defaultOptions.minDate
            ,maxDate = 'undefined' !== typeof el.data('maxdate') ? el.data('maxdate') : defaultOptions.minDate
            ,useCurrent = true === el.data('usecurrent') ? true : defaultOptions.useCurrent
            ,sideBySide = false === el.data('sidebyside') ? false : defaultOptions.sideBySide
            ,widgetPositioning = {
                horizontal: el.data('horizontal-position') || 'auto'
                ,vertical: el.data('vertical-position') || 'auto'
            };

        el.datetimepicker({
            format: format
            ,useCurrent: useCurrent
            ,sideBySide: sideBySide
            ,minDate: minDate
            ,maxDate: maxDate
            ,widgetPositioning: widgetPositioning
        });
    };

    /**
     * build a DatePicker with default settings
     * @param el the datefield
     */
    context.buildDatePicker = function (el) {
        context.buildPicker(el, {
            format: 'DD.MM.YYYY'
            ,useCurrent: false
            ,sideBySide: false
            ,minDate: false
            ,maxDate: false
        });
    };

    /**
     * build a DateTimePicker with default settings
     * @param el the datetimefield
     */
    context.buildDateTimePicker = function (el) {
        context.buildPicker(el, {
            format: 'DD.MM.YYYY HH:mm'
            ,useCurrent: false
            ,sideBySide: true
            ,minDate: false
            ,maxDate: false
        });
    };

    /**
     * build a TimePicker with default settings
     * @param el the timefield
     */
    context.buildTimePicker = function (el) {
        context.buildPicker(el, {
            format: 'HH:mm'
            ,useCurrent: false
            ,sideBySide: false
            ,minDate: false
            ,maxDate: false
        });
    };

    context.buildFromElement = function (el) {
        if (el.hasClass('js-form-dt-date')) {
            context.buildDatePicker(el);
            return;
        }
        if (el.hasClass('js-form-dt-datetime')) {
            context.buildDateTimePicker(el);
            return;
        }
        if (el.hasClass('js-form-dt-time')) {
            context.buildTimePicker(el);
        }
    };


    /**
     * Enable datetimepicker to date fields.
     */
    $.each(
        $('.js-form-dt-date, .js-form-dt-datetime, .js-form-dt-time')
        ,function (idx, el) {
            context.buildFromElement($(el));
        }
    );
});
