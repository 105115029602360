var contextIcmGenericNewCollectionItem = pwNamespace('PEAKWORK.ICM.RESTCLIENTBUNDLE.GENERIC');

contextIcmGenericNewCollectionItem.initReferenceField = function(plugin, element, selector) {
    var autoCompleteFields = element.find(selector)
        ,autoCompleteFieldsLength = autoCompleteFields.length
        ,i
        ,$autoCompleteField;

    for (i = 0; i < autoCompleteFieldsLength; i++) {
        $autoCompleteField = $(autoCompleteFields[i]);
        if (0 === $autoCompleteField.closest('.js-collection-template').length) {
            if (PEAKWORK.FORM && PEAKWORK.FORM.AUTOCOMPLETE) {
                PEAKWORK.FORM.AUTOCOMPLETE.init($autoCompleteField);
            }
        }
    }
};

contextIcmGenericNewCollectionItem.initMultiReferenceField = function(plugin, element, selector) {
    var fields = element.find(selector)
        ,fieldsLength = fields.length
        ,i
        ,$field;

    for (i = 0; i < fieldsLength; i++) {
        $field = $(fields[i]);
        if (0 === $field.closest('.js-collection-template').length) {
            PEAKWORK.FORM.TABLECHOICE.buildTableChoice($field, {});
        }
    }
};

/**
 * Init autocompletes in collections.
 * @param plugin
 * @param element
 */
contextIcmGenericNewCollectionItem.addedNewCollectionItem = function(plugin, element) {
    contextIcmGenericNewCollectionItem.initReferenceField(plugin, element, 'input.js-form-autocomplete');
    contextIcmGenericNewCollectionItem.initReferenceField(plugin, element, 'input.js-form-autocomplete-generic');
    contextIcmGenericNewCollectionItem.initMultiReferenceField(plugin, element, '.js-form-tablechoice');
};

contextIcmGenericNewCollectionItem.loadFileField = function($element) {
    var $form = $element.closest('form')
        ,$fieldContainer = $element.closest('.input-group')
        ,$input = $fieldContainer.find('.js-fileupload-input')
        ,$remove = $fieldContainer.find('.js-fileupload-remove')
        ,$link = $fieldContainer.parent().find('.js-fileupload-download')
        ,$fileName = $form.find(
            'input[name="' + ($element.attr('name').replace(
                $element.data('fileinputname'), $element.data('filenameinputname')
            )) + '"]'
        )
        ,fileNameValue = $fileName.val()
        ,$fileUrl = $form.find(
            'input[name="' + ($element.attr('name').replace(
                $element.data('fileinputname'), $element.data('fileurlinputname')
            )) + '"]'
        )
        ,fileUrlValue = $fileUrl.val();

    if ('' !== fileNameValue) {
        $input.val(fileNameValue);
        $remove.removeClass('disabled');
    }

    if ('' !== fileUrlValue) {
        $link.attr('href', fileUrlValue);
        $link.removeClass('hidden');
    }
};

contextIcmGenericNewCollectionItem.removeFile = function($element, $fileElement) {
    var $form = $element.closest('form')
        ,$fieldContainer = $element.closest('.input-group')
        ,fileElementName = $fileElement.attr('name')
        ,fileElementInputName = $fileElement.data('fileinputname')
        ,$link = $fieldContainer.parent().find('.js-fileupload-download')
        ,$fileId = $form.find(
            'input[name="' + (fileElementName.replace(
                fileElementInputName, $fileElement.data('fileidinputname')
            )) + '"]'
        )
        ,$fileSize = $form.find(
            'input[name="' + (fileElementName.replace(
                fileElementInputName, $fileElement.data('filesizeinputname')
            )) + '"]'
        )
        ,$mimeType = $form.find(
            'input[name="' + (fileElementName.replace(
                fileElementInputName, $fileElement.data('filemimetypeinputname')
            )) + '"]'
        )
        ,$fileUrl = $form.find(
            'input[name="' + ($fileElement.attr('name').replace(
                $fileElement.data('fileinputname'), $fileElement.data('fileurlinputname')
            )) + '"]'
        );

    $fileId.val('');
    $fileSize.val('');
    $mimeType.val('');
    $fileUrl.val('');
    $link.attr('href', '');
    $link.addClass('hidden');
};

$(function() {
    var fileFields = $('.js-fileupload-file')
        ,fileFieldsLength = fileFields.length
        ,i;

    for (i = 0; i < fileFieldsLength; i++) {
        contextIcmGenericNewCollectionItem.loadFileField($(fileFields[i]));
    }

    $(document).on('fileRemoved', '.js-fileupload-remove', function(event, $fileElement) {
        contextIcmGenericNewCollectionItem.removeFile($(this), $fileElement);
    });
});