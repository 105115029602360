var contextIcmCrsInstanceWorkflow = pwNamespace('PEAKWORK.FORM.CRSINSTANCE.WORKFLOW');

contextIcmCrsInstanceWorkflow.buildWorkflowForm = function($el, defaultOptions) {
    var $idInput = $el.find('input.js-form-workflow-id-input')
        ,$outputSelect = $el.find('.js-form-workflow-output-select')
        ,ajaxRoute = $idInput.data('workflowAjaxRoute');

    $idInput.change(function() {
        var params = $idInput.data('query-params') ? $idInput.data('query-params') : {};
        params.id = $idInput.val();

        $.ajax({
            url: ajaxRoute
            ,data: params
            ,success: function(data) {
                var i
                    ,dataLength = data.length;

                /* refill the workflow select options */
                $outputSelect.empty();
                for (i = 0; i < dataLength; i++) {
                    $outputSelect.append($('<option></option>')
                        .attr('value', data[i]).text(data[i]));
                }
            }
        });
    });
};

$(function() {
    var forms = $('form.js-form-workflow')
        ,formsLength = forms.length
        ,i;

    for (i = 0; i < formsLength; i++) {
        PEAKWORK.FORM.CRSINSTANCE.WORKFLOW.buildWorkflowForm($(forms[i]), {});
    }
});