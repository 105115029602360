var contextIcmBaseEmptySearch = pwNamespace('PEAKWORK.ICM.BASE');
contextIcmBaseEmptySearch.setEmptySearchValue = function ($element) {
    var $formField = $('#' + $element.data('inputid'))
        ,nullValue = $element.data('nullvalue');

    $formField.val(nullValue);
};

$(function() {
    $(document).on('click', '.js-form-searchforemptyvalue', function(event) {
        event.preventDefault();
        contextIcmBaseEmptySearch.setEmptySearchValue($(this));
    });
});