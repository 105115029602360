var contextIcmGenericBundle = pwNamespace('PEAKWORK.GERNERIC.Bundle');

/**
 * Inits the list as well as the permission, history and navigation clicks inside the list view
 * @param item
 */
contextIcmGenericBundle.initLists = function(item) {
    var $item = $(item)
        ,id = $item.attr('id')
        ,resource = $item.data('resource')
        ,formId = $item.data('formid')
        ,listOptions = $item.data('listoptions');

    new PEAKWORK.ICM.GENERIC.List({
        resource: resource
        ,tableDomId: '#' + id
        ,tableId: id
        ,dataTableIdentifier: id
        ,filterDomId: '#' + formId
        ,filterSubmittedEvent: id + 'FilterSubmitted'
        ,addBtn: 'div.addBtn'
        ,activeToggle: '.js-peakworkIcmGenericToggleActive'
        ,deleteBtn: '.js-peakworkIcmGenericDelete'
        ,exportBtn: '.js-peakworkIcmGenericExport'
        ,permissionsBtn: '.js-peakworkIcmGenericPermissions'
        ,historyBtn: '.js-peakworkIcmGenericHistory'
        ,exportCookieName: 'peakworkicmgenericinstanceexport'
        ,listOptions: listOptions
    });

    new PEAKWORK.ICM.GENERIC.Permissions({
        resource: resource
    });

    new PEAKWORK.ICM.GENERIC.History({
        resource: resource
    });

    new PEAKWORK.ICM.GENERIC.Navigation({
        resource: resource
    });
};

/**
 * Inits permission, history and navigation clicks inside the detail form
 * @param item
 */
contextIcmGenericBundle.initDetails = function(item) {
    var $item = $(item)
        ,resource = $item.data('resource')
        ,instanceId = $item.find('#generic_id_value').val();

    new PEAKWORK.ICM.GENERIC.Permissions({
        resource: resource
        ,instanceId: instanceId
    });

    new PEAKWORK.ICM.GENERIC.History({
        resource: resource
        ,instanceId: instanceId
    });

    new PEAKWORK.ICM.GENERIC.Navigation({
        resource: resource
        ,instanceId: instanceId
    });
};


$(function() {
    var lists = $('.js-peakworkIcmListTable')
        ,listsLength = lists.length
        ,i
        ,forms = $('form[name="generic"]')
        ,formsLength = forms.length;

    for (i = 0; i < listsLength; i++) {
        contextIcmGenericBundle.initLists(lists[i]);
    }

    for (i = 0; i < formsLength; i++) {
        contextIcmGenericBundle.initDetails(forms[i]);
    }
});