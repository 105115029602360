var contextIcmWorkflowOfficeCrsRouting = pwNamespace('PEAKWORK.ICM.WORKFLOW');

contextIcmWorkflowOfficeCrsRouting.CrsRouting = function (config) {
    config = config || {};

    this.config = $.extend(true, {
        'translationDomain': 'peakworkicmworkflowoffice'
    }, config);

    this.$element = this.config.element;
    this.$contentContainer = this.config.contentContainer;
    this.$filter = this.config.filter;
    this.$pwcustomerField = this.config.pwcustomerField;
    this.$pwofficeField = this.config.pwofficeField;

    this.init();
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.init = function() {
    this.loadContent();
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.loadContent = function() {
    var me = this;

    $.ajax({
        url: me.$element.attr('href')
        ,method: 'POST'
        ,data: me.getFilterValues()
        ,beforeSend: function() {
            me.$contentContainer.html(
                PeakworkBackendHelper.getInstance().createLoader()
            );

            $('html, body').animate({
                scrollTop: me.$contentContainer.offset().top
            }, 500);
        }
        ,success: function(response) {
            me.showContent(response);

            $('html, body').animate({
                scrollTop: me.$contentContainer.offset().top
            }, 500);
        }
    });
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.showContent = function(response) {
    var $element = $(response)
        ,$checkboxWrapper
        ,i = 0
        ,checkboxes = []
        ,label = [];

    /**
     * Workaround:
     * Add important attributes to duplicated checkbox fields within a checkbox container.
     * Otherwise the checkbox value will not be set if the label is clicked.
     * We must only run this for checkboxes that do have a label.
     *
     * For some unknown reason in the workflow template checkboxes will be rendered like
     * <div class="checkbox">
     *   <input type="checkbox" name="abc" />
     *   <input type="checkbox" />
     *   <label for="abc">X</label>
     * </div>
     *
     * This results in the checkbox is not clickable.
     *
     * @see https://jira.peakwork.com/browse/MAINT-6385
     */
    $checkboxWrapper = $element.find('div.checkbox');
    for (i; i < $checkboxWrapper.length; i++) {
        checkboxes = $($checkboxWrapper[i]).find('input[type="checkbox"]');
        label = $($checkboxWrapper[i]).find('label');
        if (
            label.length === 1
            && checkboxes.length === 2
            && !$(checkboxes[1]).attr('name')
        ) {
            $(checkboxes[1]).attr('name', $(checkboxes[0]).attr('name'));
            if ($(checkboxes[0]).attr('checked')) {
                $(checkboxes[1]).attr('checked', $(checkboxes[0]).attr('checked'));
            }
            $(checkboxes[1]).attr('id', $(label).attr('for'));
            $(checkboxes[0]).remove();
        }
    }

    this.$contentContainer.html($element);

    this.initEvents($element);
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.getFilterValues = function() {
    return {
        'pwcustomer': this.$pwcustomerField.select2('val')
        ,'pwoffice': this.$pwofficeField.select2('val')
    };
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.initEvents = function($element) {
    var me = this
        ,autoCompletetFields = $element.find('input.js-form-autocomplete')
        ,autoCompleteFieldsLength = autoCompletetFields.length
        ,i;

    for (i = 0; i < autoCompleteFieldsLength; i++) {
        PEAKWORK.FORM.AUTOCOMPLETE.init($(autoCompletetFields[i]));
    }

    PEAKWORK.ICM.RESTCLIENT.CRSROUTING.touroperator($element);
    PEAKWORK.ICM.RESTCLIENT.CRSROUTING.crsinstance($element);
    PEAKWORK.ICM.RESTCLIENT.CRSROUTING.crsauthentication($element, function(params) {
        me.toggleCrsAuthenticationEdit($element, params);
    });

    $element.on('click', '.js-peakworkICMCrsRoutingAbortBtn', function(event) {
        event.preventDefault();
        me.abort($(this));
    });

    $element.on('click', '.js-peakworkICMCrsRoutingSubmitBtn', function(event) {
        event.preventDefault();
        me.submit($(this));
    });

    $element.on('click', '.js-peakworkIcmCrsRoutingCrsAuthenticationEditBtn', function(event) {
        event.preventDefault();
        me.checkEnableCrsAuthenticationFields($element);
    });
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.toggleCrsAuthenticationEdit = function($element, params) {
    var $button = $($element.find('.js-peakworkIcmCrsRoutingCrsAuthenticationEditBtn'));
    $button.prop('disabled', ('registernew' === params.id));
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.checkEnableCrsAuthenticationFields = function($element) {
    var me = this
        ,$idField = $element.find('.js-peakworkIcmCrsRoutingCrsAuthenticationId')
        ,id = $idField.select2('val');

    if ('registernew' === id) {
        return;
    }

    $.ajax({
        url: Routing.generate('peakwork_icm_workflowoffice_crsrouting_crsauthentications', {
            id: id
        })
        ,dataType: 'json'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('crsrouting.crsauthentication.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            if ('undefined' !== typeof response.info) {
                me.confirmEnableCrsAuthenticationFields($element, response);
            } else {
                me.enableCrsAuthenticationFields($element);
                swal.close();
            }
        }
        ,error: function() {
            swal({
                title: Translator.trans('crsrouting.crsauthentication.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('crsrouting.crsauthentication.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
                ,showConfirmButton: true
                ,confirmButtonText: Translator.trans('crsrouting.crsauthentication.error.confirmbtn', {}, me.config.translationDomain)
            });
        }
    });
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.confirmEnableCrsAuthenticationFields = function($element, response) {
    var me = this;
    swal({
        title: Translator.trans('crsrouting.crsauthentication.confirm.title', {}, me.config.translationDomain)
        ,text: response.info
        ,html: true
        ,type: 'warning'
        ,showConfirmButton: true
        ,confirmButtonText: Translator.trans('crsrouting.crsauthentication.confirm.confirmbtn', {}, me.config.translationDomain)
        ,showCancelButton: true
        ,confirmButtonColor: '#DD6B55'
        ,closeOnConfirm: true
        ,closeOnCancel: true
    },function(isConfirm) {
        if (isConfirm) {
            me.enableCrsAuthenticationFields($element);
        }
    });
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.enableCrsAuthenticationFields = function($element) {
    var $editedField = $($element.find('.js-peakworkIcmCrsRoutingCrsAuthenticationEdited'))
        ,fields = $element.find('.js-form-generic-output-container').find('input')
        ,i = 0
        ,fieldsLength = fields.length;

    // enable the name field
    $element.find('input.js-peakworkIcmCrsRoutingCrsAuthenticationName')
        .prop('readonly', false);

    // enable all generic fields
    for (i; i < fieldsLength; i++) {
        $(fields[i]).prop('readonly', false);
        $(fields[i]).prop('disabled', false);
    }

    $editedField.prop('checked', true);
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.abort = function($element) {
    var me = this;
    this.$contentContainer.html('');

    $('html, body').animate({
        scrollTop: me.$filter.offset().bottom
    }, 500);
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.submit = function($element) {
    var me = this
        ,$form = $element.closest('form');

    $.ajax({
        'url': $form.attr('action')
        ,data: $form.serialize()
        ,method: 'POST'
        ,beforeSend: function() {
            me.$contentContainer.html('');
            swal({
                title: Translator.trans('crsrouting.stored.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.submitSuccess();
        }
        ,error: function(response) {
            me.showContent(response.responseText);
        }
    });
};

contextIcmWorkflowOfficeCrsRouting.CrsRouting.prototype.submitSuccess = function() {
    var me = this;

    me.$contentContainer.html('');
    swal({
        title: Translator.trans('crsrouting.stored.success.title', {}, me.config.translationDomain)
        ,text: Translator.trans('crsrouting.stored.success.text', {}, me.config.translationDomain)
        ,html: true
        ,type: 'success'
        ,showConfirmButton: true
        ,confirmButtonText: Translator.trans('crsrouting.stored.success.confirmbtn', {}, me.config.translationDomain)
    },function(isConfirm) {
        if (isConfirm) {
            me.$filter.trigger('peakworkIcmWorkflowOfficeListFilterSubmitted');
        }
    });
};