$(function() {
    var $usergroupSelectionForm = $('#peakworkIcmWorkflowUserSelectionForm')
        ,$usergroupSelectionField = $usergroupSelectionForm.find('.js-peakworkIcmWorkflowUserSelection');

    /* link to user page for selected group */
    $usergroupSelectionField.on('change', function (e) {
        var url = Routing.generate('peakwork_icm_workflow_user_edit', {
            username: e.val
        });

        swal({
            title: Translator.trans('loaduserdetailpage', {}, 'peakworkicmworkflowusergroup')
            ,text: PeakworkBackendHelper.getInstance().createLoader()
            ,html: true
            ,showConfirmButton: false
        });

        window.location = url;
    });
});