(function ( $, window, document, undefined ) {
    'use strict';

    var pluginName = 'pwFieldDefinition';

    // Create the plugin constructor
    function PwFieldDefinition(element, options) {
        this.element = element;
        this._isInit = false;
        this._fields = {};
        this._containers = {};
        this._name = pluginName;
        this._defaults = $.fn[pluginName].defaults;
        this.options = $.extend(
            {
                mainresource: 'resource'
                ,mainresourceFieldDefintionsField: 'fieldDefinitions'
                ,resourceFieldDefinitionRouteId: 'peakwork_icm_generice_getfielddefinitionlist'
                ,defaultType: 'String'
                ,isUpdateClass: 'js-peakworkIcmGenericFieldDefinitionIsUpdate'
                ,isCopyClass: 'js-peakworkIcmGenericFieldDefinitionIsCopy'
                ,containers: {
                    options: '.js-peakworkIcmGenericFieldDefinitionOptions'
                    ,listOptions: '.js-peakworkIcmGenericFieldDefinitionListOptions'
                    ,resource: '.js-peakworkIcmGenericFieldDefinitionResource'
                    ,collectionItems: '.js-peakworkIcmGenericFieldDefinitionCollectionItems'
                    ,enumValues: '.js-peakworkIcmResourceTypeFieldDefinitionEnumValues'
                },
                fields: {
                    type: '.js-peakworkIcmResourceTypeFieldDefinitionType'
                    ,identifier: '.js-peakworkIcmGenericFieldDefinitionIdentifier'
                    ,optionsReadOnly: '.js-peakworkIcmGenericFieldDefinitionOptionsReadonly'
                    ,optionsNotEditable: '.js-peakworkIcmGenericFieldDefinitionOptionsNotEditable'
                    ,optionsRequired: '.js-peakworkIcmGenericFieldDefinitionOptionsRequired'
                    ,optionsUnique: '.js-peakworkIcmGenericFieldDefinitionOptionsUnique'
                    ,optionsPublic: '.js-peakworkIcmGenericFieldDefinitionOptionsPublic'
                    ,optionsMin: '.js-peakworkIcmGenericFieldDefinitionOptionsMin'
                    ,optionsMax: '.js-peakworkIcmGenericFieldDefinitionOptionsMax'
                    ,optionsRegex: '.js-peakworkIcmGenericFieldDefinitionOptionsRegex'
                    ,optionsValue: '.js-peakworkIcmGenericFieldDefinitionOptionsValue'
                    ,optionsDependsOnSourceIdentifier: '.js-peakworkIcmGenericFieldDefinitionOptionsDependsOnSourceIdentifier'
                    ,optionsDependsOnDestinationIdentifier: '.js-peakworkIcmGenericFieldDefinitionOptionsDependsOnDestinationIdentifier'
                    ,optionsCollectionType: '.js-peakworkIcmGenericFieldDefinitionOptionsCollectionType'
                    ,optionsMimeTypes: '.js-peakworkIcmGenericFieldDefinitionOptionsMimeTypes'
                    ,listOptionsNotInList: '.js-peakworkIcmGenericFieldDefinitionListOptionsNotInList'
                    ,listOptionsVisible: '.js-peakworkIcmGenericFieldDefinitionListOptionsVisible'
                    ,listOptionsSearchable: '.js-peakworkIcmGenericFieldDefinitionListOptionsSearchable'
                    ,listOptionsSortable: '.js-peakworkIcmGenericFieldDefinitionListOptionsSortable'
                    ,resourceId: '.js-peakworkIcmGenericFieldDefinitionResourceId'
                    ,resourceSearchField: '.js-peakworkIcmGenericFieldDefinitionResourceSearchField'
                    ,resourceDefinitionField: '.js-peakworkIcmGenericFieldDefinitionResourceDefinitionField'
                    ,resourceOnDelete: '.js-peakworkIcmGenericFieldDefinitionResourceOnDelete'
                },
                definitions: {
                    id: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: false
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,string: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,file: {
                        enabledFields: [
                            'type', 'identifier', 'optionsRequired', 'optionsMin', 'optionsMax', 'optionsPublic',
                            'optionsMimeTypes', 'options', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,optionsPublic: false
                            ,listOptionsNotInList: true
                            ,listOptionsVisible: false
                            ,listOptionsSearchable: false
                            ,listOptionsSortable: false
                        }
                    }
                    ,text: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,url: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,email: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,secret: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsRegex', 'optionsValue',
                            'options', 'optionsNotEditable'
                        ]
                        ,hiddenFields: [
                            'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: true
                        }
                    }
                    ,enum: {
                        enabledFields:  [
                            'type', 'identifier', 'optionsRequired', 'optionsUnique', 'optionsMax',
                            'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'enumValues', 'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: 1
                            ,listOptionsNotInList: true
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,integer: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,long: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,double: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax',
                            'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,boolean: {
                        enabledFields: [
                            'type', 'identifier', 'optionsReadOnly', 'optionsRequired', 'optionsValue', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'options', 'listOptions', 'optionsNotEditable', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                        }
                    }
                    ,collection: {
                        enabledFields: [
                            'type', 'identifier', 'optionsRequired', 'optionsMin', 'optionsMax', 'options', 'optionsCollectionType', 'collectionItems'
                        ]
                        ,hiddenFields: [
                            'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: ''
                            ,listOptionsNotInList: true
                            ,optionsCollectionType: 'table'
                        }
                    }
                    ,reference: {
                        enabledFields: [
                            'type', 'identifier', 'optionsRequired', 'optionsUnique', 'optionsMin', 'optionsMax', 'optionsDependsOnSourceIdentifier',
                            'optionsDependsOnDestinationIdentifier', 'listOptionsVisible', 'listOptionsSearchable', 'listOptionsSortable',
                            'resourceId', 'resourceSearchField', 'resourceOnDelete', 'options', 'listOptions', 'resource', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: 1
                            ,listOptionsNotInList: false
                            ,listOptionsVisible: true
                            ,listOptionsSearchable: true
                            ,listOptionsSortable: true
                            ,resourceOnDelete: 'setnull'
                        }
                        ,required: ['resourceId', 'resourceSearchField', 'resourceOnDelete']
                    }
                    ,referencewithfields: {
                        enabledFields: [
                            'type', 'identifier', 'optionsRequired', 'optionsUnique', 'optionsDependsOnSourceIdentifier', 'optionsDependsOnDestinationIdentifier',
                            'resourceId', 'resourceSearchField', 'resourceDefinitionField', 'resourceOnDelete', 'options', 'resource'
                        ],
                        hiddenFields: [
                            'optionsMax', 'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            optionsMin: ''
                            ,optionsMax: 1
                            ,listOptionsNotInList: true
                            ,listOptionsVisible: false
                            ,listOptionsSearchable: false
                            ,listOptionsSortable: false
                            ,resourceOnDelete: 'setnull'
                        }
                        ,required: ['resourceId', 'resourceSearchField', 'resourceDefinitionField', 'resourceOnDelete']
                    }
                    ,fielddefinition: {
                        enabledFields: [
                            'type', 'identifier', 'optionsRequired', 'options'
                        ]
                        ,hiddenFields: [
                            'listOptionsNotInList'
                        ]
                        ,defaultValues: {
                            listOptionsNotInList: true
                        }
                    }
                }

            }
            ,this._defaults
            ,options
        );

        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(PwFieldDefinition.prototype, {

        // Initialization logic
        init: function () {
            this.buildCache();
            this.buildAutocomplete();
            this.buildPwCollectionForCollectionPanel();
            this.buildPwCollectionForEnumeration();
            this.buildPwCollectionForOptionMimeTypes();
            this.getType();
            this.bindEvents();
            this.prepareForType(this._isUpdate || this._isCopy);
            if (true === this._isUpdate || true === this._isCopy) {
                this.prepareForUpdate();
            } else {
                this.initSelect2(this._fields['resourceSearchField']);
                this.initSelect2(this._fields['resourceDefinitionField']);
            }

            this._isInit = true;
            this.$form.trigger('fielddefinition:added', [this]);
        }

        // Remove plugin instance completely
        ,destroy: function() {
            this.unbindEvents();
            this.$element.removeData();
        }

        /**
         * Checks if the parent of the element is the correct fielddefinition.
         * @param $element
         * @returns {boolean}
         */
        ,checkParent: function($element) {
            var $item = $element.closest('.' + this.options.itemClass);
            return $item[0] === this.$element[0];
        }

        /**
         * Checks if the element is part of a hidden template.
         * @param $element
         * @returns {boolean}
         */
        ,isTemplate: function($element) {
            return 0 !== $element.closest('.' + this.options.templateClass).length;
        }

        // Cache DOM nodes for performance
        ,buildCache: function () {
            var plugin = this
                ,key
                ,fields
                ,fieldsLength
                ,i
                ,$element;

            this.$element = $(this.element);
            this.$form = this.$element.closest('form');
            this._isUpdate = 'true' === $(this.element).find('.' + this.options.isUpdateClass).val();
            this._isCopy = 'true' === $(this.element).find('.' + this.options.isCopyClass).val();

            for (key in this.options.fields) {
                fields = $(this.$element.find(this.options.fields[key]));
                fieldsLength = fields.length;
                for (i = 0; i < fieldsLength; i++) {
                    $element = $(fields[i]);
                    if (true === plugin.checkParent($element)) {
                        plugin._fields[key] = $element;
                    }
                }
            }

            for (key in this.options.containers) {
                fields = $(this.$element.find(this.options.containers[key]));
                fieldsLength = fields.length;
                for (i = 0; i < fieldsLength; i++) {
                    $element = $(fields[i]);
                    if (true === plugin.checkParent($element)) {
                        plugin._containers[key] = $element;
                    }
                }
            }
        }

        /**
         * Initializes the autocomplete fields.
         */
        ,buildAutocomplete: function() {
            var plugin = this
                ,fields = this.$element.find('input.' + this.options.autocompleteClass)
                ,fieldsLength = fields.length
                ,i
                ,$element;

            for (i = 0; i < fieldsLength; i++) {
                $element = $(fields[i]);
                if (true === plugin.checkParent($element) && false === plugin.isTemplate($element)) {
                    PEAKWORK.FORM.AUTOCOMPLETE.init($element);
                }

            }
        }

        /**
         * Initializes a select2 field.
         * @param $element
         */
        ,initSelect2: function($element, data) {
            if (this.isTemplate($element)) {
                return;
            }

            var value = $element.val();

            data = 'undefined' === typeof data ? [] : data;
            $element.select2({
                data: data
                ,dropdownCssClass: 'bigdrop'
                ,escapeMarkup: function (m) {
                    return m;
                }
            });
            $element.select2('readonly', '' === value.trim());
            $element.select2('val', value);
            $element.addClass('js-has-select2');
        }

        ,validateForm: function() {
            var formId = this.$form.attr('id');

            if (
                'undefined' !== typeof PEAKWORK.ICM.BASE.FormsWithValidators[formId] &&
                !$.isEmptyObject(PEAKWORK.ICM.BASE.FormsWithValidators[formId].validator.submitted)
            ) {
                PEAKWORK.ICM.BASE.FormsWithValidators[formId].validator.form();
            }
        }

        /**
         * Initializes the pwcollection fields
         */
        ,buildPwCollectionForCollectionPanel: function() {
            var plugin = this
                ,fields
                ,fieldsLength
                ,i
                ,$element;

            if ('undefined' === typeof this._containers['collectionItems']) {
                return;
            }

            fields = this._containers['collectionItems'].find('.' + this.options.collectionPanelClass);
            fieldsLength = fields.length;
            for (i = 0; i < fieldsLength; i++) {
                $element = $(fields[i]);
                if (true === plugin.checkParent($element) && false === plugin.isTemplate($element)) {
                    $element.pwCollection();
                }
            }
        }

        /**
         * Initializes the pwcollection fields for the enumeration definition
         */
        ,buildPwCollectionForEnumeration: function() {
            if ('undefined' === typeof this._containers['enumValues']) {
                return;
            }

            this._containers['enumValues'].pwCollection();
        }

        /**
         * Initializes the pwcollection fields for the options mimetype
         */
        ,buildPwCollectionForOptionMimeTypes: function() {
            if ('undefined' === typeof this._fields['optionsMimeTypes']) {
                return;
            }

            this._fields['optionsMimeTypes'].pwCollection();
        }

        // Bind events that trigger methods
        ,bindEvents: function() {
            var plugin = this;
            plugin._fields['type'].on('change.' + plugin._name, function (event) {
                var $element = $(this);

                if ('readonly' === $element.attr('readonly')) {
                    $element.val(plugin._type);
                    return;
                }

                plugin._type = $element.val();
                plugin.prepareForType();
            });

            plugin._fields['resourceId'].on('select2-selecting', function(e) {
                plugin.prepareForResourceChange($(this), e.val);
            });

            if ('undefined' !== typeof plugin._fields['listOptionsNotInList']) {
                plugin._fields['listOptionsNotInList'].on('change.' + plugin._name, function() {
                    plugin.updateListOptions($(this).prop('checked'));
                });
            }
        }

        // Unbind events that trigger methods
        ,unbindEvents: function() {
            /*
             Unbind all events in our plugin's namespace that are attached
             to "this.$element".
             */
            this.$element.off('.' + this._name);
        }

        /**
         * Stores the type values. Sets the default for new fielddefinitions.
         */
        ,getType: function() {
            if (false === this._isCopy && false === this._isUpdate && false === this._isInit) {
                this._fields['type'].val(this.options.defaultType);
            }

            this._type = this._fields['type'].val();
        }

        /**
         * Disables or enables the fields for the selected type.
         * @param ignoreDefaults
         */
        ,prepareForType: function(ignoreDefaults) {
            var key
                ,type = this._type.toLowerCase()
                ,defaultValues = true !== ignoreDefaults && this.options.definitions[type].defaultValues ?
                this.options.definitions[type].defaultValues : {}
                ,requiredFields = this.options.definitions[type].required || [];

            for (key in this._fields) {
                if (-1 !== $.inArray(key, this.options.definitions[type].enabledFields)) {
                    this.enableField(key, this._fields[key], defaultValues, false, requiredFields);
                } else if (-1 !== $.inArray(key, this.options.definitions[type].hiddenFields)) {
                    this.enableField(key, this._fields[key], defaultValues, true, requiredFields);
                } else {
                    this.disableField(key, this._fields[key]);
                }
            }

            for (key in this._containers) {
                if (-1 !== $.inArray(key, this.options.definitions[type].enabledFields)) {
                    this.enableContainer(key, this._containers[key]);
                } else {
                    this.disableContainer(key, this._containers[key]);
                }
            }

            if ('undefined' !== typeof this._fields['listOptionsNotInList'] &&
                'undefined' !== typeof defaultValues['listOptionsNotInList']
            ) {
                this.updateListOptions(defaultValues['listOptionsNotInList']);
            }

            this.validateForm();
        }

        /**
         * Identifier and type field are readonly if form is in edit mode.
         */
        ,prepareForUpdate: function() {
            var $item = $(this._fields['type'])
                ,$tmpItem
                ,id = $item.closest('.' + this.options.itemClass).attr('id')
                ,listElementHasReadonly = true === this._isCopy || $item.closest('.' + this.options.itemClass)
                    .closest('.' + this.options.collectionClass)
                    .find('li[data-target="#' + id + '"]')
                    .hasClass(this.options.listItemReadonlyClass);

            if (false === this._isCopy) {
                this._fields['identifier'].prop('readonly', true);
                if ('undefined' !== typeof this._fields['listOptionsNotInList']) {
                    this.updateListOptions(this._fields['listOptionsNotInList'].prop('checked'));
                }
            }

            if (false === listElementHasReadonly) {
                $tmpItem = $('<input name="' + $item.attr('name') + '" value="' + $item.val() + '" class="hidden" readonly>');
                $item.after($tmpItem);
                $item.prop('disabled', true);
            }

            if (-1 !== $.inArray(this._type.toLowerCase(), ['reference', 'referencewithfields'])) {
                this.prepareForResourceChange(
                    this._fields['resourceId']
                    ,this._fields['resourceId'].select2('val')
                    ,this.initResourceSelect2FieldsWithData
                );
            }
        }

        /**
         * Disable or enable list options depending on the notInList value.
         * @param notInList
         */
        ,updateListOptions: function(notInList) {
            this.changeDisableStatusForField(this._fields['listOptionsVisible'], notInList);
            this.changeDisableStatusForField(this._fields['listOptionsSearchable'], notInList);
            this.changeDisableStatusForField(this._fields['listOptionsSortable'], notInList);
        }

        /**
         * Changes the property "disabled".
         * @param field
         * @param disabled
         */
        ,changeDisableStatusForField: function(field, disabled) {
            field.prop('disabled', disabled);
        }


        /**
         * Enables a field. That means the field will be enabled and the parent element will be shown.
         * It also shows a label before a collection field.
         * @param key
         * @param field
         * @param defaultValues
         * @param hidden
         */
        ,enableField: function(key, field, defaultValues, hidden, requiredFields) {
            var parent = this.findParentForField(field);
            hidden = hidden ? hidden : false;

            if (parent.hasClass('js-form-collection')) {
                parent.prev().show();
            }
            parent.show();

            if (true === hidden) {
                parent.addClass('hidden');
                field.addClass('hidden');
            } else {
                parent.removeClass('hidden');
                field.removeClass('hidden');
            }

            this.changeDisableStatusForField(field, false);

            if ('undefined' !== typeof defaultValues[key]) {
                this.setValueForField(field, defaultValues[key]);
            }

            if ('identifier' === key || -1 !== $.inArray(key, requiredFields)) {
                field.attr('required', 'required');
                field.prop('required', true);
            } else {
                field.removeAttr('required');
                field.prop('required', false);
            }
        }

        /**
         * Sets the default value for an option.
         * @param field
         * @param value
         */
        ,setValueForField: function(field, value) {
            switch (field.attr('type')) {
                case 'checkbox':
                    field.prop('checked', value);
                    break;
                default:
                    field.val(value);
            }
        }

        /**
         * Disables a field. That means the field will be disabled and the parent element will be hide.
         * It also hides a label before a collection field.
         * @param key
         * @param field
         */
        ,disableField: function(key, field) {
            var parent = this.findParentForField(field);
            if (parent.hasClass('js-form-collection')) {
                parent.prev().hide();
            }
            parent.hide();

            this.changeDisableStatusForField(field, true);

            if ('optionsMimeTypes' === key) {
                this.removeMimeTypesValues(field);
            }

            field.removeAttr('required');
            field.prop('required', false);
        }

        /**
         * Tries to find the parent element for a given field.
         * @param field
         * @returns {*|HTMLElement}
         */
        ,findParentForField: function (field) {
            var parent;
            if (field.is('select') || field.hasClass(this.options.collectionClass)) {
                parent = field.closest('.form-group');
            } else if (field.is('input')) {
                if ('checkbox' === field.attr('type')) {
                    parent = field.closest('.checkbox');
                } else {
                    parent = field.closest('.form-group');
                }
            } else {
                parent = field;
            }

            return $(parent);
        }

        /**
         * Removes the existing enumValues by triggering the delete click event.
         */
        ,removeEnumValues: function(field) {
            field.find('.js-collection-remove').trigger('click');
        }

        /**
         * Removes the existing mimetypes by triggering the delete click event.
         */
        ,removeMimeTypesValues: function(field) {
            field.find('.js-collection-remove').trigger('click');
        }

        /**
         * Shows a field group container
         * @param key
         * @param field
         */
        ,enableContainer: function(key, field) {
            if (field.hasClass('js-collection-content')) {
                var parent = field.closest('.js-collection-panel');
                parent.show();
                parent.prev().show();
            } else {
                field.closest('.hpanel').show();
            }
        }

        /**
         * Hides a field group container
         * @param key
         * @param field
         */
        ,disableContainer: function(key, field) {
            if (field.hasClass('js-collection-content')) {
                var parent = field.closest('.js-collection-panel');
                parent.hide();
                parent.prev().hide();
            } else {
                field.closest('.hpanel').hide();
            }

            if ('enumValues' === key) {
                this.removeEnumValues(field);
            }
        }

        /**
         * Load the data for the reference search and definition fields
         * @param $element
         * @param id
         */
        ,prepareForResourceChange: function($element, id, callback) {
            var plugin = this;

            callback = 'function' === typeof callback ? callback : plugin.updateResourceSelect2FieldsWithData;

            this._fields['resourceSearchField'].select2('val', '');
            this._fields['resourceSearchField'].select2('readonly', true);
            this._fields['resourceDefinitionField'].select2('val', '');
            this._fields['resourceDefinitionField'].select2('readonly', true);
            this.validateForm();

            $.ajax({
                url: Routing.generate(this.options.resourceFieldDefinitionRouteId, {
                    'resource': this.options.mainresource
                    ,'id': id
                    ,'field': this.options.mainresourceFieldDefintionsField
                })
                ,dataType: 'json'
                ,success: function(response) {
                    callback.call(plugin, response);
                }
            });
        }

        /**
         * Inializes the select2 fields including data for existing fielddefinitions.
         * @param response
         */
        ,initResourceSelect2FieldsWithData: function(response) {
            var plugin = this
                ,fieldDefinitions = plugin.getFieldDefinitionListForDefinitionFieldSelect(response);

            this.initSelect2(this._fields['resourceSearchField'], response);
            this.initSelect2(this._fields['resourceDefinitionField'], fieldDefinitions);
        }

        /**
         * Updates the select2 fields data..
         * @param response
         */
        ,updateResourceSelect2FieldsWithData: function (response) {
            var plugin = this
                ,fieldDefinitions = plugin.getFieldDefinitionListForDefinitionFieldSelect(response);

            plugin._fields['resourceSearchField'].select2('readonly', false);
            plugin.loadDataToSelect2Field(plugin._fields['resourceSearchField'], response);


            plugin._fields['resourceDefinitionField'].select2('readonly', !(fieldDefinitions.length > 0));
            plugin.loadDataToSelect2Field(plugin._fields['resourceDefinitionField'], fieldDefinitions);
        }

        /**
         * Prepares the fielddefinition fields.
         * @param response
         * @returns {Array}
         */
        ,getFieldDefinitionListForDefinitionFieldSelect: function(response) {
            var fieldDefinitions = []
                ,length = response.length
                ,i = 0;

            for (i; i < length; i++) {
                if ('fielddefinition' !== response[i].data.type) {
                    continue;
                }

                fieldDefinitions.push(response[i]);
            }

            return fieldDefinitions;
        }

        /**
         * Load new select options to a select2 field.
         * @param $element
         * @param data
         */
        ,loadDataToSelect2Field: function($element, data) {
            $element.select2({
                query: function (query) {
                    query.callback({
                        results: data
                    });
                }
            });
        }

        ,callback: function() {
            // Cache onComplete option
            var onComplete = this.options.onComplete;

            if ( typeof onComplete === 'function' ) {
                /*
                 Use the "call" method so that inside of the onComplete
                 callback function the "this" keyword refers to the
                 specific DOM node that called the plugin.

                 More: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
                 */
                onComplete.call(this.element);
            }
        }

    });

    /*
     Create a lightweight plugin wrapper around the "Plugin" constructor,
     preventing against multiple instantiations.

     More: http://learn.jquery.com/plugins/basic-plugin-creation/
     */
    $.fn[ pluginName ] = function( options ) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' +
                        pluginName, new PwFieldDefinition(this, options));
                }
            });
        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
            var returns;

            this.each(function () {
                var instance = $.data(this, 'plugin_' + pluginName);

                if (instance instanceof PwFieldDefinitionPlugin && typeof instance[options] === 'function') {
                    returns = instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
                }

                if (options === 'destroy') {
                    $.data(this, 'plugin_' + pluginName, null);
                }
            });

            return returns !== undefined ? returns : this;
        }
    };

    $.fn[pluginName].defaults = {
        collectionPanelClass: 'js-collection-panel',
        collectionClass: 'js-collection',
        templateClass: 'js-collection-template',
        contentClass: 'js-collection-content',
        itemClass: 'js-collection-item',
        addClass: 'js-collection-add',
        removeClass: 'js-collection-remove',
        autocompleteClass: 'js-form-autocomplete',
        onComplete: null
        ,listItemReadonlyClass: 'js-collection-listitem-noteditable'
        ,collectionListClass: 'js-collection-list'
    };

})( jQuery, window, document );

var contextIcmGenericFielddefinition = pwNamespace('PEAKWORK.ICM.GENERIC.FIELDDEFINITION');
contextIcmGenericFielddefinition.init = function($fieldDefinition) {
    var context = pwNamespace('PEAKWORK.ICM.RESTCLIENTBUNDLE.FIELDDEFINITION')
        ,fieldDefinitionItems
        ,i = 0
        ,$tmpElement;

    if ($fieldDefinition.length > 0) {
        /* enable select2 for new added field and disable resource type and and aliases */
        context.addedNewFieldDefinition = function(plugin, element) {
            element.pwFieldDefinition();
        };

        context.addedNewCollectionFieldDefinition = function(plugin, element) {
            element.pwFieldDefinition();
        };

        /* register existing items */
        fieldDefinitionItems = $fieldDefinition.find('.js-collection-item');
        if (fieldDefinitionItems.length > 0) {
            for (i; i < fieldDefinitionItems.length; i++) {
                $tmpElement = $(fieldDefinitionItems[i]);
                if (0 !== $tmpElement.closest('.js-collection-template').length ||
                    0 !== $tmpElement.closest('.js-peakworkIcmResourceTypeFieldDefinitionEnumValues').length ||
                    0 !== $tmpElement.closest('.js-peakworkIcmGenericFieldDefinitionOptionsMimeTypes').length
                ) {
                    continue;
                }

                $tmpElement.pwFieldDefinition();
            }
        }
    }
};

$(function() {
    contextIcmGenericFielddefinition.init($('.js-peakworkIcmGenericTypeFieldDefinition'));
});