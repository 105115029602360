var contextIcmGenericResource = pwNamespace('PEAKWORK.ICM.GENERIC.RESOURCE');

/**
 * Checks inital form to update all reference fields (set localresource
 * dependency or remove it)
 * @param $form
 */
contextIcmGenericResource.initLocalResource = function($form) {
    var checkboxIsChecked = $('#generic_localresource_value').prop('checked');
    contextIcmGenericResource.updateReferenceFieldDefinitions($form, checkboxIsChecked);
};

/**
 * Add change event listener to localresource checkbox to update all reference fields (set localresource
 * dependency or remove it)
 * @param $form
 */
contextIcmGenericResource.registerLocalResourceFlagChange = function($form) {
    var checkbox = $('#generic_localresource_value');
    checkbox.on('change', function(event) {
        var isChecked = $(this).prop('checked');
        contextIcmGenericResource.updateReferenceFieldDefinitions($form, isChecked);
        contextIcmGenericResource.checkExistingReferences($form, isChecked);
        PEAKWORK.ICM.BASE.FormsWithValidators[$form.attr('id')].invalidHandler(
            undefined,
            PEAKWORK.ICM.BASE.FormsWithValidators[$form.attr('id')].validator
        );
    });
};

/**
 * Sets or removes the additionalSearch parameters to all reference fields in field definition.
 * @param $form
 * @param isChecked
 */
contextIcmGenericResource.updateReferenceFieldDefinitions = function($form, isChecked) {
    var referenceFields = $form.find('input.js-peakworkIcmGenericFieldDefinitionResourceId')
        ,length = referenceFields.length
        ,i = 0;

    for (i; i < length; i++) {
        contextIcmGenericResource.updateSingleReferenceFieldDefinition($(referenceFields[i]), isChecked);
    }
};

/**
 * Set or remove the additionalSearch parameters to a reference field.
 * @param $field
 * @param isChecked
 */
contextIcmGenericResource.updateSingleReferenceFieldDefinition = function($field, isChecked) {
    var dataParams = $field.data('query-params-autocomplete-search');

    if (isChecked) {
        dataParams['additionalSearch'] = {
            'localresource': 'true'
        };
    } else if (dataParams['additionalSearch']) {
        delete dataParams['additionalSearch'];
    }

    $field.data('query-params-autocomplete-search', dataParams);
    $field.data('shouldBeLocalResource', isChecked);
    $field.data('localresource', isChecked);
};

/**
 * Check for existing references which are not a local resource. Invalidate them.
 * @param $form
 * @param isChecked
 */
contextIcmGenericResource.checkExistingReferences = function($form, isChecked) {
    var referenceFields = $form.find('input.js-peakworkIcmGenericFieldDefinitionResourceId')
        ,length = referenceFields.length
        ,i = 0
        ,$field
        ,dataParams;

    for (i; i < length; i++) {
        $field = $(referenceFields[i]);
        $field.data('shouldBeLocalResource', isChecked);

        if ($field.val() === '') {
            continue;
        }

        dataParams = $field.data('reference-attributes');
        if (typeof dataParams === 'undefined' ||
            typeof dataParams.localresource === 'undefined' ||
            dataParams.localresource === true
        ) {
            continue;
        }

        $field.data('isLocalResource', false);
    }

    PEAKWORK.ICM.BASE.FormsWithValidators[$form.attr('id')].validator.form();
};

/**
 * Add the eventlistener "select2.getloaded" to the reference fields to set the "localresource" value to the element
 * after reference was loaded successfully.
 * @param $form
 */
contextIcmGenericResource.addReferenceFieldLoadedEventListener = function($form) {
    $form.on('select2-initselected', 'input.js-peakworkIcmGenericFieldDefinitionResourceId', function(event, data) {
        if (data.data && data.data.attributes) {
            var $field = $(this);
            $field.data('reference-attributes', data.data.attributes);
            $field.data('isLocalResource', data.data.attributes.localresource === true);
        }
    });
};

/**
 * Add the eventlistener "select2-selecting" to the reference fields to set the "localresource" value to the element
 * after reference was loaded successfully.
 * @param $form
 */
contextIcmGenericResource.addReferenceFieldSelectedEventListener = function($form) {
    $form.on('select2-selecting', 'input.js-peakworkIcmGenericFieldDefinitionResourceId', function(event) {
        if (event.object && event.object.data && event.object.data.attributes) {
            var $field = $(this);
            $field.data('reference-attributes', event.object.data.attributes);
            $field.data('isLocalResource', event.object.data.attributes.localresource === true);
            PEAKWORK.ICM.BASE.FormsWithValidators[$form.attr('id')].validator.form();
        }
    });
};

/**
 * Add eventlistener "fielddefinition:added" to form to set all neccessary data to the reference field.
 * @param $form
 */
contextIcmGenericResource.addFieldDefinitionAddedEventListener = function($form) {
    $form.on('fielddefinition:added', function(event, fieldDefinition) {
        var isChecked = $('#generic_localresource_value').prop('checked');
        fieldDefinition._fields['resourceId'].rules('add', 'localresource');
        fieldDefinition._fields['resourceId'].data('shouldBeLocalResource', isChecked);
        contextIcmGenericResource.updateSingleReferenceFieldDefinition(
            fieldDefinition._fields['resourceId'], isChecked
        );
    });
};

$(function() {
    var $form = $('#peakworkIcmResourceForm');

    if ($form.length === 1) {
        /**
         * Local Resource Validator
         */
        $.validator.addMethod('localresource', function(value, element, params) {
            var $element = $(element)
                ,shouldBeLocalResource = $element.data('shouldBeLocalResource')
                ,isLocalResource = $element.data('isLocalResource');

            if (shouldBeLocalResource !== true) {
                return true;
            }

            return shouldBeLocalResource === true && isLocalResource === true;
        }, $.validator.format(Translator.trans('form.validate.localresource', {}, 'peakworkicmbase')));


        contextIcmGenericResource.addFieldDefinitionAddedEventListener($form);
        contextIcmGenericResource.addReferenceFieldLoadedEventListener($form);
        contextIcmGenericResource.addReferenceFieldSelectedEventListener($form);
        contextIcmGenericResource.initLocalResource($form);
        contextIcmGenericResource.registerLocalResourceFlagChange($form);
    }
});