var contextIcmWorkflowOfficePayment = pwNamespace('PEAKWORK.ICM.WORKFLOW');

contextIcmWorkflowOfficePayment.Payment = function (config) {
    config = config || {};

    this.config = $.extend(true, {
        'translationDomain': 'peakworkicmworkflowoffice'
        ,'editBtnClass': 'js-peakworkICMWorkflowPaymentEdit'
    }, config);

    this.$element = this.config.element;
    this.$contentContainer = this.config.contentContainer;
    this.$filter = this.config.filter;
    this.$pwcustomerField = this.config.pwcustomerField;
    this.$pwofficeField = this.config.pwofficeField;
    this.$table = this.config.table;

    this.init();
};

contextIcmWorkflowOfficePayment.Payment.prototype.init = function() {
    this.loadContent();
};

contextIcmWorkflowOfficePayment.Payment.prototype.loadContent = function(options) {
    var me = this
        ,queryParams = me.getFilterValues();

    queryParams.touroperator = me.$element.data('touroperator');
    queryParams.crs = me.$element.data('crs');
    queryParams.traveltype = me.$element.data('traveltype');

    options = 'undefined' !== typeof options ? options : {};

    $.ajax({
        url: me.$element.attr('href')
        ,method: 'POST'
        ,data: queryParams
        ,beforeSend: function() {
            me.$contentContainer.html(
                PeakworkBackendHelper.getInstance().createLoader()
            );

            $('html, body').animate({
                scrollTop: me.$contentContainer.offset().top
            }, 500);
        }
        ,success: function(response) {
            me.showContent(response);

            $('html, body').animate({
                scrollTop: me.$contentContainer.offset().top
            }, 500);

            if (true === options.updateTable) {
                me.updateTableElement();
            }
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.showContent = function(response) {
    var $element = $(response);
    this.$contentContainer.html($element);
    this.initEvents($element);
};

contextIcmWorkflowOfficePayment.Payment.prototype.getFilterValues = function() {
    return {
        'pwcustomer': this.$pwcustomerField.select2('val')
        ,'pwoffice': this.$pwofficeField.select2('val')
    };
};

contextIcmWorkflowOfficePayment.Payment.prototype.initEvents = function($element) {
    var me = this;

    $element.on('click', '.js-close-box-payment', function(event) {
        event.preventDefault();
        me.closeBox($(this));
    });

    $element.on('click', '.js-select-plan', function(event) {
        event.preventDefault();
        me.selectPlan($(this));
    });

    $element.on('click', '.js-view-payment', function(event) {
        event.preventDefault();
        me.loadDetailForm($(this), {
            routeId: 'peakwork_icm_workflowoffice_payment_get'
        });
    });

    $element.on('click', '.js-edit-payment', function(event) {
        event.preventDefault();
        me.loadDetailForm($(this), {
            routeId: 'peakwork_icm_workflowoffice_payment_edit'
        });
    });

    $element.on('click', '.js-delete-payment', function(event) {
        event.preventDefault();
        me.deleteCustom($(this));
    });

    $element.on('click', '.js-add-payment', function(event) {
        event.preventDefault();
        me.addCustom($(this));
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.closeBox = function($button) {
    var me = this;

    me.$contentContainer.html('');
    $('html, body').animate({
        scrollTop: me.$filter.offset().bottom
    }, 500);
};

contextIcmWorkflowOfficePayment.Payment.prototype.selectPlan = function($button) {
    var me = this
        ,$panel = $button.closest('.hpanel');

    if ($panel.hasClass('active')) {
        return;
    }

    if (!$panel.hasClass('js-custom-payment')) {
        swal({
            title: Translator.trans('payment.inherit.selected.confirm.title', {}, me.config.translationDomain)
            ,text: Translator.trans('payment.inherit.selected.confirm.text', {}, me.config.translationDomain)
            ,type: 'warning'
            ,showCancelButton: true
            ,confirmButtonColor: '#DD6B55'
            ,confirmButtonText: Translator.trans('payment.inherit.selected.confirm.confirmbtn', {}, me.config.translationDomain)
            ,closeOnConfirm: true
            ,closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                me.selectInherit();
            }
        });
    } else {
        me.selectCustom();
    }
};

/**
 * Select the inherit data -> remove all custom data
 */
contextIcmWorkflowOfficePayment.Payment.prototype.selectInherit = function() {
    var me = this
        ,queryParams = me.getFilterValues();

    queryParams.touroperator = me.$element.data('touroperator');
    queryParams.traveltype = me.$element.data('traveltype');

    $.ajax({
        url: Routing.generate('peakwork_icm_workflowoffice_payment_delete_all_custom')
        ,data: queryParams
        ,method: 'POST'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('payment.inherit.selected.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.loadContent({
                updateTable: true
            });
            swal.close();
        }
        ,error: function() {
            swal({
                title: Translator.trans('payment.inherit.selected.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('payment.inherit.selected.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
            });
        }
    });
};

/**
 * Select the custom data -> copy all inherit data
 */
contextIcmWorkflowOfficePayment.Payment.prototype.selectCustom = function() {
    var me = this
        ,queryParams = me.getFilterValues();

    queryParams.touroperator = me.$element.data('touroperator');
    queryParams.traveltype = me.$element.data('traveltype');

    $.ajax({
        url: Routing.generate('peakwork_icm_workflowoffice_payment_copy_inherit')
        ,data: queryParams
        ,method: 'POST'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('payment.custom.selected.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.loadContent();
            swal.close();
        }
        ,error: function() {
            swal({
                title: Translator.trans('payment.custom.selected.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('payment.custom.selected.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
                ,confirmButtonText: Translator.trans('payment.custom.selected.error.confirmbtn', {}, me.config.translationDomain)
                ,closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    me.loadContent({
                        updateTable: true
                    });
                    swal.close();
                }
            });
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.loadDetailForm = function($button, options) {
    var me = this
        ,$paymentContent = me.$contentContainer.find('.js-content-payment')
        ,id = $button.data('id');

    $.ajax({
        url: Routing.generate(options.routeId, {
            id: id
            ,option: $button.closest('.js-item-payment').find('.js-name-payment').html()
        })
        ,beforeSend: function() {
            $paymentContent.html(PeakworkBackendHelper.getInstance().createLoader());

            $('html, body').animate({
                scrollTop: $paymentContent.offset().top
            }, 500);
        }
        ,success: function(response) {
            me.showDetailForm(response, $paymentContent, id);
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.showDetailForm = function(response, $paymentContent, id) {
    var me = this
        ,$responseElement = $(response);

    $paymentContent.html($responseElement);

    $('html, body').animate({
        scrollTop: $paymentContent.offset().top
    }, 500);

    me.initDetailFormEvents($responseElement, $paymentContent, id);
};

contextIcmWorkflowOfficePayment.Payment.prototype.initDetailFormEvents = function($element, $paymentContent, id) {
    var me = this
        ,panels = $element.find('.js-collection-panel')
        ,panelsLength = panels.length
        ,i
        ,$panelItem;

    for (i = 0; i < panelsLength; i++) {
        $panelItem = $(panels[i]);
        if (0 === $panelItem.closest('.js-collection-template').length) {
            $panelItem.pwCollection();
        }
    }

    $element.on('click', '.js-peakworkICMGenericAbortBtn', function(event) {
        event.preventDefault();
        $paymentContent.html('');

        $('html, body').animate({
            scrollTop: me.$contentContainer.offset().top
        }, 500);
    });

    $element.on('click', '.js-peakworkICMGenericSubmitBtn', function(event) {
        event.preventDefault();
        me.storeDetailForm($(this), $paymentContent, id);
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.storeDetailForm = function($element, $paymentContent, id) {
    var me = this
        ,$form = $element.closest('form');

    $.ajax({
        'url': Routing.generate('peakwork_icm_workflowoffice_payment_edit', {
            id: id
        })
        ,data: $form.serialize()
        ,method: 'POST'
        ,beforeSend: function() {
            $paymentContent.html('');
            swal({
                title: Translator.trans('payment.stored.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.submitSuccess($paymentContent);
        }
        ,error: function(response) {
            me.showDetailForm(response, $paymentContent);
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.submitSuccess = function($paymentContent) {
    var me = this;

    $paymentContent.html('');
    swal({
        title: Translator.trans('payment.stored.success.title', {}, me.config.translationDomain)
        ,text: Translator.trans('payment.stored.success.text', {}, me.config.translationDomain)
        ,html: true
        ,type: 'success'
        ,confirmButtonText: Translator.trans('payment.stored.success.confirmbtn', {}, me.config.translationDomain)
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.addCustom = function($button) {
    var me = this
        ,queryParams = me.getFilterValues();

    queryParams.touroperator = me.$element.data('touroperator');
    queryParams.traveltype = me.$element.data('traveltype');

    $.ajax({
        url: Routing.generate('peakwork_icm_workflowoffice_payment_copy_inherit', {
            id: $button.data('inheritid')
        })
        ,data: queryParams
        ,method: 'POST'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('payment.custom.add.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.loadContent({
                updateTable: true
            });
            swal.close();
        }
        ,error: function() {
            swal({
                title: Translator.trans('payment.custom.add.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('payment.custom.add.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
                ,confirmButtonText: Translator.trans('payment.custom.add.error.confirmbtn', {}, me.config.translationDomain)
                ,closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    me.loadContent();
                    swal.close();
                }
            });
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.deleteCustom = function($button) {
    var me = this
        ,customItems = me.$contentContainer.find('.js-custom-payment').find('.js-item-payment')
        ,i = 0
        ,customItemsCount = customItems.length
        ,activeCount = 0;

    for (i; i < customItemsCount; i++) {
        if (false === $(customItems[i]).find('.js-delete-payment').hasClass('hidden')) {
            activeCount++;
        }
    }

    if (activeCount < 2) {
        me.selectPlan(me.$contentContainer.find('.js-inherit-payment').find('.js-select-plan'));
        return;
    }

    me.deleteCustomItem($button.data('id'));
};

contextIcmWorkflowOfficePayment.Payment.prototype.deleteCustomItem = function(id) {
    var me = this;

    swal({
        title: Translator.trans('payment.custom.delete.confirm.title', {}, me.config.translationDomain)
        ,text: Translator.trans('payment.custom.delete.confirm.text', {}, me.config.translationDomain)
        ,type: 'warning'
        ,showCancelButton: true
        ,confirmButtonColor: '#DD6B55'
        ,confirmButtonText: Translator.trans('payment.custom.delete.confirm.confirmbtn', {}, me.config.translationDomain)
        ,closeOnConfirm: true
        ,closeOnCancel: true
    }, function (isConfirm) {
        if (isConfirm) {
            me.doDeleteCustomItem(id);
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.doDeleteCustomItem = function(id) {
    var me = this;

    $.ajax({
        url: Routing.generate('peakwork_icm_workflowoffice_payment_delete_custom', {
            id: id
        })
        ,method: 'POST'
        ,beforeSend: function() {
            swal({
                title: Translator.trans('payment.custom.delete.waiting.title', {}, me.config.translationDomain)
                ,text: PeakworkBackendHelper.getInstance().createLoader()
                ,html: true
                ,type: 'info'
                ,showConfirmButton: false
            });
        }
        ,success: function(response) {
            me.loadContent({
                updateTable: true
            });
            swal.close();
        }
        ,error: function() {
            swal({
                title: Translator.trans('payment.custom.delete.error.title', {}, me.config.translationDomain)
                ,text: Translator.trans('payment.custom.delete.error.text', {}, me.config.translationDomain)
                ,html: true
                ,type: 'error'
                ,confirmButtonText: Translator.trans('payment.custom.delete.error.confirmbtn', {}, me.config.translationDomain)
                ,closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    me.loadContent();
                    swal.close();
                }
            });
        }
    });
};

contextIcmWorkflowOfficePayment.Payment.prototype.updateTableElement = function() {
    var me = this
        ,isCustom = me.$contentContainer.find('.js-custom-payment').hasClass('active')
        ,items = isCustom ?
            me.$contentContainer.find('.js-custom-payment').find('.js-item-payment:not(.js-inactive-payment)').find('.js-name-payment') :
            me.$contentContainer.find('.js-inherit-payment').find('.js-item-payment').find('.js-name-payment')
        ,i = 0
        ,itemsLength = items.length
        ,newElementText = []
        ,editBtns
        ,editBtnsLength
        ,$editBtn;

    for (i; i < itemsLength; i++) {
        newElementText.push($(items[i]).html());
    }

    if (false === $.contains(document, me.$element[0])) {
        editBtns = me.$table.find('.' + me.config.editBtnClass);
        editBtnsLength = editBtns.length;

        for (i = 0; i < editBtnsLength; i++) {
            $editBtn = $(editBtns[i]);
            if (
                $editBtn.data('traveltype') === me.$element.data('traveltype') &&
                $editBtn.data('crs') === me.$element.data('crs') &&
                $editBtn.data('touroperator') === me.$element.data('touroperator')
            ) {
                me.$element = $editBtn;
                return;
            }
        }
    }

    me.$element.html(newElementText.join(', '));
};