var contextIcmGenericReference = pwNamespace('PEAKWORK.ICM.RESTCLIENTBUNDLE.REFERENCE');

contextIcmGenericReference.buildReferenceWithFields = function($el, defaultOptions) {

    var $outputContainer = $('<div class="js-peakworkIcmGenericTypeReferenceWithFields-container"></div>')
        ,additionalData = $el.data('additionalData')
        ,basename = $el.attr('name').match(/.*\[(.*)\]$/)[1]
        ,name = $el.attr('name') + '[id]'
        ,instanceId = $el.closest('form').data('instanceid');

    $el.attr('name', name);
    $el.parent().after($outputContainer);

    var getFields = function() {
        params = {};
        params.id = $el.val();
        params.parentname = basename;
        params.instanceid = instanceId;
        params.disabled = true === $el.prop('disabled') ? 1 : 0;

        $.ajax({
            url: additionalData.fieldAjaxRoute
            ,data: params
            ,beforeSend: function() {
                $outputContainer.html(PeakworkBackendHelper.getInstance().createLoader());
            }
            , success: function (data) {
                $outputContainer.html(data.form);
            }
            ,error: function() {
                $outputContainer.html('');
            }

        });
    };

    $el.change(getFields);

    if ($el.val()) {
        getFields();
    }
};

contextIcmGenericReference.buildDependencies = function($el) {
    var dependsOnField = $el.data('dependsonfield')
        ,dependsOnSearchField = $el.data('dependsonsearchfield')
        ,$form = $el.closest('form')
        ,$field = $form.find('input[name="' + dependsOnField + '"]');

    if (0 === $field.length) {
        return;
    }

    if ('' === $el.select2('val')) {
        $el.select2('readonly', true);
    }

    $field.on('change', function(e) {
        var dependsOnValue = {};
        dependsOnValue[dependsOnSearchField] = e.val;
        $el.select2('val', '', true);
        $el.select2('readonly', false);
        $el.data('query-params-autocomplete', {
            'dependsOn': dependsOnValue
        });
    });
};

contextIcmGenericReference.initFields = function($container) {
    var referenceWithFields = $container.find('input.js-peakworkIcmGenericTypeReferenceWithFields')
        ,referenceWithFieldsLength = referenceWithFields.length
        ,dependencyFields = $container.find('input.js-peakworkIcmGenericTypeDependsOn')
        ,dependencyFieldsLength = dependencyFields.length
        ,i;

    for (i = 0; i < referenceWithFieldsLength; i++) {
        contextIcmGenericReference.buildReferenceWithFields($(referenceWithFields[i]), {});
    }

    for (i = 0; i < dependencyFieldsLength; i++) {
        contextIcmGenericReference.buildDependencies($(dependencyFields[i]));
    }
};

$(function() {
    contextIcmGenericReference.initFields($(document));
});